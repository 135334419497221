import {HannahAppReduxState} from 'hannah/src/app/frontend/default_state_template';

// This is a simply synchronous function that provides our data
// It is used for the server-side render as well as simply injected on the front-end.
// It's organized in this way so we can decouple/fetch later if necessary.
export function getInitialPageLoadData(url: string): HannahAppReduxState {
	return {
		/* XXX for some fucking bizarre reason, importing from site_config to get this
		value causes webpack to try to parse css files */
		urlPathOnPageLoad: url,
		activeTopLinkHref: url === '/' ? '/global-filtering' : url,
	};
}
