import * as React from 'react';

import {Page} from 'hannah/src/app/frontend/page_config';
import {ProgressivePageGenerator} from 'hannah/src/app/frontend/page_generator';

export interface ProjectPageProps {
	config: Page;	
}

export const ProjectPage = (props: ProjectPageProps) => {
	const {config} = props;

	return (
		<article>
			<ProgressivePageGenerator page={config} />
		</article>
	);
};
