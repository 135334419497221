/*
   Super hackily written features to make it easier to develop
*/

function createClassNameElement() {
   const el = document.createElement('span');
   el.style.position = 'absolute';
   el.style.zIndex = '12';
   el.style.background = 'white';
   el.style.boxShadow = `0 1px 14px 0 rgba(0, 0, 0, 0.2)`;
   el.style.padding = '6px 8px';
   el.style.display = 'none';
   el.style.pointerEvents = 'none';
   return el;
}

function createCoverElement() {
   const el = document.createElement('span');
   el.style.position = 'fixed';
   el.style.zIndex = '10';
   el.style.background = 'blue';
   el.style.padding = '6px 8px';
   el.style.display = 'none';
   el.style.pointerEvents = 'none';
   el.style.boxSizing = 'border-box';
   el.style.opacity = '0.2';
   return el;
}

function createPaddingElem() {
   const el = document.createElement('span');
   el.style.position = 'fixed';
   el.style.zIndex = '11';
   el.style.background = 'lime';
   el.style.display = 'none';
   el.style.pointerEvents = 'none';
   el.style.boxSizing = 'border-box';
   el.style.opacity = '0.4';
   return el;
}

function createMarginElem() {
   const el = document.createElement('span');
   el.style.position = 'fixed';
   el.style.zIndex = '11';
   el.style.background = 'orange';
   el.style.display = 'none';
   el.style.pointerEvents = 'none';
   el.style.boxSizing = 'border-box';
   el.style.opacity = '0.4';
   return el;
}

export function initDevOnlyFeatures() {
   const classNameElem = createClassNameElement();
   const coverElem = createCoverElement();
   const paddingLeftElem = createPaddingElem();
   const paddingRightElem = createPaddingElem();
   const paddingTopElem = createPaddingElem();
   const paddingBottomElem = createPaddingElem();
   const marginLeftElem = createMarginElem();
   const marginRightElem = createMarginElem();
   const marginTopElem = createMarginElem();
   const marginBottomElem = createMarginElem();

   document.body.appendChild(classNameElem);
   document.body.appendChild(coverElem);
   document.body.appendChild(paddingLeftElem);
   document.body.appendChild(paddingRightElem);
   document.body.appendChild(paddingTopElem);
   document.body.appendChild(paddingBottomElem);
   document.body.appendChild(marginLeftElem);
   document.body.appendChild(marginRightElem);
   document.body.appendChild(marginTopElem);
   document.body.appendChild(marginBottomElem);

   const classNameWhitelist = [
      /* Layout */
      '.content_width',
      '.content_width_max_wide',
      '.padding_top_spacing',
      '.padding_bottom_spacing',
      '.padding_right_spacing',
      '.padding_left_spacing',
      '.margin_top_spacing',
      '.margin_bottom_spacing',
      '.project_intro_card_pane_container',
      '.project_intro_card_left_pane_spacing',
      '.project_intro_card_right_pane_top_section_spacing',
      /* Typography */
      '.bold',
      '.caption_1',
      '.caption_2',
      '.colored_dot_legend_text',
      '.contact_blurb',
      '.h1',
      '.h2',
      '.h3',
      '.logo',
      '.logo_subhead',
      '.overline',
      '.page_heading',
      '.page_subhead',
      '.page_top_nav_author_link',
      '.page_top_nav_link',
      '.paragraph',
      '.paragraph_margin_top',
      '.paragraph_margin_bottom',
      '.quote_1',
      '.quote_2',
      '.top_nav_link',
   ];

   document.querySelector('*')!.addEventListener('mouseover', (event: any) => {
      if (event.target === classNameElem) {
         return;
      }

      const classes = Array.from(event.target.classList).filter((cx: any) => {
         return cx.split('__').length > 1;
      }).map((cx: any) => {
         const classWithHash = cx.split('__').pop();
   	  return `.${classWithHash.split('--')[0]}`;
      });
      const top = event.target.offsetTop;
      const left = event.target.offsetLeft;
      
      const filteredClasses = classes.filter((c: string) => {
         return classNameWhitelist.indexOf(c) !== -1;
      });

      if (filteredClasses.length > 0) {
         classNameElem.textContent = filteredClasses.join(', ');
         classNameElem.style.top = `${top}px`;
         classNameElem.style.left = `${left}px`;
         classNameElem.style.display = 'block';
         const computedStyles = window.getComputedStyle(event.target);
         const domRect = event.target.getBoundingClientRect();

         coverElem.style.display = 'block';
         coverElem.style.left = `${domRect.left}px`;
         coverElem.style.top = `${domRect.top}px`;
         coverElem.style.width = `${domRect.width}px`;
         coverElem.style.height = `${domRect.height}px`;

         const paddingLeft = computedStyles.getPropertyValue('padding-left');
         const paddingRight = computedStyles.getPropertyValue('padding-right');
         const paddingTop = computedStyles.getPropertyValue('padding-top');
         const paddingBottom = computedStyles.getPropertyValue('padding-bottom');

         const marginLeft = computedStyles.getPropertyValue('margin-left');
         const marginRight = computedStyles.getPropertyValue('margin-right');
         const marginTop = computedStyles.getPropertyValue('margin-top');
         const marginBottom = computedStyles.getPropertyValue('margin-bottom');


         paddingLeftElem.style.display = 'block';
         paddingLeftElem.style.left = `${domRect.left}px`;
         paddingLeftElem.style.top = `${domRect.top}px`;
         paddingLeftElem.style.width = paddingLeft;
         paddingLeftElem.style.height = `${domRect.height}px`;

         paddingRightElem.style.display = 'block';
         paddingRightElem.style.left = `${domRect.left}px`;
         paddingRightElem.style.top = `${domRect.top}px`;
         paddingRightElem.style.width = paddingRight;
         paddingRightElem.style.height = `${domRect.height}px`;

         paddingTopElem.style.display = 'block';
         paddingTopElem.style.left = `${domRect.left}px`;
         paddingTopElem.style.top = `${domRect.top}px`;
         paddingTopElem.style.width = paddingTop;
         paddingTopElem.style.height = `${domRect.height}px`;

         paddingBottomElem.style.display = 'block';
         paddingBottomElem.style.left = `${domRect.left}px`;
         paddingBottomElem.style.top = `${domRect.top}px`;
         paddingBottomElem.style.width = paddingBottom;
         paddingBottomElem.style.height = `${domRect.height}px`;

         marginLeftElem.style.display = 'block';
         marginLeftElem.style.left = `${domRect.left - Number(marginLeft.split('px')[0])}px`;
         marginLeftElem.style.top = `${domRect.top}px`;
         marginLeftElem.style.width = marginLeft;
         marginLeftElem.style.height = `${domRect.height}px`;

         marginRightElem.style.display = 'block';
         marginRightElem.style.left = `${domRect.left + domRect.width}px`;
         marginRightElem.style.top = `${domRect.top}px`;
         marginRightElem.style.width = marginRight;
         marginRightElem.style.height = `${domRect.height}px`;

         marginTopElem.style.display = 'block';
         marginTopElem.style.left = `${domRect.left}px`;
         marginTopElem.style.top = `${domRect.top - Number(marginTop.split('px')[0])}px`;
         marginTopElem.style.width = `${domRect.width}px`;
         marginTopElem.style.height = marginTop;

         marginBottomElem.style.display = 'block';
         marginBottomElem.style.left = `${domRect.left}px`;
         marginBottomElem.style.top = `${domRect.top + domRect.height}px`;
         marginBottomElem.style.width = `${domRect.width}px`;
         marginBottomElem.style.height = marginBottom;

      } else {
         classNameElem.textContent = '';
         classNameElem.style.top = '0px';
         classNameElem.style.left = '0px';
         classNameElem.style.display = 'none';
         clearOverlayElems();
      }
   });

   function clearOverlayElems() {
      coverElem.style.display = 'none';
      paddingLeftElem.style.display = 'none';
      paddingRightElem.style.display = 'none';
      paddingTopElem.style.display = 'none';
      paddingBottomElem.style.display = 'none';
      marginLeftElem.style.display = 'none';
      marginRightElem.style.display = 'none';
      marginTopElem.style.display = 'none';
      marginBottomElem.style.display = 'none';
   }

   window.addEventListener('scroll', clearOverlayElems);
   window.addEventListener('resize', clearOverlayElems);
}
