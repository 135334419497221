import * as React from 'react';
import {spring, TransitionMotion} from 'react-motion';

import {
  ImageItemMeta,
  ImageTypes,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';

import * as classes from 'hannah/src/app/frontend/components/lightbox/lightbox_carousel.css';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import panzoom from 'panzoom';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'pinch-zoom': any;
    }
  }
}

interface LightboxCarouselState {
  pinchZoomLoaded: boolean;
}

interface LightboxCarouselProps {
  images: ImageItemMeta[];
	activeImage: ImageItemMeta;
	onClickCurrentImage: () => void;
  onClickPrevButton: () => void;
  onClickNextButton: () => void;
}

const LEFT_ARROW_KEYCODE = 37;
const RIGHT_ARROW_KEYCODE = 39;


interface PanzoomProps {
  children: JSX.Element;
}

export class Panzoom extends React.Component<PanzoomProps> {

  private elem: HTMLElement;

  componentDidMount() {
    panzoom(this.elem);
  }

  render() {
    const {children} = this.props;

    return React.cloneElement(children, {
      ref: (elem: HTMLElement) => {
        this.elem = elem;
      },
    }); 
  }
}

export class LightboxCarousel extends React.Component<LightboxCarouselProps, LightboxCarouselState> {

  private keyDownListener: (event: KeyboardEvent) => void;

  state = {
    pinchZoomLoaded: false,
  };

  componentDidMount() {
    const {onClickPrevButton, onClickNextButton} = this.props;

    this.keyDownListener = (event: KeyboardEvent) => {
      if (event.which === LEFT_ARROW_KEYCODE) {
        onClickPrevButton();
      } else if (event.which === RIGHT_ARROW_KEYCODE) {
        onClickNextButton();
      }
    };
    window.addEventListener('keydown', this.keyDownListener);

    // NOTE(taylor) this is sketch, figure out a better way to organize
    if ('customElements' in window) {
      import('hannah/vendor/pinch-zoom/pinch-zoom.js').then(() => {
        this.setState({
          pinchZoomLoaded: true,
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyDownListener);
  }

  willEnter() {
    return {
      opacity: 0,
    };
  }

  willLeave() {
    return {
      opacity: spring(0),
    };
  }

	render() {
		const {
      images,
			activeImage,
			onClickCurrentImage,
      onClickPrevButton,
      onClickNextButton,
		} = this.props;
    const {
      pinchZoomLoaded,
    } = this.state;

    const shouldDisabledPrevButton = (
      images.indexOf(activeImage) === 0
    );
    const shouldDisableNextButton = (
      images.indexOf(activeImage) === images.length - 1
    );

    return (
      <React.Fragment>
        {
          images.length > 1 && (
            <React.Fragment>
              <button
                onClick={onClickPrevButton}
                className={classes.prev_button}
                disabled={shouldDisabledPrevButton}>
                &#8592;
              </button>
              <button
                onClick={onClickNextButton}
                className={classes.next_button}
                disabled={shouldDisableNextButton}>
                &#8594;
              </button>
            </React.Fragment>
          )
        }
        <TransitionMotion
        	willEnter={this.willEnter}
          willLeave={this.willLeave}
          styles={[activeImage].map((image: ImageItemMeta) => ({
            key: image.url,
            style: {
            	opacity: spring(1),
            },
            data: image,
          }))}>
          {(interpolatedStyles) =>
            <React.Fragment>
              {interpolatedStyles.map((config) => {
                const {key, data} = config;

                let figure;
                if (data.type === ImageTypes.IMAGE) {
                  figure = (
                    <div className={classes.figure_wrapper}>
                    	<img
                    		key={key}
                        onClick={onClickCurrentImage}
                        className={classes.image}
                    		style={config.style}
                    		src={data.url}
                    	/>
                    </div>
                  );

                  // figure = (
                  //   <Panzoom>
                  //     {figure}
                  //   </Panzoom>
                  // );
                  if ('customElements' in window && pinchZoomLoaded) {
                    /* NOTE(taylor) weird that this is class instead of className */
                    figure = (
                      <pinch-zoom class={classes.pinch_zoom} key={key}>
                        {figure}
                      </pinch-zoom>
                    )
                  }

                } else if (data.type === ImageTypes.VIDEO) {
                  figure = (
                    <div className={classes.figure_wrapper}>
                      <video
                        key={key}
                        className={classes.video}
                        loop
                        autoPlay
                        style={config.style}
                        playsInline>
                          <source src={data.url} />
                      </video>
                    </div>
                  );
                }

                return (
                  <div className={mediaSelectorClasses.lightbox_carousel_figure_wrapper}>
                    {figure}
                  </div>
                );
              })}
            </React.Fragment>
          }
        </TransitionMotion>
      </React.Fragment>
    );
	}
}
