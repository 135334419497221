import * as React from 'react';
import classNames from 'classnames';

import * as Typography from 'hannah/src/app/frontend/typography';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/pages/typography_page.css';

const components = [
	{
		selector: '.bold',
		component: Typography.Bold,
	},
	{
		selector: '.caption_1',
		component: Typography.Caption1,
	},
	{
		selector: '.caption_2',
		component: Typography.Caption2,
	},
	{
		selector: '.colored_dot_legend_text',
		component: Typography.ColoredDotLegendText,
	},
	{
		selector: '.contact_blurb',
		component: Typography.ContactBlurb,
	},
	{
		selector: '.h1',
		component: Typography.H1,
	},
	{
		selector: '.h2',
		component: Typography.H2,
	},
	{
		selector: '.h3',
		component: Typography.H3,
	},
	{
		selector: '.logo',
		component: Typography.Logo,
	},
	{
		selector: '.logo_subhead',
		component: Typography.LogoSubhead,
	},
	{
		selector: '.overline',
		component: Typography.Overline,
	},
	{
		selector: '.page_heading',
		component: Typography.PageHeading,
	},
	{
		selector: '.page_subhead',
		component: Typography.PageSubhead,
	},
	{
		selector: '.page_top_nav_author_link',
		component: Typography.PageTopNavAuthorLink,
		props: {
			to: '/',
		},
	},
	{
		selector: '.page_top_nav_link',
		component: Typography.PageTopNavLink,
		props: {
			to: '/',
		},
	},
	{
		selector: '.paragraph',
		component: Typography.Paragraph,
	},
	{
		selector: '.quote_1',
		component: Typography.Quote1,
	},
	{
		selector: '.quote_2',
		component: Typography.Quote2,
	},
	{
		selector: '.top_nav_link',
		component: Typography.TopNavLink,
		props: {
			to: '/',
		},
	},
];

export const TypographyPage = () => {
	const cx = classNames(
		classes.page_container,
		mediaSelectorClasses.content_width,
	);
	return (
		<div className={cx}>
			<ul className={classes.component_list}>
				{
					components.map(({selector, component, props}: any) => {
						const Component = component;
						return (
							<li className={classes.component_list_item}>
								<div className={classes.selector_text_wrapper}>
									<span className={classes.selector_text}>{selector}</span>
								</div>
								<Component {...props}>
									The quick brown fox jumps over the lazy dog
								</Component>
							</li>	
						);
					})
				}
			</ul>
		</div>
	);
};
