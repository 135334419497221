import * as React from 'react';
import {spring, TransitionMotion, SpringHelperConfig} from 'react-motion';

interface CrossfadeProps extends React.HTMLAttributes<HTMLDivElement> {
  springConfig?: SpringHelperConfig;
}

export class CrossfadeMotion extends React.Component<CrossfadeProps> {

  willEnter = () => {
    return {
      opacity: 0,
    };
  }

  willLeave = () => {
    const {springConfig} = this.props;
    return {
      opacity: spring(0, springConfig),
    };
  }

  renderItem = (config: any) => {
    const {key, data} = config;
    const {children} = data;
    return (
      <div key={key} style={{
        opacity: config.style.opacity,
      }}>{children}</div>
    )
  }

  render() {
    const {children, springConfig, ...rest} = this.props;

    const transitionStyles = [];
    const transitionDefaultStyles = [];

    if (children) {
      transitionStyles.push({
        key: "unknown",
        data: {
          children: children,
        },
        style: {
          opacity: spring(1, springConfig),
        },
      });

      transitionDefaultStyles.push({
        key: "unknown", 
        style: {
          opacity: 0,
        },
      });
    }

    return (
      <div {...rest}>
        <TransitionMotion
          willEnter={this.willEnter}
          willLeave={this.willLeave}
          styles={transitionStyles}
          defaultStyles={transitionDefaultStyles}>
          {(interpolatedStyles) => {
            return (
              <div>
                {interpolatedStyles.map(this.renderItem)}
              </div>
            );
          }}
        </TransitionMotion>
      </div>
    );
  }
}
