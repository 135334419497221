export default {
  "images/about-me/hannah-1.jpeg": "c857534d",
  "images/about-me/hannah-2.jpg": "1ae8ff38",
  "images/about-me/me-blue-background.jpg": "049371eb",
  "images/about-me/me-brick-background.jpg": "1d70d40b",
  "images/about-me/me-yellow-background.jpg": "59436c16",
  "images/adventure/1-hero/hero-800.jpg": "f59916f0",
  "images/adventure/1-hero/hero-800.png": "ae85f9fa",
  "images/adventure/1-hero/hero-900.png": "5b2d4ea2",
  "images/adventure/2-research/card-hover.png": "ed7afdda",
  "images/adventure/2-research/chart-actual.jpg": "6d8d60dd",
  "images/adventure/2-research/chart-actual.png": "dfcae1d4",
  "images/adventure/2-research/chart-assumptions.jpg": "0b994d71",
  "images/adventure/2-research/chart-assumptions.png": "2eee1c49",
  "images/adventure/2-research/competitive-review.jpg": "4e49b3df",
  "images/adventure/2-research/competitive-review.png": "c3d6c609",
  "images/adventure/2-research/feature-matrix.jpg": "7058517c",
  "images/adventure/2-research/feature-matrix.png": "9f17bc00",
  "images/adventure/2-research/google-docs-hover.png": "389a2588",
  "images/adventure/2-research/google-docs.jpg": "4b07f812",
  "images/adventure/2-research/google-docs.png": "ec3f5af5",
  "images/adventure/2-research/pinterest-hover.png": "1b7dd4be",
  "images/adventure/2-research/pinterest.jpg": "3aa8329c",
  "images/adventure/2-research/pinterest.png": "54bc0e1c",
  "images/adventure/2-research/trip-advisor-hover.png": "a3b18038",
  "images/adventure/2-research/trip-advisor.jpg": "d32cfca6",
  "images/adventure/2-research/trip-advisor.png": "d2773839",
  "images/adventure/3-personas/persona-1-no-copy.jpg": "1682bcab",
  "images/adventure/3-personas/persona-1.jpg": "cf01d50b",
  "images/adventure/3-personas/persona-1.png": "d859bd85",
  "images/adventure/3-personas/persona-2-no-copy.jpg": "a11b5832",
  "images/adventure/3-personas/persona-2.jpg": "f43b39ff",
  "images/adventure/3-personas/persona-2.png": "4fdf6245",
  "images/adventure/3-personas/persona-3-no-copy.jpg": "6e639886",
  "images/adventure/3-personas/persona-3.jpg": "4570c5ef",
  "images/adventure/3-personas/persona-3.png": "a05f7747",
  "images/adventure/4-user-flows/sketch-iterations.jpg": "04060f01",
  "images/adventure/4-user-flows/sketch-iterations.png": "986d2d48",
  "images/adventure/4-user-flows/user-flow.jpg": "c518752c",
  "images/adventure/4-user-flows/user-flow.png": "1a9cfee1",
  "images/adventure/5-user-testing/croped-flow-chart.png": "0778dcd3",
  "images/adventure/5-user-testing/full-flow-chart.jpg": "11264f6c",
  "images/adventure/5-user-testing/full-flow-chart.png": "2f811be6",
  "images/adventure/5-user-testing/user-testing-1.png": "0c5ca102",
  "images/adventure/5-user-testing/user-testing-2.png": "3ab6fbb1",
  "images/adventure/5-user-testing/user-testing-3.png": "f163fbb0",
  "images/adventure/5-user-testing/user-testing-video-1-c.mp4": "47b6bf44",
  "images/adventure/5-user-testing/user-testing-video-1.mov": "7f422d48",
  "images/adventure/5-user-testing/user-testing-video-2-c.mp4": "091afee1",
  "images/adventure/5-user-testing/user-testing-video-2.mov": "49407fe1",
  "images/adventure/5-user-testing/user-testing-video-3-c.mp4": "f4b39fac",
  "images/adventure/5-user-testing/user-testing-video-3.mov": "7a3483c3",
  "images/adventure/6-design-iterations/Adventure_iterations.gif": "0356c453",
  "images/adventure/6-design-iterations/color-palette.jpg": "72e31c02",
  "images/adventure/6-design-iterations/color-palette.png": "34847be4",
  "images/adventure/6-design-iterations/design-iterations-placeholder.png": "8154c334",
  "images/adventure/6-design-iterations/large-phone-placeholder.png": "63541d4c",
  "images/adventure/7-mvp/add-activities-c.mp4": "7f153d32",
  "images/adventure/7-mvp/add-activities.gif": "abb1d06e",
  "images/adventure/7-mvp/add-activities.mp4": "b9ea967b",
  "images/adventure/7-mvp/add-to-itinerary-c.mp4": "909b797c",
  "images/adventure/7-mvp/add-to-itinerary.gif": "5125c0ea",
  "images/adventure/7-mvp/add-to-itinerary.mp4": "7c4393cd",
  "images/adventure/7-mvp/final-walkthrough-placeholder1.png": "e2728f4c",
  "images/adventure/7-mvp/final-walkthrough-placeholder2.png": "b5888aa5",
  "images/adventure/7-mvp/final-walkthrough-placeholder3.png": "b5888aa5",
  "images/adventure/7-mvp/ftue-c.mp4": "53bf9eac",
  "images/adventure/7-mvp/ftue.mp4": "74204b3a",
  "images/adventure/7-mvp/mvp-1.png": "29c3d8ab",
  "images/adventure/7-mvp/mvp-2.png": "e517bd67",
  "images/adventure/7-mvp/mvp-3.png": "23285fe3",
  "images/adventure/7-mvp/small-phone-placeholder.png": "2ee8ea7a",
  "images/adventure/8-next-step/10-adventure-mockup.jpg": "76a8bbea",
  "images/adventure/8-next-step/views.png": "a81ddbb4",
  "images/adventure/9-gallery/1-loading-screen.png": "65a6060a",
  "images/adventure/9-gallery/10-adventure-mockup.jpg": "76a8bbea",
  "images/adventure/9-gallery/2-search-screen.png": "54097685",
  "images/adventure/9-gallery/3-details-screen.png": "e4a3e595",
  "images/adventure/9-gallery/4-itinerary-screen.png": "53d12587",
  "images/adventure/9-gallery/5-wireframes1.jpg": "499998ed",
  "images/adventure/9-gallery/5-wireframes1.png": "aabd2b3d",
  "images/adventure/9-gallery/6-wireframes2.jpg": "6dfa6d47",
  "images/adventure/9-gallery/6-wireframes2.png": "94da4d0b",
  "images/adventure/9-gallery/7-wireframes3.jpg": "0fdcc5dd",
  "images/adventure/9-gallery/7-wireframes3.png": "d5024ecc",
  "images/adventure/9-gallery/8-wireframes4.jpg": "c23286cd",
  "images/adventure/9-gallery/8-wireframes4.png": "054d9aae",
  "images/adventure/9-gallery/9-plane-mockup.jpg": "41d816ee",
  "images/archive/1-hero/archive-hero.jpg": "ca319336",
  "images/archive/1-hero/hero-800.png": "0546bba5",
  "images/archive/1-hero/hero-900.png": "d70268dc",
  "images/archive/2-sb-mesa/gallery/deck-perspective-full.jpg": "18246193",
  "images/archive/2-sb-mesa/gallery/deck-perspective-full.png": "afa79629",
  "images/archive/2-sb-mesa/gallery/deck-perspective.jpg": "64fd7e02",
  "images/archive/2-sb-mesa/gallery/deck-perspective.png": "586e7d71",
  "images/archive/2-sb-mesa/gallery/deck-plan.jpg": "5edfa6bd",
  "images/archive/2-sb-mesa/gallery/deck-plan.png": "0220c088",
  "images/archive/2-sb-mesa/gallery/isometric.jpg": "972fc8ec",
  "images/archive/2-sb-mesa/gallery/isometric.png": "518de440",
  "images/archive/2-sb-mesa/gallery/mesa-plan.jpg": "0127e5a1",
  "images/archive/2-sb-mesa/gallery/mesa-plan.png": "49d157c6",
  "images/archive/2-sb-mesa/gallery/mesa-topo.jpg": "5e65705c",
  "images/archive/2-sb-mesa/gallery/mesa-topo.png": "1a70375b",
  "images/archive/2-sb-mesa/gallery/night-perspective.jpg": "e7e9e173",
  "images/archive/2-sb-mesa/gallery/night-perspective.png": "748c7d70",
  "images/archive/2-sb-mesa/gallery/plant-plan.jpg": "a24975b7",
  "images/archive/2-sb-mesa/gallery/plant-plan.png": "8d59db68",
  "images/archive/2-sb-mesa/gallery/process-activity-bubbles.jpg": "aa29cd30",
  "images/archive/2-sb-mesa/gallery/process-age-bubbles.jpg": "8f7e430a",
  "images/archive/2-sb-mesa/gallery/process-matrix.jpg": "cc943454",
  "images/archive/2-sb-mesa/gallery/process-plan.jpg": "838b0471",
  "images/archive/2-sb-mesa/gallery/process-relationship-bubbles.jpg": "9f285524",
  "images/archive/2-sb-mesa/gallery/process-sketches.jpg": "c60b44fb",
  "images/archive/2-sb-mesa/gallery/process-soil.jpg": "32d3889b",
  "images/archive/2-sb-mesa/gallery/process-topography.jpg": "559c5d07",
  "images/archive/2-sb-mesa/gallery/process-trails.jpg": "eaf523d9",
  "images/archive/2-sb-mesa/gallery/section.jpg": "c762e305",
  "images/archive/2-sb-mesa/gallery/section.png": "61cbab3c",
  "images/archive/2-sb-mesa/gallery/viewing-perspective.jpg": "6981ed00",
  "images/archive/2-sb-mesa/gallery/viewing-perspective.png": "4c49b1ad",
  "images/archive/2-sb-mesa/mesa-construction-doc.jpg": "32c13f37",
  "images/archive/2-sb-mesa/mesa-construction-doc.png": "93efefe3",
  "images/archive/2-sb-mesa/mesa-deck-perspective.jpg": "0fab5d39",
  "images/archive/2-sb-mesa/mesa-deck-perspective.png": "d2828a6f",
  "images/archive/2-sb-mesa/mesa-night.jpg": "361d8795",
  "images/archive/2-sb-mesa/mesa-night.png": "c1a2b85a",
  "images/archive/2-sb-mesa/mesa-on-deck.jpg": "91fa1fc3",
  "images/archive/2-sb-mesa/mesa-on-deck.png": "bc484c5e",
  "images/archive/2-sb-mesa/outcome/award.jpg": "61668b17",
  "images/archive/2-sb-mesa/outcome/award.png": "64fb193c",
  "images/archive/2-sb-mesa/outcome/built.jpg": "cdb7409c",
  "images/archive/2-sb-mesa/outcome/built.png": "c9a9fed7",
  "images/archive/2-sb-mesa/outcome/project-photo.jpg": "c043a2a4",
  "images/archive/2-sb-mesa/outcome/project-photo.png": "13d64a95",
  "images/archive/2-sb-mesa/process/process-activity-bubbles.png": "f62bd851",
  "images/archive/2-sb-mesa/process/process-age-bubbles.png": "765cf2f9",
  "images/archive/2-sb-mesa/process/process-matrix.png": "e507acfb",
  "images/archive/2-sb-mesa/process/process-plan.png": "d22c27eb",
  "images/archive/2-sb-mesa/process/process-relationship-bubbles.png": "743b2c05",
  "images/archive/2-sb-mesa/process/process-sketches.png": "5e0b8140",
  "images/archive/2-sb-mesa/process/process-soil.png": "47ff00fc",
  "images/archive/2-sb-mesa/process/process-topography.png": "3b4c13a1",
  "images/archive/2-sb-mesa/process/process-trails.png": "87cc7a5c",
  "images/archive/3-rrm/gallery/kennedy-plan.jpg": "ca4dfa5e",
  "images/archive/3-rrm/gallery/kennedy-plan.png": "8130a15a",
  "images/archive/3-rrm/gallery/kennedy-sketch.jpg": "f62e30d3",
  "images/archive/3-rrm/gallery/kennedy-sketch.png": "40b8589b",
  "images/archive/3-rrm/gallery/playground-sketch-smaller.jpg": "3d1139c8",
  "images/archive/3-rrm/gallery/playground-sketch-smaller.png": "983074d1",
  "images/archive/3-rrm/gallery/san-lorenzo-final.jpg": "3f8f09a2",
  "images/archive/3-rrm/gallery/san-lorenzo-final.png": "b87a8517",
  "images/archive/3-rrm/gallery/san-lorenzo-final2.jpg": "29ed358e",
  "images/archive/3-rrm/gallery/san-lorenzo-final2.png": "74477387",
  "images/archive/3-rrm/gallery/san-lorenzo-sketch.jpg": "c39f3968",
  "images/archive/3-rrm/gallery/san-lorenzo-sketch.png": "40eb77f9",
  "images/archive/3-rrm/house-sketch.jpg": "9ff6da75",
  "images/archive/3-rrm/house-sketch.png": "d8a2b0a9",
  "images/archive/3-rrm/playground-sketch.jpg": "3d1139c8",
  "images/archive/3-rrm/playground-sketch.png": "983074d1",
  "images/archive/3-rrm/process/community-feedback.jpg": "e44ce2f1",
  "images/archive/3-rrm/process/community-feedback.png": "3369829d",
  "images/archive/3-rrm/process/greenwood-final.jpg": "e936230a",
  "images/archive/3-rrm/process/greenwood-final.png": "b7f1be2a",
  "images/archive/3-rrm/process/huddle.jpg": "b63a8297",
  "images/archive/3-rrm/process/huddle.png": "4116b506",
  "images/archive/3-rrm/process/kennedy-sketch.jpg": "ad6f4499",
  "images/archive/3-rrm/process/kennedy-sketch.png": "6a438845",
  "images/archive/3-rrm/process/kids-playing.jpg": "98b42bef",
  "images/archive/3-rrm/process/kids-playing.png": "e65e1288",
  "images/archive/3-rrm/process/rrm-flow.jpg": "5a2eea78",
  "images/archive/3-rrm/process/rrm-flow.png": "901233a6",
  "images/archive/3-rrm/process/trail-plan-smaller.jpg": "a0890f4d",
  "images/archive/3-rrm/process/trail-plan-smaller.png": "66120295",
  "images/archive/3-rrm/trail-plan.jpg": "5d64712a",
  "images/archive/3-rrm/trail-plan.png": "e67bdccb",
  "images/archive/4-beyond-digital/chair.jpg": "87520897",
  "images/archive/4-beyond-digital/chair.png": "9fc9755e",
  "images/archive/4-beyond-digital/company-swag.jpg": "734155af",
  "images/archive/4-beyond-digital/company-swag.png": "ac4cd4bb",
  "images/archive/4-beyond-digital/modal-building.jpg": "bde6825c",
  "images/archive/4-beyond-digital/modal-building.png": "65d63804",
  "images/archive/4-beyond-digital/office-organization.jpg": "396c2b2b",
  "images/archive/4-beyond-digital/office-organization.png": "70e7b677",
  "images/archive/4-beyond-digital/party-planning.jpg": "c6b7e17f",
  "images/archive/4-beyond-digital/party-planning.png": "56548b58",
  "images/archive/4-beyond-digital/section-sketch.jpg": "7585a980",
  "images/archive/4-beyond-digital/section-sketch.png": "2558b58e",
  "images/archive/4-beyond-digital/warm-springs-kitchen.jpg": "49303d85",
  "images/archive/4-beyond-digital/warm-springs-kitchen.png": "06e67ec7",
  "images/archive/4-beyond-digital/warm-springs-living.jpg": "895ae5b0",
  "images/archive/4-beyond-digital/warm-springs-living.png": "55dc28bd",
  "images/archive/6-cheese-to-plants/cheese-feed.png": "330f3ca1",
  "images/archive/6-cheese-to-plants/cheese-popcicle.png": "d710afea",
  "images/archive/6-cheese-to-plants/cheese-recipe.png": "3da99d30",
  "images/archive/6-cheese-to-plants/gallery/chia-pudding.jpg": "9c6bd5b6",
  "images/archive/6-cheese-to-plants/gallery/muffins.jpg": "2c5d6c8e",
  "images/archive/6-cheese-to-plants/gallery/pancake.jpg": "fe984470",
  "images/archive/6-cheese-to-plants/gallery/pea-soup.jpg": "f7425944",
  "images/archive/6-cheese-to-plants/gallery/squash-soup.jpg": "47c61ce2",
  "images/archive/6-cheese-to-plants/gallery/tortilla-soup.jpg": "5dbfaa4d",
  "images/data-visualization/1-hero/charts-hero-image.jpg": "bdb830f4",
  "images/data-visualization/1-hero/charts-hero-image.png": "c4b4f1b1",
  "images/data-visualization/1-hero/hero-image-large.jpg": "0d87ae8a",
  "images/data-visualization/10-gallery/gallery-chart-settings.png": "90441e3c",
  "images/data-visualization/10-gallery/gallery-column-hover.png": "c8037951",
  "images/data-visualization/10-gallery/gallery-deal-info.png": "294d8d62",
  "images/data-visualization/10-gallery/gallery-dimensions.png": "f0b07153",
  "images/data-visualization/10-gallery/gallery-export.png": "8f05add7",
  "images/data-visualization/10-gallery/gallery-legend.png": "0818278f",
  "images/data-visualization/10-gallery/gallery-matrix.png": "68c8d8b7",
  "images/data-visualization/10-gallery/gallery-modal.png": "2b18e245",
  "images/data-visualization/10-gallery/gallery-pie-hover.png": "be5c9436",
  "images/data-visualization/10-gallery/gallery-template.png": "964750a6",
  "images/data-visualization/10-gallery/gallery-tooltips.png": "8b05b622",
  "images/data-visualization/10-gallery/gallery-view-all-modal.png": "fccf5421",
  "images/data-visualization/2-problem/carousel/problems-colors.jpg": "8f1542c1",
  "images/data-visualization/2-problem/carousel/problems-colors.png": "104dbc6b",
  "images/data-visualization/2-problem/carousel/problems-deal-info.jpg": "10992784",
  "images/data-visualization/2-problem/carousel/problems-deal-info.png": "b4c3a451",
  "images/data-visualization/2-problem/carousel/problems-reporting.jpg": "d7690f7c",
  "images/data-visualization/2-problem/carousel/problems-reporting.png": "3bae3d8e",
  "images/data-visualization/2-problem/carousel/problems-scaling-placeholder.png": "a735b429",
  "images/data-visualization/2-problem/carousel/scaling-issue-c.gif": "2619abc8",
  "images/data-visualization/2-problem/carousel/scaling-issue.gif": "e6dd981f",
  "images/data-visualization/3-research/bubble_group_1.png": "164fb780",
  "images/data-visualization/3-research/bubble_group_2.png": "176392d1",
  "images/data-visualization/3-research/carousel/competitive-dealcloud.jpg": "88ba4a3e",
  "images/data-visualization/3-research/carousel/competitive-dealcloud.png": "2a220f23",
  "images/data-visualization/3-research/carousel/competitive-mixpanel.jpg": "0dd464ad",
  "images/data-visualization/3-research/carousel/competitive-mixpanel.png": "6dea94d0",
  "images/data-visualization/3-research/carousel/competitive-salesforce.jpg": "73f8a10f",
  "images/data-visualization/3-research/carousel/competitive-salesforce.png": "0c292fec",
  "images/data-visualization/3-research/carousel/DC-pros.jpg": "199abe0b",
  "images/data-visualization/3-research/carousel/DL-cons.jpg": "d4ea5f23",
  "images/data-visualization/3-research/carousel/MP-cons.jpg": "2af9b8e4",
  "images/data-visualization/3-research/carousel/SF-cons.jpg": "114c3cac",
  "images/data-visualization/3-research/carousel/SF-pros.jpg": "8e114ee2",
  "images/data-visualization/3-research/circle-aew.png": "46068cc8",
  "images/data-visualization/3-research/circle-analysts.png": "4afa470b",
  "images/data-visualization/3-research/circle-cp.png": "6d36923f",
  "images/data-visualization/3-research/circle-execs.png": "57ca28b2",
  "images/data-visualization/3-research/circle-lm.png": "de4d1c4f",
  "images/data-visualization/3-research/comparing-sections.jpg": "ac11d619",
  "images/data-visualization/3-research/comparing-sections.png": "e1a91a23",
  "images/data-visualization/3-research/DC-pros.jpg": "199abe0b",
  "images/data-visualization/3-research/design-guidelines-placeholder.jpg": "37ceca2c",
  "images/data-visualization/3-research/design-guidelines-placeholder.png": "46aaa0a3",
  "images/data-visualization/3-research/DL-cons.jpg": "d4ea5f23",
  "images/data-visualization/3-research/metrics-global-page-views.png": "9f37e3d5",
  "images/data-visualization/3-research/metrics-page-views.jpg": "f12d36de",
  "images/data-visualization/3-research/metrics-page-views.png": "c0411d0e",
  "images/data-visualization/3-research/MP-cons.jpg": "2af9b8e4",
  "images/data-visualization/3-research/MP-pros.jpg": "2728d206",
  "images/data-visualization/3-research/research-insights-placeholder.jpg": "334f05c8",
  "images/data-visualization/3-research/research-insights-placeholder.png": "46aaa0a3",
  "images/data-visualization/3-research/SF-cons.jpg": "114c3cac",
  "images/data-visualization/3-research/SF-pros.jpg": "8e114ee2",
  "images/data-visualization/4-hypothesis/graphic-customization.jpg": "ef2134d2",
  "images/data-visualization/4-hypothesis/graphic-customization.png": "f7356fc4",
  "images/data-visualization/4-hypothesis/graphic-familiarity.jpg": "73e6d8ed",
  "images/data-visualization/4-hypothesis/graphic-familiarity.png": "356174f8",
  "images/data-visualization/4-hypothesis/graphic-grid.jpg": "9dc8efa8",
  "images/data-visualization/4-hypothesis/graphic-grid.png": "304c31b3",
  "images/data-visualization/4-hypothesis/graphic-sharing.jpg": "4ca292ad",
  "images/data-visualization/4-hypothesis/graphic-sharing.png": "936bf23e",
  "images/data-visualization/5-color-palette/black-and-white.jpg": "0231196e",
  "images/data-visualization/5-color-palette/board-cool.jpg": "0548d342",
  "images/data-visualization/5-color-palette/board-cool.png": "6993b4d7",
  "images/data-visualization/5-color-palette/board-warm.jpg": "fc1b0b6d",
  "images/data-visualization/5-color-palette/board-warm.png": "5b279d04",
  "images/data-visualization/5-color-palette/carousel/research-material.jpg": "13f5624b",
  "images/data-visualization/5-color-palette/carousel/research-material.png": "76f345ea",
  "images/data-visualization/5-color-palette/carousel/research-mixpanel.jpg": "db79309d",
  "images/data-visualization/5-color-palette/carousel/research-mixpanel.png": "7246f4ae",
  "images/data-visualization/5-color-palette/carousel/research-quickbooks.jpg": "8ec5fde4",
  "images/data-visualization/5-color-palette/carousel/research-quickbooks.png": "ee0f7b28",
  "images/data-visualization/5-color-palette/carousel/research-salesforce.jpg": "d5fab107",
  "images/data-visualization/5-color-palette/carousel/research-salesforce.png": "b43265fb",
  "images/data-visualization/5-color-palette/carousel/research-zendesk.jpg": "43f8b04e",
  "images/data-visualization/5-color-palette/carousel/research-zendesk.png": "0d8ca095",
  "images/data-visualization/5-color-palette/color-vs-bw.png": "75b9b52e",
  "images/data-visualization/5-color-palette/colors.jpg": "35428688",
  "images/data-visualization/5-color-palette/existing-color-palette.jpg": "8f1542c1",
  "images/data-visualization/5-color-palette/existing-color-palette.png": "104dbc6b",
  "images/data-visualization/5-color-palette/option-1.jpg": "6da216e2",
  "images/data-visualization/5-color-palette/option-1.png": "9f0824e6",
  "images/data-visualization/5-color-palette/option-2.jpg": "981a0022",
  "images/data-visualization/5-color-palette/option-2.png": "02778fb0",
  "images/data-visualization/5-color-palette/option-3.jpg": "d2690e9c",
  "images/data-visualization/5-color-palette/option-3.png": "da6d2aac",
  "images/data-visualization/5-color-palette/primary-colors.png": "76848007",
  "images/data-visualization/5-color-palette/settings-colors.jpg": "542937a1",
  "images/data-visualization/5-color-palette/settings-colors.png": "4209f279",
  "images/data-visualization/6-deal-info-charts/add-chart-modal.jpg": "8a9f6e2b",
  "images/data-visualization/6-deal-info-charts/add-chart-modal.png": "2a69eaa3",
  "images/data-visualization/6-deal-info-charts/card-flip-placeholder.png": "02d1e4e0",
  "images/data-visualization/6-deal-info-charts/edit-chart-c.mp4": "7d03dabd",
  "images/data-visualization/6-deal-info-charts/edit-chart.mov": "21edc21f",
  "images/data-visualization/6-deal-info-charts/final-deal-info-page.jpg": "20652339",
  "images/data-visualization/6-deal-info-charts/final-deal-info-page.png": "8a258eeb",
  "images/data-visualization/6-deal-info-charts/google-charts.jpg": "1ccca2e3",
  "images/data-visualization/6-deal-info-charts/google-charts.png": "219e0a02",
  "images/data-visualization/6-deal-info-charts/graphic-bar.jpg": "ffea8151",
  "images/data-visualization/6-deal-info-charts/graphic-bar.png": "05111df2",
  "images/data-visualization/6-deal-info-charts/graphic-column.jpg": "7f98cf5f",
  "images/data-visualization/6-deal-info-charts/graphic-column.png": "7eec42db",
  "images/data-visualization/6-deal-info-charts/graphic-donut.jpg": "6e1197e8",
  "images/data-visualization/6-deal-info-charts/graphic-donut.png": "234efdbd",
  "images/data-visualization/6-deal-info-charts/template-editor-c.mp4": "e4da848b",
  "images/data-visualization/6-deal-info-charts/template-editor-placeholder.png": "bbf8a3d5",
  "images/data-visualization/6-deal-info-charts/template-editor.mov": "6363e6f7",
  "images/data-visualization/7-reporting-charts/carousel/interactions-legend-placeholder.png": "2940c45f",
  "images/data-visualization/7-reporting-charts/carousel/interactions-page-scroll-placeholder.png": "51ba3744",
  "images/data-visualization/7-reporting-charts/carousel/interactions-scale-placeholder.png": "e505365e",
  "images/data-visualization/7-reporting-charts/carousel/interactions-tooltip-placeholder.png": "2940c45f",
  "images/data-visualization/7-reporting-charts/carousel/legend-interaction-c.mp4": "d54fe3a8",
  "images/data-visualization/7-reporting-charts/carousel/legend-interaction.mov": "b6c95870",
  "images/data-visualization/7-reporting-charts/carousel/responsive-c.mp4": "2a9a114c",
  "images/data-visualization/7-reporting-charts/carousel/responsive.mov": "2c43faab",
  "images/data-visualization/7-reporting-charts/carousel/scrolling-interaction-c.mp4": "4f58fe3a",
  "images/data-visualization/7-reporting-charts/carousel/scrolling-interaction.mov": "b96b0349",
  "images/data-visualization/7-reporting-charts/carousel/tooltip-interaction-c.mp4": "dd5e7fb8",
  "images/data-visualization/7-reporting-charts/carousel/tooltip-interaction.mov": "251afe52",
  "images/data-visualization/7-reporting-charts/chart-dimensions.jpg": "4d90fc0c",
  "images/data-visualization/7-reporting-charts/chart-dimensions.png": "87bea8c0",
  "images/data-visualization/7-reporting-charts/configuration-left.jpg": "93710b31",
  "images/data-visualization/7-reporting-charts/configuration-left.png": "31fe35a6",
  "images/data-visualization/7-reporting-charts/configuration-right.jpg": "39f11163",
  "images/data-visualization/7-reporting-charts/configuration-right.png": "82ba0b62",
  "images/data-visualization/7-reporting-charts/configuration-top-separate.jpg": "0d86e797",
  "images/data-visualization/7-reporting-charts/configuration-top-separate.png": "a439791a",
  "images/data-visualization/7-reporting-charts/configuration-top.jpg": "94c1f143",
  "images/data-visualization/7-reporting-charts/configuration-top.png": "c215419e",
  "images/data-visualization/7-reporting-charts/final-reporting-charts-placeholder.png": "633b8889",
  "images/data-visualization/7-reporting-charts/final-reporting-movie-c.mp4": "611cc3db",
  "images/data-visualization/7-reporting-charts/final-reporting-movie.mov": "095e6124",
  "images/data-visualization/7-reporting-charts/full reporting animation option 3 (with clicks).mov": "fb3984c1",
  "images/data-visualization/7-reporting-charts/Full reporting charts animation - option 1.mov": "1e330cfc",
  "images/data-visualization/7-reporting-charts/settings-no-bubbles.png": "3c0cfce2",
  "images/data-visualization/7-reporting-charts/settings-with-bubbles.png": "6b9f86a4",
  "images/data-visualization/8-results/metric-numbers.jpg": "8ba70b0f",
  "images/data-visualization/8-results/metric-numbers.png": "bff9979e",
  "images/data-visualization/8-results/metrics-results.png": "e26470e8",
  "images/data-visualization/9-next-step/double-charts.jpg": "abcfdac8",
  "images/data-visualization/9-next-step/double-charts.png": "016c808e",
  "images/dealpath/1-hero/hero-800.jpg": "d94da63b",
  "images/dealpath/1-hero/hero-800.png": "86c1444a",
  "images/dealpath/1-hero/hero-900.png": "5c0535ee",
  "images/dealpath/2-cards/complete-data-viz.png": "fd12d17e",
  "images/dealpath/2-cards/complete-design-kit.png": "0f98d267",
  "images/dealpath/2-cards/complete-filtering.png": "fda9262c",
  "images/dealpath/2-cards/complete-grid.png": "0363a20b",
  "images/dealpath/2-cards/gradient-data-viz.jpg": "71839d28",
  "images/dealpath/2-cards/gradient-data-viz.png": "fd704a39",
  "images/dealpath/2-cards/gradient-design-kit.jpg": "482e4750",
  "images/dealpath/2-cards/gradient-design-kit.png": "31dc6526",
  "images/dealpath/2-cards/gradient-filtering.jpg": "dbe100ef",
  "images/dealpath/2-cards/gradient-filtering.png": "43df6ed7",
  "images/dealpath/2-cards/gradient-grid.png": "b5ec84ac",
  "images/dealpath/2-cards/graphic-data-viz.png": "baf58768",
  "images/dealpath/2-cards/graphic-design-kit.png": "6fd698f6",
  "images/dealpath/2-cards/graphic-filtering.png": "d0b59830",
  "images/dealpath/2-cards/graphic-grid.png": "1150ef2a",
  "images/global-filtering/1-hero/hero-filtering-alternative.jpg": "0d286726",
  "images/global-filtering/1-hero/hero-image-only.png": "785660a0",
  "images/global-filtering/1-hero/hero-image-product.png": "961c989c",
  "images/global-filtering/1-hero/hero-image-with-background.png": "751bf989",
  "images/global-filtering/10-gallery/charts-filter.jpg": "1b52b241",
  "images/global-filtering/10-gallery/charts-filter.png": "f792942e",
  "images/global-filtering/10-gallery/charts.jpg": "fbfea78e",
  "images/global-filtering/10-gallery/charts.png": "d615cfa8",
  "images/global-filtering/10-gallery/dashboard-configure-hover.png": "9a582e7d",
  "images/global-filtering/10-gallery/dashboard-configure-search.png": "bca9bb16",
  "images/global-filtering/10-gallery/dashboard-filter-flyout.png": "0710fd0d",
  "images/global-filtering/10-gallery/dashboard-filter-hover.png": "cf07b346",
  "images/global-filtering/10-gallery/filter-add-flow.png": "2d0ebc28",
  "images/global-filtering/10-gallery/filter-set-flow.png": "45ea1a54",
  "images/global-filtering/10-gallery/navigation.png": "09329dac",
  "images/global-filtering/10-gallery/order-configure-flow.png": "1086fd29",
  "images/global-filtering/10-gallery/set-configure-flow.png": "5f69fb6a",
  "images/global-filtering/10-gallery/tasklist-filter.png": "202a25bd",
  "images/global-filtering/2-problem/carousel/configure-problems.png": "fcad1924",
  "images/global-filtering/2-problem/carousel/filter-problems.png": "4bd9d481",
  "images/global-filtering/2-problem/carousel/ia-problems.png": "2498b21c",
  "images/global-filtering/2-problem/carousel/icon-problems.png": "4f6ccd38",
  "images/global-filtering/2-problem/carousel/non-responsive-nav.jpg": "e4db5838",
  "images/global-filtering/2-problem/carousel/non-responsive-nav.png": "9d5a43e3",
  "images/global-filtering/2-problem/carousel/non-responsive-nav2.jpg": "dbc4655f",
  "images/global-filtering/2-problem/carousel/non-responsive-nav2.png": "245f1750",
  "images/global-filtering/2-problem/carousel/problem-video-mockup.png": "4d5db899",
  "images/global-filtering/2-problem/carousel/scrolling-problems.png": "a12db9de",
  "images/global-filtering/2-problem/carousel/search-problems.png": "84919717",
  "images/global-filtering/2-problem/problems-c.gif": "aa417af9",
  "images/global-filtering/2-problem/problems.gif": "78a6062d",
  "images/global-filtering/3-research/fullstory-hotspots.png": "2a242c67",
  "images/global-filtering/3-research/page-views-noborder.png": "ee6f2b6f",
  "images/global-filtering/3-research/page-views.png": "d36ded85",
  "images/global-filtering/4-navigation/new-carousel/new-assignments-ia.png": "fdfbb1f5",
  "images/global-filtering/4-navigation/new-carousel/new-dashboard-ia.png": "64228689",
  "images/global-filtering/4-navigation/new-carousel/new-reporting-ia.png": "1e19d125",
  "images/global-filtering/4-navigation/new-carousel/new-tasks-ia.png": "f7a9034a",
  "images/global-filtering/4-navigation/new-filter-nav-responsive.png": "d24c1097",
  "images/global-filtering/4-navigation/new-filter-nav.png": "c5a01d0c",
  "images/global-filtering/4-navigation/new-navigation.png": "4e71dc57",
  "images/global-filtering/4-navigation/old-carousel/assignments-ia.png": "c63678bd",
  "images/global-filtering/4-navigation/old-carousel/dashboard-ia.png": "c6e800bb",
  "images/global-filtering/4-navigation/old-carousel/reporting-ia.png": "84ca744a",
  "images/global-filtering/4-navigation/old-carousel/tasks-ia.png": "e32ddb5b",
  "images/global-filtering/4-navigation/old-filter-nav-responsive.png": "6a0aa43c",
  "images/global-filtering/4-navigation/old-filter-nav.png": "2075c9a9",
  "images/global-filtering/4-navigation/old-navigation.png": "52625dac",
  "images/global-filtering/5-user-experience/add-filter-animation.gif": "b3ce1499",
  "images/global-filtering/5-user-experience/add-filter-animation.mp4": "42625f57",
  "images/global-filtering/5-user-experience/add-filter-placeholder.png": "11675e91",
  "images/global-filtering/5-user-experience/carousel/filter-problems-placeholder.png": "ef059fd6",
  "images/global-filtering/5-user-experience/carousel/problems-filter-reset-c.mp4": "8ed3cb3a",
  "images/global-filtering/5-user-experience/carousel/problems-filter-reset.gif": "407ad172",
  "images/global-filtering/5-user-experience/carousel/problems-filter-reset.mp4": "f4fef5d0",
  "images/global-filtering/5-user-experience/carousel/problems-scrolling-c.mp4": "8e7cd006",
  "images/global-filtering/5-user-experience/carousel/problems-scrolling.gif": "6fb19d36",
  "images/global-filtering/5-user-experience/carousel/problems-scrolling.mp4": "caf7089b",
  "images/global-filtering/5-user-experience/carousel/problems-search-c.mp4": "ec6c2baf",
  "images/global-filtering/5-user-experience/carousel/problems-search.gif": "97223a71",
  "images/global-filtering/5-user-experience/carousel/problems-search.mp4": "9c37d83b",
  "images/global-filtering/5-user-experience/carousel/scrolling-problems-placeholder.png": "ef059fd6",
  "images/global-filtering/5-user-experience/carousel/search-problems-placeholder.png": "ef059fd6",
  "images/global-filtering/5-user-experience/filter-matrix.png": "368ef548",
  "images/global-filtering/5-user-experience/final-full-screen-recording-c.mp4": "98e8e91d",
  "images/global-filtering/5-user-experience/final-full-screen-recording.mov": "482395ab",
  "images/global-filtering/5-user-experience/final-mockup.png": "1c896a08",
  "images/global-filtering/5-user-experience/ftue-video.mp4": "85137eeb",
  "images/global-filtering/5-user-experience/new-panel-1.png": "d1fe4ca9",
  "images/global-filtering/5-user-experience/new-panel-2.png": "47ef6afe",
  "images/global-filtering/5-user-experience/new-panel-3.png": "11164912",
  "images/global-filtering/5-user-experience/new-panels-interactive.png": "41bd3546",
  "images/global-filtering/5-user-experience/old-panel-1.png": "02564cc0",
  "images/global-filtering/5-user-experience/old-panel-2.png": "3f0f315c",
  "images/global-filtering/5-user-experience/old-panel-3.png": "7e06fd8a",
  "images/global-filtering/5-user-experience/old-panels-interactive.png": "e8d091af",
  "images/global-filtering/5-user-experience/Process-animation-c.gif": "29c8d50a",
  "images/global-filtering/5-user-experience/process-animation-faster.mp4": "e866e325",
  "images/global-filtering/5-user-experience/Process-animation.gif": "c2bddb81",
  "images/global-filtering/5-user-experience/process-animation.psd": "5148b3da",
  "images/global-filtering/5-user-experience/process-gif-placeholder.png": "e2b61b72",
  "images/global-filtering/6-iconography/icons-after.png": "22492857",
  "images/global-filtering/6-iconography/icons-before-after.png": "8a5a79fc",
  "images/global-filtering/6-iconography/icons-before.png": "76930ae2",
  "images/global-filtering/6-iconography/new-slider.png": "6844fa78",
  "images/global-filtering/6-iconography/old-slider.png": "52ac71a2",
  "images/global-filtering/7-user-adoption/ftue-animation-placeholder.png": "4a994816",
  "images/global-filtering/7-user-adoption/ftue-video-c.mp4": "fd1cadc5",
  "images/global-filtering/7-user-adoption/ftue-video.mp4": "85137eeb",
  "images/global-filtering/8-results/metric-numbers.png": "22b82291",
  "images/global-filtering/9-next-step/charts-vision-mock.png": "c28fc85b",
  "images/global-filtering/full-page-global-filtering.png": "7d1a6223",
  "images/grid/1-hero/grid-hero image.jpg": "9de30051",
  "images/grid/1-hero/grid-hero-image.png": "c5228850",
  "images/grid/2-problem/carousel/problems-alignment.png": "113287b1",
  "images/grid/2-problem/carousel/problems-padding.png": "5e7cbdc1",
  "images/grid/2-problem/carousel/problems-scaling-placeholder.png": "28e1f03d",
  "images/grid/2-problem/carousel/problems-section-types.png": "4fa6a01f",
  "images/grid/2-problem/carousel/scaling-issue.gif": "e6dd981f",
  "images/grid/3-research/google-editor.png": "fe5bc2ef",
  "images/grid/3-research/google-grid.png": "f1aee09c",
  "images/grid/3-research/google-grid2-extra.png": "a8192fa8",
  "images/grid/3-research/logo-google.png": "55b8d32b",
  "images/grid/3-research/logo-salesforce.png": "51bdfa7f",
  "images/grid/3-research/salesforce-editor.png": "d759fc7f",
  "images/grid/3-research/salesforce-grid.png": "8c250e70",
  "images/grid/3-research/typical-layout.png": "caa6e550",
  "images/grid/3-research/user-data.png": "0c52df03",
  "images/grid/4-grid/final-grid.png": "5f939097",
  "images/grid/4-grid/final-scaling-placeholder.png": "e1006080",
  "images/grid/4-grid/grid scaling on product.mov": "5f64b13b",
  "images/grid/4-grid/grid-animation-1440.gif": "957eff3c",
  "images/grid/4-grid/grid-animation.gif": "d6f95655",
  "images/grid/4-grid/grid-scaling-on-product-c.mp4": "26fc16ca",
  "images/grid/4-grid/iterations-video-placeholder.png": "1b0ad7c3",
  "images/grid/4-grid/iterations.png": "5ab8d38f",
  "images/grid/5-improved-ui/final-ui-design.png": "46d2be90",
  "images/grid/5-improved-ui/graphic-component.png": "1173ac68",
  "images/grid/5-improved-ui/graphic-exports.png": "4a2755b2",
  "images/grid/5-improved-ui/graphic-font.png": "af05c434",
  "images/grid/5-improved-ui/graphic-padding.png": "6401f9de",
  "images/grid/5-improved-ui/grid-ui-animation.gif": "ad05233c",
  "images/grid/5-improved-ui/improved-ui-placeholder.png": "f9d19244",
  "images/grid/6-results/metric-numbers.png": "b294bd3b",
  "images/grid/7-next-step/vision-mock.png": "f0c2308f",
  "images/grid/8-gallery/1-columns.png": "af6566e2",
  "images/grid/8-gallery/2-snapping.png": "8fe1aa3d",
  "images/grid/8-gallery/3-sections.png": "65407386",
  "images/grid/8-gallery/4-deal-info.jpg": "64713beb",
  "images/grid/8-gallery/5-template-editor.png": "c714a581",
  "images/grid/8-gallery/6-deal-info.png": "64b72f4a",
  "images/grid/8-gallery/7-edit-mode.png": "edd67e21",
  "images/grid/8-gallery/8-property-info.png": "4252f112",
  "images/guiding-the-style/1-hero/original-sg-graphic.png": "6626a25d",
  "images/guiding-the-style/1-hero/styleguide-gray-c.jpg": "2f480a15",
  "images/guiding-the-style/1-hero/styleguide-gray.png": "8d940bba",
  "images/guiding-the-style/1-hero/styleguide-hero.png": "a08e52b2",
  "images/guiding-the-style/1-hero/styleguide-shorter-c.jpg": "4bbb66c2",
  "images/guiding-the-style/1-hero/styleguide-shorter.png": "bb7c8ce8",
  "images/guiding-the-style/10-gallery/buttons.png": "b5896b97",
  "images/guiding-the-style/10-gallery/cards.png": "e13db12b",
  "images/guiding-the-style/10-gallery/color-palette.png": "edc50289",
  "images/guiding-the-style/10-gallery/cursors.png": "56b74ae2",
  "images/guiding-the-style/10-gallery/dropdowns.png": "33ee24f9",
  "images/guiding-the-style/10-gallery/iconography.png": "c7e15615",
  "images/guiding-the-style/10-gallery/inputs.png": "93193393",
  "images/guiding-the-style/10-gallery/notifications.png": "f23466ca",
  "images/guiding-the-style/10-gallery/tables.png": "052d0fb5",
  "images/guiding-the-style/10-gallery/typography.png": "cefcbe9f",
  "images/guiding-the-style/2-problem/carousel/problem-guide.png": "baef4583",
  "images/guiding-the-style/2-problem/carousel/problem-icons.png": "aeeb2c40",
  "images/guiding-the-style/2-problem/carousel/problem-modals.png": "b4bf14bd",
  "images/guiding-the-style/2-problem/carousel/problem-symbols.png": "c7c8e5c5",
  "images/guiding-the-style/2-problem/carousel/problem-zeplin.png": "2892f00f",
  "images/guiding-the-style/3-research/atlassian.png": "14dfbbef",
  "images/guiding-the-style/3-research/material-design.png": "cbd6162a",
  "images/guiding-the-style/3-research/shopify.png": "ba9ce69c",
  "images/guiding-the-style/4-organization/new symbols page.mov": "05481092",
  "images/guiding-the-style/4-organization/new-menu-list.png": "c34ca3f0",
  "images/guiding-the-style/4-organization/new-menu.png": "ce12f4bd",
  "images/guiding-the-style/4-organization/new-sketch-walkthrough-placeholder.png": "aa59c963",
  "images/guiding-the-style/4-organization/new-styleguide-c.mp4": "a0e6586b",
  "images/guiding-the-style/4-organization/new-styleguide.mov": "b335ef79",
  "images/guiding-the-style/4-organization/new-symbols-dropdown.png": "0f5af3fd",
  "images/guiding-the-style/4-organization/new-symbols-page-c.mp4": "fa556c51",
  "images/guiding-the-style/4-organization/new-symbols-walkthrough-placeholder.png": "aa59c963",
  "images/guiding-the-style/4-organization/old symbols page.mov": "fc250750",
  "images/guiding-the-style/4-organization/old-menu-list.png": "c75ca75b",
  "images/guiding-the-style/4-organization/old-menu.png": "fc16082e",
  "images/guiding-the-style/4-organization/old-sketch-walkthrough-placeholder.png": "aa59c963",
  "images/guiding-the-style/4-organization/old-styleguide-c.mp4": "53ac99b6",
  "images/guiding-the-style/4-organization/old-styleguide.mov": "31cd6c17",
  "images/guiding-the-style/4-organization/old-symbols-dropdown.png": "cf630d93",
  "images/guiding-the-style/4-organization/old-symbols-page-c.mp4": "aadb7fca",
  "images/guiding-the-style/4-organization/old-symbols-walkthrough-placeholder.png": "aa59c963",
  "images/guiding-the-style/5-out-of-date/calendar-process.png": "281a144f",
  "images/guiding-the-style/5-out-of-date/confusing-mock.png": "be2055c1",
  "images/guiding-the-style/5-out-of-date/new-zeplin.png": "2f8ebc79",
  "images/guiding-the-style/5-out-of-date/old-zeplin.png": "fbfad9e6",
  "images/guiding-the-style/5-out-of-date/recurring-meetings.png": "4c8d0142",
  "images/guiding-the-style/5-out-of-date/slack-messages.png": "0141221b",
  "images/guiding-the-style/6-incomplete/carousel/incomplete-buttons.png": "13c23c5f",
  "images/guiding-the-style/6-incomplete/carousel/incomplete-dropdowns.png": "01db4329",
  "images/guiding-the-style/6-incomplete/carousel/incomplete-modals.png": "750ae6c9",
  "images/guiding-the-style/6-incomplete/icon-dropdown.png": "815a711f",
  "images/guiding-the-style/6-incomplete/new-icons.png": "0f32705e",
  "images/guiding-the-style/6-incomplete/old-icons.png": "47bb0cbf",
  "images/guiding-the-style/6-incomplete/pdf-recording-c.mp4": "84d86699",
  "images/guiding-the-style/6-incomplete/pdf-recording.mov": "b3901b45",
  "images/guiding-the-style/6-incomplete/styleguide-walkthrough.png": "5026adbb",
  "images/guiding-the-style/6-incomplete/template page.mov": "e1331254",
  "images/guiding-the-style/6-incomplete/template-page-c.mp4": "129c6e67",
  "images/guiding-the-style/6-incomplete/template-page-placeholder.png": "1cb6a94e",
  "images/guiding-the-style/7-adaptability/abstract walkthrough.mov": "33498698",
  "images/guiding-the-style/7-adaptability/abstract-walkthrough-c.mp4": "5fe890ac",
  "images/guiding-the-style/7-adaptability/abstract-walkthrough.png": "9827ea61",
  "images/guiding-the-style/7-adaptability/guide recording.mov": "de4c5d9b",
  "images/guiding-the-style/7-adaptability/guide-recording-c.mp4": "2a404e5f",
  "images/guiding-the-style/7-adaptability/team-growth.png": "1daa1cef",
  "images/guiding-the-style/7-adaptability/ui-guide-placeholder.png": "e941941f",
  "images/guiding-the-style/9-next-step/sketch-figma-edited.png": "3f5845a0",
  "images/guiding-the-style/9-next-step/sketch-figma.png": "e37de554",
  "images/about-me/hannah-silly.jpg": "de504c0b"
};
