import * as React from 'react';
import classNames from 'classnames';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/two_pane_image/two_pane_image.css';
import {PageImage} from 'hannah/src/app/frontend/components/layout/page_image/page_image';
import {
	Caption2,
} from 'hannah/src/app/frontend/typography';

export interface TwoPaneImageProps {
	leftImageUrl: string;
	rightImageUrl: string;
	marginTop?: boolean;
	marginBottom?: boolean;
	flip?: boolean;
	caption: string;
	aspectRatioExpression: string;
}

const _TwoPaneImage = (props: TwoPaneImageProps) => {
	const {
		leftImageUrl,
		rightImageUrl,
		flip = false,
		caption,
		marginTop,
		marginBottom,
		aspectRatioExpression,
	} = props;

	const cx = classNames({
		[classes.outer_container]: true,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom
	});

	const imagesContainerCx = classNames({
		[classes.images_container]: true,
		[classes.images_container_flipped]: flip,
	});

	const aspectRatioStyles = {
		paddingBottom: `calc(100% * ${aspectRatioExpression})`,
	};

	return (
		<figure className={cx}>
			<div className={classes.inner_container}>
				<div className={classes.aspect_ratio_sizing_parent}>
					<div style={aspectRatioStyles}>
						<div className={imagesContainerCx}>
							<div className={classes.left_image_container}>
								<PageImage src={leftImageUrl} className={classes.left_image} />
							</div>
							<div className={classes.right_image_container}>
								<PageImage src={rightImageUrl} className={classes.right_image} />
							</div>
						</div>
					</div>
				</div>
				{
					<Caption2>{caption}</Caption2>
				}
			</div>
		</figure>
	);
};

export const TwoPaneImage = React.memo(_TwoPaneImage);
