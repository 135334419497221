import * as React from 'react';
import classNames from 'classnames';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/page_subsection/page_subsection.css';

const _PageSubsection = (props: React.HTMLAttributes<HTMLDivElement>) => {
	const {children, ...rest} = props;
	const cx = classNames(
		classes.container,
		mediaSelectorClasses.content_width,
	);
	return (
		<div className={cx} {...rest}>
			{children}
		</div>
	);
};

export const PageSubsection = React.memo(_PageSubsection);
