import * as React from 'react';
import classNames from 'classnames';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/page_callouts_image/page_callouts_image.css';
import {Caption2} from 'hannah/src/app/frontend/typography';
import {CalloutsImage} from 'hannah/src/app/frontend/components/callouts_image/callouts_image';

export interface PageCalloutsImageCalloutItemProps {
	targetText: string;
	description: string;
	position: {
		top?: string;
		right?: string;
		bottom?: string;
		left?: string;
	};
}

export interface PageCalloutsImageProps {
	items: PageCalloutsImageCalloutItemProps[];
	src: string;
	caption?: string;
	marginTop?: boolean;
	marginBottom?: boolean;
	maxWidth?: number;
}

const _PageCalloutsImage = (props: PageCalloutsImageProps) => {
	const {
		items,
		src,
		caption,
		marginTop,
		marginBottom,
		maxWidth,
	} = props;

	const cx = classNames({
		[mediaSelectorClasses.content_width_max_wide]: true,
		[classes.container]: true,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
	});

	const calloutsImageItems = items.map(
		({targetText, description, position}: PageCalloutsImageCalloutItemProps) => {
			return {
				target: (
					<span
						className={classes.callout_target}
						style={position}>
						{targetText}
					</span>
				),
				popper: (
					<div className={classes.callout_popper}>
						{description}
					</div>
				),
				arrow: (
					<span className={classes.callout_popper_arrow} />
				),
			};
		}
	)

	return (
		<figure className={cx}>
			<CalloutsImage maxWidth={maxWidth} src={src} items={calloutsImageItems} />
			{
				caption && (
					<figcaption>
						<Caption2>{caption}</Caption2>
					</figcaption>
				)
			}
		</figure>
	);
};

export const PageCalloutsImage = React.memo(_PageCalloutsImage);
