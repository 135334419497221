import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import {HannahApp} from 'hannah/src/app/frontend/hannah_app';
import {getInitialPageLoadData} from 'hannah/src/app/isomorphic/data';

function init() {
	const initialPageLoadData = getInitialPageLoadData(location.pathname);
	ReactDOM.hydrate((
		<BrowserRouter>
			<HannahApp initialPageLoadData={initialPageLoadData} />
		</BrowserRouter>
	), document.getElementById('root'));
}

init();
