import {assetUrl} from 'hannah/src/app/frontend/static_utils';
import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';
import {ThreePaneImageCaptionStyles} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';

import {
	carousel,
	// coloredDotLegend,
	compareImage,
	fullWidthVideo,	
	h1,
	h2,
	image,
	lightboxThumbnails,
	link,
	orderedListCards,
	overline,
	page,
	paragraph,
	projectIntroCard,
	projectPageHeader,
	// quote,
	section,
	threePaneImage,
	twoPaneImage,
	video,
} from 'hannah/src/app/frontend/page_config';

export default page(
	projectPageHeader({
		heading: `Guiding the style`,
		subhead: `Defining and creating a guide for the company to follow.`,
	}),
	section(() => [
		overline(`Intro`),
		projectIntroCard({
			descriptionHeading: `The challenge`,
			descriptionParagraph: `
				Design and integrate a holistic design kit for Dealpath to help designers and engineers increase productivity. 
			`,
			roleHeading: `My role`,
			roleParagraph: `
				Research, interface design, typesetting, iconography, illustration, and team training.
			`,
			timeframeHeading: `Timeframe`,
			timeframeParagraph: `April 2019 - June 2019`,
		}),
		paragraph(`
			TLDR; This wasn’t a company defined project, but something I knew I had to take on for our design team and product to scale effectively. 
			Our old-style guide was created in 2014 and hadn’t been kept up to date, which meant both new designers and engineers were confused about what they should be following. 
			With our team growing and my shift into a leadership position, I knew I had to take it upon myself to create a beautiful, easy to follow guide. 
			I spent any time in between projects or nights and weekends to finish this, and it paid off. 
			Now there is one source of truth, and all designs follow the same guidelines, and the whole team is happy.
		`),
		paragraph(`
			Curious to learn more about what Dealpath does? See all case studies and the company background `,
			link({
				text: `here`,
				to: `/dealpath`,
			}),
			`.`
		),	
	]),
	section(() => [
		overline(`Discover`),
		h1(`Problem`),
		paragraph(`
			There was so much wrong with our existing style guide that I knew we had to start from scratch. 
			We built our original guide in Sketch, and we had several different pages where previous designers had just copied and pasted elements as they were made. 
			This meant the pages were unorganized and impossible for new team members to follow.
		`),
		carousel({
			aspectRatioWidth: 726,
			aspectRatioHeight: 420,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/2-problem/carousel/problem-modals.png`),
					captionHeader: 'Component cluster',
					caption: `Our components page existed of several components that were pasted in no particular order.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/2-problem/carousel/problem-symbols.png`),
					captionHeader: 'Symbols page chaos',
					caption: `Since components were just pasted into the guide, this meant our symbols page was a mess.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/2-problem/carousel/problem-zeplin.png`),
					captionHeader: 'Zeplin styleguide redundancy',
					caption: `Zeplin is the primary tool that engineers use to reference designs, and ours had so many redundant colors and classes.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/2-problem/carousel/problem-icons.png`),
					captionHeader: 'Incomplete icons list',
					caption: `Our site has way more icons than shown here, but they were never added. This meant you had to either create or re-find icons which slows down your design process.`,
				},				
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/2-problem/carousel/problem-guide.png`),
					captionHeader: 'Unhelpful guide',
					caption: `Our “guide” on how to use the style guide only had a few essential tips but was in no way useful for new designers.`,
				},
			]
		}),
		h2(`Problem areas`),
		orderedListCards({
			items: [
				{
					topic: `1. Organization: `,
					details: `
					The Sketch page layouts were more like a guessing game than an organized list of components. 
					There were too many pages, and each page has out of date symbols. 
					This meant the symbols page was also not organized and not a cohesive list of current symbols.
					`
				},
				{
					topic: `2. Out of date: `,
					details: `
						Both engineers and designers were confused about what the current version of designs were. 
						This meant there was always back and forth in Slack and meetings, which took time away from both teams.
					`
				},
				{
					topic: `3. Incomplete: `,
					details: `
						Modals, dropdowns, forms, and page templates are reused across the platform and not having the current versions meant they needed to be built from scratch every time.
					`
				},
				{
					topic: `4. Adaptability: `,
					details: `
						A growing product team meant that the guide needed to be easily adapted. 
						This meant a better guide and system in Abstract needed to be created.
					`
				},
			]
		}),
	]),
	section(() => [
		h1(`Research`),
		paragraph(`
			The first step to create the best style guide for Dealpath was to do extensive research. 
			A design system is something that most companies have, and several have open-source resources. 
			Atlassian, Shopify, and Google’s Material Design have excellent examples using the best practices today.
		`),
		threePaneImage({
			leftImageUrl: assetUrl(`images/guiding-the-style/3-research/material-design.png`),
			rightTopImageUrl: assetUrl(`images/guiding-the-style/3-research/atlassian.png`),
			rightBottomImageUrl: assetUrl(`images/guiding-the-style/3-research/shopify.png`),
		    flip: true,
			caption: {
				type: ThreePaneImageCaptionStyles.HEADING_SUBHEAD,
				heading: `Style guide inspiration`,
				subhead: `Atlassian, Shopify, and Google Material design have online design systems which was how I conducted most of my research.`,
			},
			aspectRatioExpression: `420 / 725`,
		}),
	]),
	section(() => [
		h1(`Hypothesis`),
		paragraph(`
			If Dealpath has a comprehensive style guide, both existing and new employees will save time in the design, engineering, and QA stages. 
			This will, in turn, save money for the company and improve the overall quality of the product.
		`),
	]),
	section(() => [
		overline(`Define`),
		h1(`Project Goals`),
		paragraph(`
			There were a few key goals in mind when starting this project:`
		),
		orderedListCards({
			items: [
				{
					topic: `Goal 1: `,
					details: `Include 100% of key pages and components within the style guide.`,
				},
				{
					topic: `Goal 2: `,
					details: `Shorten onboarding of new designers to only two weeks.`,
				},
				{
					topic: `Goal 3: `,
					details: `Cut down back and forth about design style questions in Slack by 50%.`,
				},
				{
					topic: `Goal 4: `,
					details: `Have one cohesive system that links the design style guide to the engineering style guide.`,
				},
			],
		}),
		paragraph(`
			We had only two months before new designers started so this project had to be completed by then. 
			I was the only designer at the time, which meant it was up to me to achieve all the goals. 
			Being the Type A person I am, I was up for the challenge! 
		`),
	]),
	section(() => [
		overline(`Ideate`),
		h1(`Organization`),
		h2(`Page layout`),
		paragraph(`
			The first step to create a cleaned-up style guide was to look at all the pages and see what was actually needed. 
			We had 12 pages in Sketch! 
			Pages like “Gavin’s mocks” weren’t going to be helpful for new designers so this needed to be better organized.
		`),
		twoPaneImage({
			leftImageUrl: assetUrl(`images/guiding-the-style/4-organization/old-menu.png`),
			rightImageUrl: assetUrl(`images/guiding-the-style/4-organization/old-menu-list.png`),
			caption: `Old Sketch pages`,
			aspectRatioExpression: `388 / 725`,
		}),
		paragraph(`
			From the video below, you can see that each page was organized differently and was mainly just a cluster of components in no particular order.
		`),
		fullWidthVideo({
			src: assetUrl(`images/guiding-the-style/4-organization/old-styleguide-c.mp4`),
			caption: `Old Sketch walkthrough`,
			dropshadow: true,
		}),
		paragraph(`
			To fix the organization, I narrowed out Sketch file to only have six pages. 
			These included everything we would need, and you no longer have to click across 12 different pages to try and hunt down what you were looking for.
		`),
		twoPaneImage({
			leftImageUrl: assetUrl(`images/guiding-the-style/4-organization/new-menu.png`),
			rightImageUrl: assetUrl(`images/guiding-the-style/4-organization/new-menu-list.png`),
			caption: `New Sketch pages`,
			aspectRatioExpression: `232 / 725`, // width / height
		}),
		paragraph(`
			Each page now has a cohesive style with a clear page title and subheaders to indicate what each component is.
		`),
		fullWidthVideo({
			src: assetUrl(`images/guiding-the-style/4-organization/new-styleguide-c.mp4`),
			caption: `New Sketch walkthrough`,
			dropshadow: true,
		}),
		paragraph(`
			Limiting the pages to only six has proven to be hugely successful. 
			The file size is smaller, and you know exactly where to look for each component. 
			I haven’t received a single question about where to find something now!
		`),
	]),
	section(() => [
		h2(`Symbols`),
		paragraph(`
			The symbols page is the most critical page within a style guide, yet it was the most neglected. 
			This is where all symbols are found so that you can just add them to the new design. 
			When there are several of the same, or they aren’t named correctly, it is impossible to use.
		`),
		fullWidthVideo({
			src: assetUrl(`images/guiding-the-style/4-organization/old-symbols-page-c.mp4`),
			caption: `Old Sketch symbols page`,
			dropshadow: true,
		}),
		paragraph(`
			To add a symbol you just select the dropdown from Sketch and choose which to insert. 
			As you can see from the image below our buttons, cursors, flags, etc. were all out of place and not neatly organized in folders like they should be.
		`),
		image({
			src: assetUrl(`images/guiding-the-style/4-organization/old-symbols-dropdown.png`),
			caption: `Old symbols dropdown`,
			dropshadow: true,
		}),
		paragraph(`
			To fix this, I went through and removed all old styles and renamed every symbol so that they would group nicely in folders.
		`),
		fullWidthVideo({
			src: assetUrl(`images/guiding-the-style/4-organization/new-symbols-page-c.mp4`),
			caption: `New Sketch symbols page`,
			dropshadow: true,
		}),
		paragraph(`
			Now the dropdown is bucketed into groups, and all names are easy to understand for a new designer.
		`),
		image({
			src: assetUrl(`images/guiding-the-style/4-organization/new-symbols-dropdown.png`),
			caption: `New symbols dropdown`,
			dropshadow: true,
		}),
		paragraph(`
			This change alone saved our design team at least an hour a week because you no longer need to re-create icons or buttons because you weren’t sure where to find it.
		`)
	]),
	section(() => [
		h1(`Out of date`),
		h2(`Engineer collaboration`),
		paragraph(`
			Dealpath is a close-knit team of only about 50 employees. 
			All of the product team and engineering teams are located in-house, which meant there was a lot of collaboration between us. 
			The design system was confusing to the product team, so it was even more confusing for the engineers building it. 
			This meant questions like the one below were always being asked.
		`),
		image({
			src: assetUrl(`images/guiding-the-style/5-out-of-date/slack-messages.png`),
			caption: `Actual Slack conversation`,
			dropshadow: true,
		}),	
		paragraph(`
			Dealpath is an agile company with 2-week sprints. 
			This means we are regularly shipping new features and moving extremely fast.
		`),			
		image({
			src: assetUrl(`images/guiding-the-style/5-out-of-date/calendar-process.png`),
			caption: `2-week sprint schedule`,
			dropshadow: true,
		}),	
		paragraph(`
			Zeplin was the primary way we shared our designs with the engineers, and because our style guide was not organized, neither was Zeplin. 
			We had redundant colors and old colors and fonts listed, which made things confusing for the engineers.
		`),
		image({
			src: assetUrl(`images/guiding-the-style/5-out-of-date/old-zeplin.png`),
			caption: `Old Zeplin styleguide`,
			dropshadow: true,
		}),	
		paragraph(`
			Since Zeplin wasn’t up-to-date, this meant our mocks had to be very detailed. 
			The mock below is an example of what we would need to do because the guide did not make it clear what the default padding or colors were.
		`),
		image({
			src: assetUrl(`images/guiding-the-style/5-out-of-date/confusing-mock.png`),
			caption: `Mock example with annotations`,
			dropshadow: true,
			maxWidth: 538
		}),	
		paragraph(`
			Now the style guide is organized and only has the colors and fonts used in all designs.
		`),									
		image({
			src: assetUrl(`images/guiding-the-style/5-out-of-date/new-zeplin.png`),
			caption: `New Zeplin Styleguide`,
			dropshadow: true,
		}),	
		paragraph(`
			In order to make sure the teams were unblocked, I set up recurring meetings with key team members to make sure all questions were answered. 
			This significantly reduced the back and forth in Slack, and designs were correctly executed.
		`),
		image({
			src: assetUrl(`images/guiding-the-style/5-out-of-date/recurring-meetings.png`),
			caption: `Design and engineering meetings`,
			dropshadow: true,
		}),
	]),
	section(() => [
		h1(`Incomplete`),
		h2(`Modals, dropdowns, and forms`),
		paragraph(`
			Re-usable components are essential to keep the product consistent. 
			There should only really be a few different versions of modals, dropdowns, and forms so that users know how to use the product. 
			We did a pretty good job at re-using elements, but the problem was that we had this knowledge in our brain and not in a style guide.
		`),	
		carousel({
			aspectRatioWidth: 730,
			aspectRatioHeight: 323,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/6-incomplete/carousel/incomplete-modals.png`),
					captionHeader: 'Out-of-date modals',
					caption: `These modals were either out-of-date or one-off cases that didn’t need to be in the guide.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/6-incomplete/carousel/incomplete-dropdowns.png`),
					captionHeader: 'Dropdown cluster',
					caption: `This cluster format is nearly impossible to find anything. It is more like a Where’s Waldo game than an organized list.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/guiding-the-style/6-incomplete/carousel/incomplete-buttons.png`),
					captionHeader: 'Missing buttons and forms',
					caption: `Our buttons and forms looked a little better, but they still didn’t contain everything needed.`,
				},
			]
		}),
		paragraph(`
			In order to fix this, we created separate artboards for input containers, dropdowns, etc. 
			Now the guide lays out how to use each type of component and even the different states and responsive sizes.
		`),									
		fullWidthVideo({
			src: assetUrl(`images/guiding-the-style/6-incomplete/pdf-recording-c.mp4`),
			caption: `Updated Sketch components`,
			dropshadow: true,
		}),	
		paragraph(`
			This change allowed us to group inputs in our symbols dropdown which means when you create a new modal, all you do is select the type of inputs to add and you are done!
		`),
	]),
	section(() => [
		h2(`Icons`),
		paragraph(`
			From the slider below, you can see that we were missing about half of the icons that we use regularly. 
			Although it is fun to design a new icon, this really shouldn’t be done on a daily basis because all icons will look slightly different across the product.
		`),		
		compareImage({
			leftImageUrl: assetUrl(`images/guiding-the-style/6-incomplete/old-icons.png`),
			rightImageUrl: assetUrl(`images/guiding-the-style/6-incomplete/new-icons.png`),
			leftImageAlt: `Old icons`,
			rightImageAlt: `Updated icons`,
			caption: `Old vs. new icons`,
		}),
		paragraph(`
			Now the icons are located in the icon folder dropdown, and you can place an icon in a matter of seconds!
		`),									
		image({
			src: assetUrl(`images/guiding-the-style/6-incomplete/icon-dropdown.png`),
			caption: `Updated icon dropdown`,
			dropshadow: true,
		}),	
	]),
	section(() => [
		h2(`Templates`),
		paragraph(`
			When I first started at Dealpath we only had a few of the pages saved in Sketch which meant in order to tweak a small thing on the page you had to recreate the entire page. 
			This was time-consuming and unnecessary. 
			I created a separate templates page that has all key pages, so you never have to recreate the page.
		`),		
		fullWidthVideo({
			src: assetUrl(`images/guiding-the-style/6-incomplete/template-page-c.mp4`),
			caption: `New template page`,
			dropshadow: true,
		}),	
		paragraph(`
			This not only saves us half of the time to do our designs, but it has prevented engineering confusion. 
			Engineers before were unsure what had changed because the template didn’t match what was exactly on the product.
		`),	
	]),
	section(() => [
		h1(`Adaptability`),
		paragraph(`
			Over the course of four years at Dealpath our company grew from about ten people to over 60. 
			This meant there was a ton of onboarding and training new designers and engineers. 
			Recently the engineering team grew so much a new pod was added. 
			This meant an adaptable design system was needed to onboard the new engineers.
		`),	
		image({
			src: assetUrl(`images/guiding-the-style/7-adaptability/team-growth.png`),
			caption: `Engineering pod growth`,
		}),	
		h2(`Guidance`),
		paragraph(`
			In order to make the design system usable by everyone, it was essential to have a clear guide. 
			The old guide had pink font everywhere and was confusing to following. 
			The new guide now has headers and subheaders defining the most critical pieces of the guide.
		`),
		video({
			src: assetUrl(`images/guiding-the-style/7-adaptability/guide-recording-c.mp4`),
			caption: `New guide in Sketch`,
			dropshadow: true,
		}),	
		paragraph(`
			The updated guide was added to both Zeplin and Sketch so that engineers and designers can access it. 
			In addition, I printed all pages of the style guide and hung up in the office so that everyone can see and ask questions.
		`),
		h2(`Design collaboration`),
		paragraph(`
			The last piece of style guide was to make sure it is accessible by all product members. 
			I implemented Abstract as a way to share design files and the style guide.
		`),
		fullWidthVideo({
			src: assetUrl(`images/guiding-the-style/7-adaptability/abstract-walkthrough-c.mp4`),
			caption: `Abstract walkthrough`,
			dropshadow: true,
		}),	
		paragraph(`
			I was able to finish the style guide a week before a new designer started and was confident that this would greatly improve the onboarding experience.
		`),			
	]),
	section(() => [
		overline(`Evaluate`),
		h1(`Results`),
		paragraph(`
			This project was a huge success! 
			Not only is everything clean and much more usable, we were able to onboard a new designer and start them on a project their first week!
		`),
	]),
	section(() => [
		h1(`Next step`),
		paragraph(`
			Our initial style guide and components were built using Sketch, which made a lot of sense at the time because it was a small design team. 
			Now that the design team is growing and collaboration is more important, we have been looking into switching to Figma. 
			This is something that the team will decide on together, but thanks to the organized style guide, it will be a fairly seamless switch.
		`),
		image({
			src: assetUrl(`images/guiding-the-style/9-next-step/sketch-figma-edited.png`),
			caption: `Moving from Sketch to Figma`,
		}),	
		paragraph(`
			The style guide is in a great place, but as we all know, the process of refinement and learning is never over. 
			We will continue to adapt and improve the guide and never neglect it again!
		`),
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/color-palette.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/typography.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/cursors.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/iconography.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/buttons.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/notifications.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/inputs.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/dropdowns.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/tables.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/guiding-the-style/10-gallery/cards.png`),
					caption: `blah blah.`,
				}			
			],
		}),
	]),
);
