import {assetUrl} from 'hannah/src/app/frontend/static_utils';
import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';
import {ThreePaneImageCaptionStyles} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';

import {
	carousel,
	//coloredDotLegend,
	//compareImage,
	crossfadingImages,
	h1,
	h2,
	image,
	lightboxThumbnails,
	longQuote,
	//orderedListCards,
	overline,
	page,
	paragraph,
	projectIntroCard,
	projectPageHeader,
	section,
	//video,
	threePaneImage,
	fullWidthFigureGroup,

} from 'hannah/src/app/frontend/page_config';

export default page(
	projectPageHeader({
		heading: `Archive`,
		subhead: `Always exploring. Always learning.`,
	}),
	section(() => [
		overline(`Intro`),
		projectIntroCard({
			descriptionHeading: `The challenge`,
			descriptionParagraph: `
				I pivoted from architecture to digital design in 2014. 
				Design has always played a huge role in my life, and I never stop creating. 
				I wanted a page for side projects and proud accomplishments, so I created this archive to share.
			`,
			roleHeading: `My role`,
			roleParagraph: `
				Always encourage myself to think outside the box.
			`,
			timeframeHeading: `Timeframe`,
			timeframeParagraph: `September 2013 - Present`,
		}),
		paragraph(
			`
				TLDR; these projects range from architecture to hand drawings and photography. 
				I have been designing my entire life and love all aspects of creating. 
				I have built architecture projects in the Bay Area as well as have an active food Instagram. 
				I am always exploring and getting inspired by others, so this list could go on forever, but these are a few of my top accomplishments that define me as a designer.
			`,
		),
	]),
	section(() => [
		overline(`Architecture`),
		h1(`Santa Barbara Mesa`),
		paragraph(`
			I graduated in 2014 from Cal Poly, San Luis Obispo with a degree in Landscape Architecture. 
			As a senior thesis project, I was able to redesign one of my favorite places in the world, the Santa Barbara Mesa. 
			The Mesa is a gorgeous oceanfront property near the downtown area, but it hasn’t been remodeled since the 1970s. 
			The bluffs are eroding so this design was intended to make the space more usable, while at the same time help improve the ecosystem.
		`),
		crossfadingImages({
			imageSrcs: [
				assetUrl(`images/archive/2-sb-mesa/mesa-deck-perspective.jpg`),
				assetUrl(`images/archive/2-sb-mesa/mesa-on-deck.jpg`),
				assetUrl(`images/archive/2-sb-mesa/mesa-night.jpg`),
			],
			intervalMS: 3000,
			caption: `Project shots`,
			aspectRatioExpression: `1238 / 2202`,
			dropshadow: true,
		}),
		h2(`The process`),		
		paragraph(`
			This was a massive one year project that consisted of several months of extensive research and planning. 
			Understanding the topography, soils, and surrounding areas was vital to this project because the ultimate goal was to get it constructed.
		`),
		carousel({
			aspectRatioWidth: 628,
			aspectRatioHeight: 364,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-activity-bubbles.png`),
					captionHeader: 'Age demographics',
					caption: `After gathering census data, I understood the demographics of the area and started brainstorming activities for the groups.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-age-bubbles.png`),
					captionHeader: 'Project goals',
					caption: `Exercise, play, and learning was my main focus of the project.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-relationship-bubbles.png`),
					captionHeader: 'Bubble diagrams',
					caption: `To understand the activities and relationships with each other, bubble diagrams were essential.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-topography.png`),
					captionHeader: 'Topography challenges',
					caption: `The primary reason I chose this site was that the bluffs were eroding and we need to design for the future.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-trails.png`),
					captionHeader: 'Local trails',
					caption: `The Santa Barbara Mesa has existing trails, but several are forced through busy streets. There should be one continuous safe loop for pedestrians.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-soil.png`),
					captionHeader: 'Soil mapping',
					caption: `To create a successful planting plan, I needed to understand what types of soils I was working with.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-matrix.png`),
					captionHeader: 'Feature matrix',
					caption: `Similar to product design, matrices are used in architecture to understand critical project features.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-sketches.png`),
					captionHeader: 'Initial sketches',
					caption: `Sketching out elements and play structures helps start the brainstorming process and helps the community understand your vision.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/2-sb-mesa/process/process-plan.png`),
					captionHeader: 'Plan view',
					caption: `To understand the site, I created a hand drawing of the area in the plan view.`,
				},										
			]
		}),	
		h2(`Outcome`),		
		paragraph(`
			Since I wanted this school project to become a reality, I met with local architects and explained my plan. 
			They thoroughly understood the need for the project and decided to partner with me on it. 
			The first step was improving pedestrian safety and the flow of traffic, so a new roundabout has been constructed. 
		`),	
		threePaneImage({
			leftImageUrl: assetUrl(`images/archive/2-sb-mesa/outcome/award.jpg`),
			rightTopImageUrl: assetUrl(`images/archive/2-sb-mesa/outcome/project-photo.jpg`),
			rightBottomImageUrl: assetUrl(`images/archive/2-sb-mesa/outcome/built.jpg`),
		    flip: true,
			caption: {
				type: ThreePaneImageCaptionStyles.HEADING_SUBHEAD,
				heading: `Finished project and awards`,
				subhead: `With each major interaction, we more than doubled our conversion rate.`,
			},
			aspectRatioExpression: `420 / 725`,
		}),	
		paragraph(`
			I presented this project to the entire graduating class, and I won first place. 
			I also received the ASLA Award of Merit, a very prestigious award. 
			The best part is that Santa Barbara has already allocated $700,000 for this project! 
		`),	
		longQuote(`\u201C The City Council voted last night to allocate $700,000 to complete the necessary funding for the Roundabout at Cliff Drive and Positas! \u201D`),
		paragraph(`
			Often when I mention that my degree is in architecture, people seem surprised that I was able to shift into product design so smoothly. 
			The truth is that they are incredibly similar. 
			Both have a research phase, user testing, visual design iterations, and development. 
			With architecture, you are designing a space for people to interact with and with product design you are as well. 
			This year-long project advanced me as a designer and led me to where I am today.
		`),							
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/mesa-plan.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/mesa-topo.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/deck-perspective.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/deck-plan.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/deck-perspective-full.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/night-perspective.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/viewing-perspective.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/section.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/isometric.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/2-sb-mesa/gallery/plant-plan.jpg`),
					caption: `blah blah.`,
				},					
			],
		}),									
	]),
	section(() => [
		h1(`RRM Design Group`),
		paragraph(`
			RRM Design Group is a multi-disciplinary design firm located in San Luis Obispo. 
			I was fortunate to be the only student chosen to work at the company while in school, and I continued working at the firm for over a year and a half. 
			I led most of the visual design and sketches for the office and even led massive million-dollar projects.
		`),
		crossfadingImages({
			imageSrcs: [
				assetUrl(`images/archive/3-rrm/playground-sketch.jpg`),
				assetUrl(`images/archive/3-rrm/house-sketch.jpg`),
				assetUrl(`images/archive/3-rrm/trail-plan.jpg`),
			],
			intervalMS: 3000,
			caption: `Project sketches`,
			aspectRatioExpression: `1260 / 2200`,
			dropshadow: true,
		}),
		h2(`Team process`),		
		paragraph(`
			The San Luis Obispo office had about 50 employees, and we focused on creating parks and trail systems for government funding projects (primarily low-income communities). 
			We led several community meetings to make sure we were designing what was needed, and several projects are now completed in the Bay Area.
		`),
		carousel({
			aspectRatioWidth: 635,
			aspectRatioHeight: 368,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/3-rrm/process/rrm-flow.jpg`),
					captionHeader: 'Typical design process',
					caption: `Although every project varied in scope, we typically follow this design process.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/3-rrm/process/huddle.jpg`),
					captionHeader: 'Collaborative environment',
					caption: `The team consisted of architects, engineers, graphic designers, and urban planners, so we were continually huddling and collaborating on projects.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/3-rrm/process/community-feedback.jpg`),
					captionHeader: 'Community gatherings',
					caption: `To engage the community and get support, we would go to the site and have everyone vote on what they like.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/3-rrm/process/trail-plan-smaller.jpg`),
					captionHeader: 'Plan visualizations',
					caption: `I often created the site plans to bring to community meetings and share with city members.`,
				},
				{				
				type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/3-rrm/process/kennedy-sketch.jpg`),
					captionHeader: 'Perspective sketches',
					caption: `Sketching was one of my favorite aspects of the job. I would use Photoshop to create a rendering of what would be built.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/3-rrm/process/greenwood-final.jpg`),
					captionHeader: 'Finished projects',
					caption: `This is a completed project located in Hayward, California. It is gratifying to see your hard work pay off and people enjoying your space.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/3-rrm/process/kids-playing.jpg`),
					captionHeader: 'Rewarding designs',
					caption: `There’s nothing quite like seeing children enjoy your park. This is from a project in San Lorenzo California.`,
				},				
			]
		}),	
		h2(`Finished projects`),		
		paragraph(`
			Several projects that I designed years ago at RRM are now under construction or just recently finished being built. 
			Architecture projects take much longer to complete than product design, but to see families enjoying the space is all worth it. 	
		`),							
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/archive/3-rrm/gallery/kennedy-plan.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/3-rrm/gallery/san-lorenzo-sketch.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/3-rrm/gallery/playground-sketch-smaller.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/3-rrm/gallery/kennedy-sketch.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/3-rrm/gallery/san-lorenzo-final.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/3-rrm/gallery/san-lorenzo-final2.jpg`),
					caption: `blah blah.`,
				},						
			],
		}),	
	]),
	section(() => [
		overline(`Illustration`),
		h1(`Beyond digital`),
		paragraph(`
			In addition to product design and architecture, I have many other creative passions. 
			Interior design, furniture design, sketching, party planning, and branding are all ways I get creative outside of digital design.
		`),	
		carousel({
			aspectRatioWidth: 635,
			aspectRatioHeight: 368,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/warm-springs-kitchen.jpg`),
					captionHeader: 'Kitchen redesign',
					caption: `My husband and I recently purchased a home in wine country which I was able to redesign and now rent as a vacation property.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/warm-springs-living.jpg`),
					captionHeader: 'Living room redesign',
					caption: `The goal of the living room design was to feel bright and modern.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/chair.jpg`),
					captionHeader: 'Furniture building',
					caption: `This fun chair was built using a laser cutter and was 3D modeled in AutoCAD.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/section-sketch.jpg`),
					captionHeader: 'Always sketching',
					caption: `Whether it is improving my home or sketching ideas for others, I am still playing with a pen and paper.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/office-organization.jpg`),
					captionHeader: 'Office organization',
					caption: `Dealpath’s office arrangement wasn’t encouraging people to interact with each other, so I worked with another designer to overhaul the space.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/party-planning.jpg`),
					captionHeader: 'Event planning',
					caption: `I was in charge of all event planning at Dealpath (because I love it). We hosted parties for hundreds of people.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/modal-building.jpg`),
					captionHeader: 'Model building',
					caption: `This is an art installation that was used to mimic the chipped curb paint along busy streets.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/archive/4-beyond-digital/company-swag.jpg`),
					captionHeader: 'Company branding',
					caption: `To make sure everything had the same voice and brand at Dealpath, I often led the designs for company rebranding or swag.`,
				},				
			]
		}),	
		paragraph(`
			Art has always been an outlet for me, and there is something special about creating something with your hands. 
			I am still creating and will never get tired of learning new design techniques.
		`),												
	]),
	section(() => [
		h1(`Cheese to plants`),
		paragraph(`
			My other passion in life is food. 
			There is not a single ingredient that I don’t like, and I am always testing out new recipes. 
			One way to combine my love for photography and food was to create a food Instagram. 
			I have been posting almost daily on my account, Cheese to Plants, which features mostly vegetarian and vegan recipes.
		`),
		fullWidthFigureGroup(
			image({
				src: assetUrl(`images/archive/6-cheese-to-plants/cheese-popcicle.png`),
			}),
			image({
				src: assetUrl(`images/archive/6-cheese-to-plants/cheese-feed.png`),
			}),
			image({
				src: assetUrl(`images/archive/6-cheese-to-plants/cheese-recipe.png`),
			}),		
		),		
		paragraph(`
			Cooking is the perfect way to unwind from the day and get inspired. 
			I am continuing to improve my photography skills and learning the importance of marketing from this project. 
			Each post includes a full recipe (mostly created by me) as well as images of the final result. 
			One consequence of this hobby is that I often bring in leftovers to my office, nobody has ever complained!
		`),					
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/archive/6-cheese-to-plants/gallery/pancake.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/6-cheese-to-plants/gallery/muffins.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/6-cheese-to-plants/gallery/pea-soup.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/6-cheese-to-plants/gallery/chia-pudding.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/6-cheese-to-plants/gallery/squash-soup.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/archive/6-cheese-to-plants/gallery/tortilla-soup.jpg`),
					caption: `blah blah.`,
				},										
			],
		}),		
	]),
);
