import * as React from 'react';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';

export interface EmailLinkProps {
	children: string;
}

export const EmailLink = (props: EmailLinkProps) => {
	const {
		children,
	} = props;

	return (
		<a className={mediaSelectorClasses.link}
			href={`mailto:${children}`}>
			{children}
		</a>
	);
};
