import * as React from 'react';
import classNames from 'classnames';

import {
	ImageTypes,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';

import {
	LightboxContext,
	addImageActionPayload,
	removeImageActionPayload,
	openLightboxActionPayload,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_context_manager';

import {ExpandIcon} from 'hannah/src/app/frontend/components/layout/expand_icon/expand_icon';
import {Caption2} from 'hannah/src/app/frontend/typography';
import {AutoplayVideo} from 'hannah/src/app/frontend/components/autoplay_video/autoplay_video';
import {ChromeUnderlay} from 'hannah/src/app/frontend/components/layout/chrome_underlay/chrome_underlay';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/page_video/page_video.css';
import {IS_MOBILE_OR_TABLET} from 'hannah/src/app/frontend/environment_utils';

export interface PageVideoProps {
	src: string;
	caption?: string;
	marginTop?: boolean;
	marginBottom?: boolean;
	sideMargins?: boolean;
	maxWidth?: number;
	playButton?: boolean;
	dropshadow?: boolean;
	chromeSrc?: string;
	chromePadding?: string;
}

// I don't really understand the HTMLAttributes typings...
const _PageVideo = (props: PageVideoProps) => {
	const {
		src,
		caption,
		marginTop,
		marginBottom,
		sideMargins = false,
		maxWidth,
		playButton,
		dropshadow,
		chromeSrc,
		chromePadding = '',
	} = props;

	const [isHovered, setIsHovered] = React.useState(false);

  	const {dispatch} = React.useContext(LightboxContext);

	React.useEffect(() => {
		if (dispatch) {
	  		dispatch(addImageActionPayload(src, ImageTypes.VIDEO, src, caption || ''));
		}
		return () => {
			if (dispatch) {
		  		dispatch(removeImageActionPayload(src));
			}
		}
	}, []);

	const handleMouseEnterVideo = () => {
		setIsHovered(true);
	};

	const handleMouseLeaveVideo = (event: any) => {
		setIsHovered(false);
	};

	const handleClickExpand = () => {
		if (dispatch) {
	  		dispatch(openLightboxActionPayload(src));
		}
	};

	const cx = classNames({
		[classes.container]: true,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
		[mediaSelectorClasses.content_side_margins]: sideMargins,
	});

	const videoCx = classNames({
		[mediaSelectorClasses.content_width_max_wide]: true,
		[classes.video]: true,
		[classes.video_dropshadow]: dropshadow,
	});


	const videoStyles = {
		maxWidth,
	};

	const videoInWrapper = (
		<div
			onMouseEnter={handleMouseEnterVideo}
			onMouseLeave={handleMouseLeaveVideo}
			className={classes.video_wrapper}>
			{
				isHovered && (
					<ExpandIcon onClick={handleClickExpand} />
				)
			}
			<AutoplayVideo
				playButton={playButton}
				style={videoStyles}
				className={videoCx}
				loop
				playsInline
				controls={IS_MOBILE_OR_TABLET}>
		  		<source src={src} />
			</AutoplayVideo>
		</div>
	);

	return (
		<figure className={cx}>
			{
				chromeSrc ? (
					<ChromeUnderlay src={chromeSrc} padding={chromePadding}>
						{videoInWrapper}
					</ChromeUnderlay>
				) : videoInWrapper
			}
			{
				caption && (
					<figcaption>
						<Caption2>{caption}</Caption2>
					</figcaption>
				)
			}
		</figure>
	);
};

export const PageVideo = React.memo(_PageVideo);
