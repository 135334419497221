import * as React from 'react';
import {spring, TransitionMotion, SpringHelperConfig} from 'react-motion';

interface CrossfadingScalingImageProps extends React.HTMLAttributes<HTMLDivElement> {
  imageClassName?: string;
  url: string;
  scaleUpRatio: number;
  opacitySpringConfig?: SpringHelperConfig;
  scaleSpringConfig?: SpringHelperConfig;
  enable?: boolean;
}

export class CrossfadingScalingImage extends React.Component<CrossfadingScalingImageProps> {

  willEnter = () => {
    const {scaleUpRatio} = this.props;
    return {
      opacity: 0,
      transformScale: scaleUpRatio,
    };
  }

  willLeave = () => {
    const {
      opacitySpringConfig,
      scaleSpringConfig,
    } = this.props;
    const {scaleUpRatio} = this.props;
    return {
      opacity: spring(0, opacitySpringConfig),
      transformScale: spring(scaleUpRatio, scaleSpringConfig),
    };
  }

  renderItem = (config: any) => {
    const {imageClassName} = this.props;
    const {url} = config.data;
    const {opacity, transformScale} = config.style;

    const styles = {
      backgroundImage: `url(${url})`,
      /*backgroundPosition: 'center',*/
      opacity: opacity,
      transform: `scale(${transformScale})`,
      transformProperty: 'background-position, padding-top',
    };

    return (
      <div key={config.key} className={imageClassName} style={styles}/>
    )
  }

  render() {
    const {
      url,
      opacitySpringConfig,
      scaleSpringConfig,
      scaleUpRatio,
      imageClassName,
      enable = true,
      ...rest
    } = this.props;

    return (
      <div {...rest}>{
        enable ? (
          <TransitionMotion
            willEnter={this.willEnter}
            willLeave={this.willLeave}
            styles={[{
              key: url,
              data: {
                url: url,
              },
              style: {
                opacity: spring(1, opacitySpringConfig),
                transformScale: spring(1, scaleSpringConfig),
              },
            }]}>
            {(interpolatedValues: any) => {
              return (
                <div>
                  {interpolatedValues.map(this.renderItem)}
                </div>
              );
            }}
          </TransitionMotion>
        ) : (
          <div>
            <div key={url} className={imageClassName} style={{
              backgroundImage: `url(${url})`,
            }}/>
          </div>
        )
      }
      </div>
    );
  }
}
