import * as React from 'react';
import classNames from 'classnames';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image.css';
import {PageImage} from 'hannah/src/app/frontend/components/layout/page_image/page_image';
import {
	Caption1,
	Caption2,
	H3,
} from 'hannah/src/app/frontend/typography';

export enum ThreePaneImageCaptionStyles {
	LEFT_RIGHT_SMALL,
	HEADING_SUBHEAD,
}

export interface ThreePaneImageLeftRightSmallCaptionProps {
	type: ThreePaneImageCaptionStyles.LEFT_RIGHT_SMALL;
	leftImage: string;
	rightImages: string;
}

export interface ThreePaneImageHeadingSubheadCaptionProps {
	type: ThreePaneImageCaptionStyles.HEADING_SUBHEAD;
	heading: string;
	subhead: string;
}

export interface ThreePaneImageProps {
	leftImageUrl: string;
	rightTopImageUrl: string;
	rightBottomImageUrl: string;
	marginTop?: boolean;
	marginBottom?: boolean;
	flip?: boolean;
	caption: ThreePaneImageLeftRightSmallCaptionProps | ThreePaneImageHeadingSubheadCaptionProps;
	aspectRatioExpression: string;
}

const ThreePaneImageLeftRightSmallCaption = (props: ThreePaneImageLeftRightSmallCaptionProps) => {
	const {
		leftImage,
		rightImages,
	} = props;

	return (
		<figcaption className={classes.left_right_small_figcaption}>
			<div className={classes.left_caption_container}>
				<Caption2>{leftImage}</Caption2>
			</div>
			<div className={classes.right_caption_container}>
				<Caption2>{rightImages}</Caption2>
			</div>
		</figcaption>
	);
};

const ThreePaneImageHeadingSubheadCaption = (props: ThreePaneImageHeadingSubheadCaptionProps) => {
	const {
		heading,
		subhead,
	} = props;

	return (
		<figcaption className={classes.heading_subhead_figcaption}>
			<H3>{heading}</H3>
			<Caption1>{subhead}</Caption1>
		</figcaption>
	);
};

const _ThreePaneImage = (props: ThreePaneImageProps) => {
	const {
		leftImageUrl,
		rightTopImageUrl,
		rightBottomImageUrl,
		flip = false,
		caption,
		marginTop,
		marginBottom,
		aspectRatioExpression,
	} = props;

	const cx = classNames({
		[classes.outer_container]: true,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom
	});

	const imagesContainerCx = classNames({
		[classes.images_container]: true,
		[classes.images_container_flipped]: flip,
	});

	const aspectRatioStyles = {
		paddingBottom: `calc(100% * ${aspectRatioExpression})`,
	};

	return (
		<figure className={cx}>
			<div className={classes.inner_container}>
				<div className={classes.aspect_ratio_sizing_parent}>
					<div style={aspectRatioStyles}>
						<div className={imagesContainerCx}>
							<div className={classes.left_image_container}>
								<PageImage src={leftImageUrl} className={classes.left_image} />
							</div>
							<div className={classes.right_images_container}>
								<div className={classes.right_top_image_container}>
									<PageImage src={rightTopImageUrl} className={classes.right_image} />
								</div>
								<div className={classes.right_bottom_image_container}>
									<PageImage src={rightBottomImageUrl} className={classes.right_image} />
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					caption.type === ThreePaneImageCaptionStyles.LEFT_RIGHT_SMALL && (
						<ThreePaneImageLeftRightSmallCaption {...caption} />
					) ||
					caption.type === ThreePaneImageCaptionStyles.HEADING_SUBHEAD && (
						<ThreePaneImageHeadingSubheadCaption {...caption} />
					)
				}
			</div>
		</figure>
	);
};

export const ThreePaneImage = React.memo(_ThreePaneImage);
