import classNames from 'classnames';
import * as React from 'react';

import {
	ImageTypes,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';
import {
	H3,
	Caption1,
} from 'hannah/src/app/frontend/typography';
import {AutoplayVideo} from 'hannah/src/app/frontend/components/autoplay_video/autoplay_video';
import {ExpandIcon} from 'hannah/src/app/frontend/components/layout/expand_icon/expand_icon';
import {
	LightboxContext,
	addImageActionPayload,
	removeImageActionPayload,
	openLightboxActionPayload,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_context_manager';
import 'hannah/vendor/royalslider/royalslider.css';
import * as classes from 'hannah/src/app/frontend/components/carousel/visible_nearby.css';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import {IS_MOBILE_OR_TABLET} from 'hannah/src/app/frontend/environment_utils';

declare global {
	interface JQuery {
		royalSlider: any;
	}
}

export enum CarouselItemType {
	IMAGE,
	IMAGE_WITH_DROPSHADOW,
	VIDEO,
}

export interface CarouselItemMeta {
	type: CarouselItemType;
	url: string;
	captionHeader: string;
	caption: string;
}

export interface CarouselProps {
	items: CarouselItemMeta[];
	aspectRatioWidth: number;
	aspectRatioHeight: number;
}

export interface CarouselState {
	royalSliderInitialized: boolean;
	sliderKey: number;
}

const CarouselItem = (props: CarouselItemMeta) => {
	const {type, url, captionHeader, caption} = props;
	const [isHovered, setIsHovered] = React.useState(false);

  	const {dispatch} = React.useContext(LightboxContext);

	React.useEffect(() => {
		if (dispatch) {
			let figureType: ImageTypes;
			if (type === CarouselItemType.IMAGE || type === CarouselItemType.IMAGE_WITH_DROPSHADOW) {
				figureType = ImageTypes.IMAGE;
			} else if (type === CarouselItemType.VIDEO) {
				figureType = ImageTypes.VIDEO;
			}
	  		dispatch(addImageActionPayload(url, figureType!, url, caption || ''));
		}
		return () => {
			if (dispatch) {
		  		dispatch(removeImageActionPayload(url));
			}
		}
	}, []);

	const handleMouseEnterItem = () => {
		setIsHovered(true);
	};

	const handleMouseLeaveItem = (event: any) => {
		setIsHovered(false);
	};

	const handleClickExpand = () => {
		if (dispatch) {
	  		dispatch(openLightboxActionPayload(url));
		}
	};


	function wrapElem(elem: JSX.Element, className?: string) {
		// https://dimsemenov.com/plugins/royal-slider/documentation/#global-caption
		// royalslider will extract the content from .rsCaption element and
		// use it to render the caption
		const figcaptionCx = classNames(
			'rsCaption',
			classes.figcaption
		);
		return (
			<div
				onMouseEnter={handleMouseEnterItem}
				onMouseLeave={handleMouseLeaveItem}
				className={className}>
				{
					isHovered && (
			  			<ExpandIcon onClick={handleClickExpand} />
					)
				}
			  	{elem}
				<figcaption className={figcaptionCx}>
					<H3>{captionHeader}</H3>
					<Caption1>{caption}</Caption1>
				</figcaption>
			</div>
		);
	}

	if (type === CarouselItemType.IMAGE) {
		return wrapElem(
			<img key={url} className="rsImg" src={url} alt={caption} />
		);
	} else if (type == CarouselItemType.IMAGE_WITH_DROPSHADOW) {
		return wrapElem(
			<div className={classes.image_with_dropshadow_container}>
				<img key={url} className="rsImg" src={url} alt={caption} />
			</div>
		);
	} else if (type === CarouselItemType.VIDEO) {
		return wrapElem(
			<AutoplayVideo loop playsInline controls={IS_MOBILE_OR_TABLET}>
		  		<source src={url} />
			</AutoplayVideo>,
			'rsVideoContainer'
		);
	}
	return null;
};

export class Carousel extends React.PureComponent<CarouselProps, CarouselState> {

	private element: HTMLDivElement;
	private sizingElem: HTMLDivElement;
	private loadjQueryWithSliderPromise: Promise<JQueryStatic>;
	private rsInstance: any;
	private resizeListener: EventListener;
	private previousSizingElemWidth: number;

	state = {
		royalSliderInitialized: false,
		sliderKey: 0,
	};

	constructor(props: CarouselProps) {
		super(props);
		this.loadjQueryWithSliderPromise = import('jquery').then((jQuery) => {
			// expose jQuery to the window for royalslider to access
			(window as any).jQuery = jQuery.default;
			// register the plugin
			import('hannah/vendor/royalslider/royalslider.min.js' as string);
			return jQuery.default;
		});
	}

	async componentDidMount() {
		// XXX OMG this code is so hacky I hate this stupid plugin
		// these could change, but whatever
		const {aspectRatioWidth, aspectRatioHeight} = this.props;
		const jQuery = await this.loadjQueryWithSliderPromise;
		// requestAnimationFrame is necessary so it scales correctly on page load

		const initRoyalSlider = (centerSlideWidthRatio: number, cb: () => void) => {

			const width = aspectRatioWidth;
			const height = aspectRatioHeight;
			const heightToWidthRatio = height / width;
			const bodyWidth = document.body.clientWidth;
			const sliderHeight = (
				heightToWidthRatio * bodyWidth * centerSlideWidthRatio
			) * 1.05; // hack that might always work to adjust for bad math in royalslider?

			const jqElem = jQuery(this.element);
			jqElem.royalSlider({
			    addActiveClass: true,
			    arrowsNav: false,
			    controlNavigation: 'none',
			    autoScaleSlider: true, 
			    // auto sizing will auto calculate based on this aspect ratio
			    autoScaleSliderWidth: bodyWidth,
			    autoScaleSliderHeight: sliderHeight,
			    loop: true,
			    fadeinLoadedSlide: false,
			    globalCaption: true,
			    keyboardNavEnabled: true,
			    globalCaptionInside: false,
			    visibleNearby: {
					enabled: true,
					centerArea: centerSlideWidthRatio,
					center: true,
					breakpoint: 0,
					navigateByCenterClick: false,
			    },
			});

			this.rsInstance = jqElem.data('royalSlider');

			// HACK resize it until it's the width of the window as we expect
			const attemptFixSize = () => {
				const wrapElem = document.querySelector('.rsVisibleNearbyWrap');
				if (wrapElem!.clientWidth < document.body.clientWidth) {
					requestAnimationFrame(() => {
						this.rsInstance.updateSliderSize();
						attemptFixSize();
					});
				} else {
					cb();
				}
			}

			this.setState({
				royalSliderInitialized: true,
			});

			attemptFixSize();
		}

		// HACK wait until the resizing element renders
		// it will have the same width as the body until it renders
		const attemptResizeSlidesToContentWidth = (cb: () => void) => {
			const cs = getComputedStyle(this.sizingElem);
			const paddingX = parseFloat(cs.paddingLeft!) + parseFloat(cs.paddingRight!);
			const elementWidth = this.sizingElem.clientWidth - paddingX * 2;

			requestAnimationFrame(() => {
				const widthRatio = elementWidth / document.body.clientWidth;
				// we know that this has padding, but it's rendered later for some reason
				if (widthRatio === 1 || paddingX === 0) {
					attemptResizeSlidesToContentWidth(cb);
				} else {
					initRoyalSlider(widthRatio, cb);
				}
			});
		};

		attemptResizeSlidesToContentWidth(() => {});

		let requested = false;

		const respondToResize = () => {
			if (this.sizingElem.clientWidth === this.previousSizingElemWidth) {
				return;
			}
			this.previousSizingElemWidth = this.sizingElem.clientWidth;
			const {sliderKey} = this.state;
			this.rsInstance.destroy();
			this.setState({
				sliderKey: sliderKey + 1,
				royalSliderInitialized: false,
			}, () => {
				attemptResizeSlidesToContentWidth(() => {
					requested = false;
				});
			});
		};

		this.resizeListener = () => {
			if (!requested) {
				// don't allow anything for a second after requesting
				// but once the second is up, then reload
				requested = true;
				setTimeout(() => {
					requestAnimationFrame(respondToResize);
				}, 1000);
			}
		};

		window.addEventListener('resize', this.resizeListener);

		this.previousSizingElemWidth = this.sizingElem.clientWidth;
	}

	componentWillUnmount() {
		if (this.rsInstance) {
			this.rsInstance.destroy();
		}
		window.removeEventListener('resize', this.resizeListener);
	}

	renderItem(item: CarouselItemMeta) {
		return (
			<CarouselItem key={item.url} {...item} />
		);
	}

	render() {
		const {items} = this.props;
		const {
			royalSliderInitialized,
			sliderKey,
		} = this.state;

		const figureCx = classNames({
			royalSlider: true,
			rsDefault: true,
			[classes.visible_nearby]: true,
			[classes.royal_slider_initialized]: royalSliderInitialized,
		});

		const sizingElemCx = classNames({
			[mediaSelectorClasses.content_side_padding]: true,
			[mediaSelectorClasses.content_width]: true,
		});

		return (
			<div>
				<div
					className={sizingElemCx}
					ref={(elem: HTMLDivElement) => this.sizingElem = elem}>
				</div>
				<figure
					key={sliderKey}
					ref={(elem: HTMLDivElement) => this.element = elem}
					className={figureCx}>
					{
						items.map(this.renderItem)
					}
				</figure>
			</div>
		);
	}
};
