import * as React from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';

export interface PageLinkProps extends NavLinkProps {
	text: string;
}

export const PageLink = (props: PageLinkProps) => {
	const {
		text,
		...rest,
	} = props;

	return (
		<NavLink
			className={mediaSelectorClasses.link}
			{...rest}
		>
			{text}
		</NavLink>
	);
};
