import * as React from 'react';

import {NavLink} from 'react-router-dom';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/project_cta_card/project_cta_card.css';

export interface ProjectCTACardProps {
	imageSrc: string;
	displayText: string; 
	buttonText: string;
	background: string;
	to: string;
}

export const ProjectCTACard = (props: ProjectCTACardProps) => {
	const {
		imageSrc,
		displayText,
		buttonText,
		background,
		to,
	} = props;

	return (
		<div className={classes.container} style={{background: background}}>
			<div className={classes.image_container}>
				<img className={classes.image} src={imageSrc} />
			</div>
			<div className={mediaSelectorClasses.project_cta_card_right_content}>
				<h3 className={mediaSelectorClasses.project_cta_card_display}>
					{displayText}
				</h3>
				<NavLink to={to} className={mediaSelectorClasses.project_cta_card_button}>
					{buttonText}
				</NavLink>
			</div>
		</div>
	);
};
