import * as React from 'react';
import classNames from 'classnames';
import {NavLink, NavLinkProps} from 'react-router-dom';

import * as classes from 'hannah/src/app/frontend/media_selectors.css';

export const H1 = (props: React.HTMLAttributes<HTMLHeadingElement>) => {
	return <h1 className={classes.h1} {...props} />;
};

export const H2 = (props: React.HTMLAttributes<HTMLHeadingElement>) => {
	return <h2 className={classes.h2} {...props} />;
};

export const H3 = (props: React.HTMLAttributes<HTMLHeadingElement>) => {
	return <h3 className={classes.h3} {...props} />;
};

export interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
	marginTop?: boolean;
	marginBottom?: boolean;
	marginTopSmall?: boolean;
}

export const Paragraph = (props: ParagraphProps) => {
	const {
		marginTop,
		marginBottom,
		marginTopSmall,
	} = props;

	const cx = classNames({
		[classes.paragraph]: true,
		[classes.paragraph_margin_top]: marginTop,
		[classes.paragraph_margin_bottom]: marginBottom,
		[classes.paragraph_margin_top_small]: marginTopSmall,
	});

	return (
		<p className={cx} {...props} />
	);
};

export const Bold = (props: React.HTMLAttributes<HTMLSpanElement>) => {
	return <strong className={classes.bold} {...props} />;
};

export const Caption1 = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
	return <p className={classes.caption_1} {...props} />;
};

export const Caption2 = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
	return <p className={classes.caption_2} {...props} />;
};

export const ColoredDotLegendText = (props: React.HTMLAttributes<HTMLSpanElement>) => {
	return <span className={classes.colored_dot_legend_text} {...props} />;
};

export const Overline = (props: React.HTMLAttributes<HTMLDivElement>) => {
	return <div className={classes.overline} {...props} />;
};

export const Quote1 = (props: React.HTMLAttributes<HTMLQuoteElement>) => {
	return <q className={classes.quote_1} {...props} />;
};

export const Quote2 = (props: React.HTMLAttributes<HTMLQuoteElement>) => {
	return <q className={classes.quote_2} {...props} />;
};

export const Logo = (props: React.HTMLAttributes<HTMLDivElement>) => {
	return <div className={classes.logo} {...props} />;
};

export const LogoSubhead = (props: React.HTMLAttributes<HTMLDivElement>) => {
	return <div className={classes.logo_subhead} {...props} />;
};

export const ContactBlurb = (props: React.HTMLAttributes<HTMLDivElement>) => {
	return <div className={classes.contact_blurb} {...props} />;
};

export const PageHeading = (props: React.HTMLAttributes<HTMLHeadingElement>) => {
	return <h1 className={classes.page_heading} {...props} />;
};

export const PageSubhead = (props: React.HTMLAttributes<HTMLHeadingElement>) => {
	return <h2 className={classes.page_subhead} {...props} />;
};

export const PageTopNavAuthorLink = (props: NavLinkProps & React.HTMLAttributes<HTMLAnchorElement>) => {
	return (
		<NavLink
			className={classes.page_top_nav_author_link}
			{...props}
		/>
	);
};

export interface TopNavLinkProps extends NavLinkProps {
	hovered?: boolean;
}

export const TopNavLink = (props: TopNavLinkProps & React.HTMLAttributes<HTMLAnchorElement>) => {
	const {
		hovered = false,
		...rest,
	} = props;

	const cx = classNames({
		[classes.top_nav_link]: true,
		[classes.top_nav_link_hovered]: hovered,
	});

	return (
		<NavLink
			className={cx}
			activeClassName={classes.top_nav_link_active}
			{...rest}
		/>
	);
};

export const PageTopNavLink = (props: NavLinkProps & React.HTMLAttributes<HTMLAnchorElement>) => {
	return (
		<NavLink
			className={classes.page_top_nav_link}
			activeClassName={classes.page_top_nav_link_active}
			{...props}
		/>
	);
};

export const AuthorCredit = (props: React.HTMLAttributes<HTMLDivElement>) => {
	return <div className={classes.author_credit} {...props} />;
};

export const AuthorEmail = (props: React.HTMLAttributes<HTMLDivElement>) => {
	return <div className={classes.author_email} {...props} />;
};
