import {defaultStateTemplate} from 'hannah/src/app/frontend/default_state_template';
import {IndexActionTypes} from 'hannah/src/app/frontend/index_actions';

interface ActionPayload {
	type: IndexActionTypes;
	data: any;
}

export function indexReducer<P>(previousState = defaultStateTemplate, action: ActionPayload) {
	const {type: actionType, data} = action;
  switch (actionType) {
  	case IndexActionTypes.SET_STORE_STATE: {
  		return data;
  	}
  	case IndexActionTypes.SET_ACTIVE_TOP_LINK_HREF: {
  		const {href} = data;
  		return {
  			activeTopLinkHref: href,
  		}
  	}
		default: {
			return previousState;
		}
	}
}
