import * as React from 'react';
import {NavLink} from 'react-router-dom';

import {
	PageConfigItem,
} from 'hannah/src/app/frontend/site_config_types';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';

interface PageNavFooterProps {
	prevPage?: PageConfigItem;
	nextPage?: PageConfigItem;
}

export const PageNavFooter = (props: PageNavFooterProps) => {
	const {
		prevPage,
		nextPage,
	} = props;

	return (
		<div className={mediaSelectorClasses.page_nav_footer}>
			<div>
				{
					prevPage && (
						<NavLink
							className={mediaSelectorClasses.page_nav_footer_link_previous}
							to={prevPage.href}>
							<div className={mediaSelectorClasses.page_nav_footer_link_previous_text}>
								Previous
							</div>
							<div className={mediaSelectorClasses.page_nav_footer_link_project_name}>
								{prevPage.text}
							</div>
						</NavLink>
					)
				}
			</div>
			<div>
				{
					nextPage && (
						<NavLink
							className={mediaSelectorClasses.page_nav_footer_link_next}
							to={nextPage.href}>
							<div className={mediaSelectorClasses.page_nav_footer_link_next_text}>
								Next
							</div>
							<div className={mediaSelectorClasses.page_nav_footer_link_project_name}>
								{nextPage.text}
							</div>
						</NavLink>
					)
				}
			</div>
		</div>
	);
};
