import classNames from 'classnames';
import * as React from 'react';

import {
	Paragraph,
	H3,
} from 'hannah/src/app/frontend/typography';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/project_intro_card/project_intro_card.css';

export interface ProjectIntroCardProps {
	descriptionHeading: string;
	descriptionParagraph: string;
	roleHeading: string;
	roleParagraph: string;
	timeframeHeading: string;
	timeframeParagraph: string;
}

const _ProjectIntroCard = (props: ProjectIntroCardProps) => {
	const {
		descriptionHeading,
		descriptionParagraph,
		roleHeading,
		roleParagraph,
		timeframeHeading,
		timeframeParagraph,
	} = props;

	const leftPaneCx = classNames(
		mediaSelectorClasses.project_intro_card_left_pane_spacing,
		classes.left_pane,
	);

	return (
		<div className={classes.outermost_container}>
			<div className={mediaSelectorClasses.project_intro_card_pane_container}>
				<div className={leftPaneCx}>
					<H3>{descriptionHeading}</H3>
					<Paragraph>{descriptionParagraph}</Paragraph>
				</div>
				<div className={classes.right_pane}>
					<div className={mediaSelectorClasses.project_intro_card_right_pane_top_section_spacing}>
						<H3>{roleHeading}</H3>
						<Paragraph>{roleParagraph}</Paragraph>
					</div>
					<div>
						<H3>{timeframeHeading}</H3>
						<Paragraph>{timeframeParagraph}</Paragraph>
					</div>
				</div>
			</div>
		</div>
	);
};

export const ProjectIntroCard = React.memo(_ProjectIntroCard);
