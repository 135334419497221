import * as React from 'react';
import {spring, TransitionMotion} from 'react-motion';

export interface CrossfadingElementsProps {
  itemKey: React.Key;
  data: any;
  children: (config: any) => JSX.Element;
}

export class CrossfadingElements extends React.Component<CrossfadingElementsProps> {

  willEnter() {
    return {
      opacity: 0,
    };
  }

  willLeave() {
    return {
      opacity: spring(0),
    };
  }

	render() {
		const {
      itemKey,
      data,
      children,
		} = this.props;

    const styleInputObj = {
      key: itemKey,
      data,
    };

    return (
      <TransitionMotion
      	willEnter={this.willEnter}
        willLeave={this.willLeave}
        styles={[styleInputObj].map(({key, data}: any) => ({
          key: key,
          style: {
          	opacity: spring(1),
          },
          data: data,
        }))}>
        {(interpolatedStyles) =>
          <React.Fragment>
            {interpolatedStyles.map((config) => {
              return children(config);
            })}
          </React.Fragment>
        }
      </TransitionMotion>
    );
	}
}
