import {ColoredDotLegendProps} from 'hannah/src/app/frontend/components/layout/colored_dot_legend/colored_dot_legend';
import {OrderedListCardsProps} from 'hannah/src/app/frontend/components/layout/ordered_list_cards/ordered_list_cards';
import {PageCalloutsImageProps} from 'hannah/src/app/frontend/components/layout/page_callouts_image/page_callouts_image';
import {PageCarouselProps} from 'hannah/src/app/frontend/components/layout/page_carousel/page_carousel';
import {PageCompareImageProps} from 'hannah/src/app/frontend/components/layout/page_compare_image/page_compare_image';
import {PageHeaderProps} from 'hannah/src/app/frontend/components/layout/page_header/page_header';
import {PageImageProps} from 'hannah/src/app/frontend/components/layout/page_image/page_image';
import {ProjectIntroCardProps} from 'hannah/src/app/frontend/components/layout/project_intro_card/project_intro_card';
import {LightboxThumbnailsProps} from 'hannah/src/app/frontend/components/lightbox/lightbox_thumbnails';
import {ThreePaneImageProps} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';
import {TwoPaneImageProps} from 'hannah/src/app/frontend/components/layout/two_pane_image/two_pane_image';
import {PageVideoProps} from 'hannah/src/app/frontend/components/layout/page_video/page_video';
import {ProjectCTACardProps} from 'hannah/src/app/frontend/components/layout/project_cta_card/project_cta_card';
import {PageLinkProps} from 'hannah/src/app/frontend/components/layout/page_link/page_link';
import {EmailLinkProps} from 'hannah/src/app/frontend/components/layout/email_link/email_link';
import {PageCrossfadingImagesProps} from 'hannah/src/app/frontend/components/layout/page_crossfading_images/page_crossfading_images';
import {DownloadButtonProps} from 'hannah/src/app/frontend/components/layout/download_button/download_button';

export enum Components {
	BIG_QUOTE,
	BOLD,
	CALLOUTS_IMAGE,
	CAROUSEL,
	CROSSFADING_IMAGES,
	COLORED_DOT_LEGEND,
	COMPARE_IMAGE,
	DOWNLOAD_BUTTON,
	EMAIL_LINK,
	FIGURE_GROUP,
	NON_STACKING_FIGURE_GROUP,
	FIGURE_SHOWCASE,
	FULL_WIDTH_FIGURE_GROUP,
	NON_STACKING_FULL_WIDTH_FIGURE_GROUP,
	FULL_WIDTH_IMAGE,
	FULL_WIDTH_VIDEO,
	H1,
	H2,
	H3,
	IMAGE,
	LIGHTBOX_THUMBNAILS,
	LINK,
	LONG_QUOTE,
	ORDERED_LIST_CARDS,
	OVERLINE,
	PAGE_HEADER,
	PARAGRAPH,
	PROJECT_CTA_CARD,
	PROJECT_INTRO_CARD,
	THREE_PANE_IMAGE,
	TWO_PANE_IMAGE,
	VIDEO,
}

export enum Layout {
	PROJECT_PAGE_HEADER,
	PROJECT_PAGE_SECTION,
	LOADED_PROJECT_PAGE_SECTION,
}

/* Component Defs */

interface BIG_QUOTE_DEF {
	type: Components.BIG_QUOTE;
	props: {
		children: string;
	};
}

interface CALLOUTS_IMAGE_DEF {
	type: Components.CALLOUTS_IMAGE;
	props: PageCalloutsImageProps;
}

interface CAROUSEL_DEF {
	type: Components.CAROUSEL;
	props: PageCarouselProps;
}

interface CROSSFADING_IMAGES_DEF {
	type: Components.CROSSFADING_IMAGES;
	props: PageCrossfadingImagesProps;
}

interface COLORED_DOT_LEGEND_DEF {
	type: Components.COLORED_DOT_LEGEND;
	props: ColoredDotLegendProps;
}

interface COMPARE_IMAGE_DEF {
	type: Components.COMPARE_IMAGE;
	props: PageCompareImageProps;
}

interface DOWNLOAD_BUTTON_DEF {
	type: Components.DOWNLOAD_BUTTON;
	props: DownloadButtonProps;
}

interface EMAIL_LINK_DEF {
	type: Components.EMAIL_LINK;
	props: EmailLinkProps;
}

interface FIGURE_SHOWCASE_DEF {
	type: Components.FIGURE_SHOWCASE;
	props: {
		children: FigureShowcaseChild[];
	};
}

interface FULL_WIDTH_FIGURE_GROUP_DEF {
	type: Components.FULL_WIDTH_FIGURE_GROUP;
	props: {
		children: FigureGroupChild[];
	};
}

interface NON_STACKING_FULL_WIDTH_FIGURE_GROUP_DEF {
	type: Components.NON_STACKING_FULL_WIDTH_FIGURE_GROUP;
	props: {
		children: FigureGroupChild[];
	};
}

interface FULL_WIDTH_IMAGE_DEF {
	type: Components.FULL_WIDTH_IMAGE;
	props: PageImageProps;
}

interface FULL_WIDTH_VIDEO_DEF {
	type: Components.FULL_WIDTH_VIDEO;
	props: PageVideoProps;
}

interface H1_DEF {
	type: Components.H1;
	props: {
		children: string;
	};
}

interface H2_DEF {
	type: Components.H2;
	props: {
		children: string;
	};
}

interface H3_DEF {
	type: Components.H3;
	props: {
		children: string;
	};
}

interface IMAGE_DEF {
	type: Components.IMAGE;
	props: PageImageProps;
}

interface FIGURE_GROUP_DEF {
	type: Components.FIGURE_GROUP;
	props: {
		children: FigureGroupChild[];
	};
}

interface NON_STACKING_FIGURE_GROUP_DEF {
	type: Components.NON_STACKING_FIGURE_GROUP;
	props: {
		children: FigureGroupChild[];
	};
}


interface LIGHTBOX_THUMBNAILS_DEF {
	type: Components.LIGHTBOX_THUMBNAILS;
	props: LightboxThumbnailsProps;
}

interface LINK_DEF {
	type: Components.LINK;
	props: PageLinkProps;
}

interface LONG_QUOTE_DEF {
	type: Components.LONG_QUOTE;
	props: {
		children: string;
	};
}

interface ORDERED_LIST_CARDS_DEF {
	type: Components.ORDERED_LIST_CARDS;
	props: OrderedListCardsProps;
}

interface OVERLINE_DEF {
	type: Components.OVERLINE;
	props: {
		children: string;
	};
}

interface PARAGRAPH_DEF {
	type: Components.PARAGRAPH;
	props: {
		children: ParagraphChild[];
	};
}

interface PROJECT_INTRO_CARD_DEF {
	type: Components.PROJECT_INTRO_CARD;
	props: ProjectIntroCardProps;
}

interface PROJECT_CTA_CARD_DEF {
	type: Components.PROJECT_CTA_CARD;
	props: ProjectCTACardProps;
}

interface THREE_PANE_IMAGE_DEF {
	type: Components.THREE_PANE_IMAGE;
	props: ThreePaneImageProps;
}

interface TWO_PANE_IMAGE_DEF {
	type: Components.TWO_PANE_IMAGE;
	props: TwoPaneImageProps;
}

interface VIDEO_DEF {
	type: Components.VIDEO;
	props: PageVideoProps;
}

/* Component children */
type ParagraphChild = string | LINK_DEF | EMAIL_LINK_DEF;
type FigureShowcaseChild = H3_DEF | IMAGE_DEF | FIGURE_GROUP_DEF | FULL_WIDTH_FIGURE_GROUP_DEF;
type FigureGroupChild = IMAGE_DEF | VIDEO_DEF | CALLOUTS_IMAGE_DEF;

/* Layout Defs */

interface PROJECT_PAGE_HEADER_DEF {
	type: Layout.PROJECT_PAGE_HEADER;
	props: PageHeaderProps;
}

interface PROJECT_PAGE_SECTION_DEF {
	type: Layout.PROJECT_PAGE_SECTION;
	configFn: () => ComponentConfig[];
}

export interface LOADED_PROJECT_PAGE_SECTION_DEF {
	type: Layout.LOADED_PROJECT_PAGE_SECTION;
	components: ComponentConfig[];
}

export type ComponentConfig = (
	BIG_QUOTE_DEF |
	CALLOUTS_IMAGE_DEF |
	CAROUSEL_DEF |
	COLORED_DOT_LEGEND_DEF |
	COMPARE_IMAGE_DEF |
	CROSSFADING_IMAGES_DEF |
	DOWNLOAD_BUTTON_DEF |
	EMAIL_LINK_DEF |
	FIGURE_GROUP_DEF |
	NON_STACKING_FIGURE_GROUP_DEF |
	FIGURE_SHOWCASE_DEF |
	FULL_WIDTH_FIGURE_GROUP_DEF |
	NON_STACKING_FULL_WIDTH_FIGURE_GROUP_DEF |
	FULL_WIDTH_IMAGE_DEF |
	FULL_WIDTH_VIDEO_DEF |
	H1_DEF |
	H2_DEF |
	H3_DEF |
	IMAGE_DEF |
	LIGHTBOX_THUMBNAILS_DEF |
	LINK_DEF |
	LONG_QUOTE_DEF |
	ORDERED_LIST_CARDS_DEF |
	OVERLINE_DEF |
	PARAGRAPH_DEF |
	PROJECT_CTA_CARD_DEF |
	PROJECT_INTRO_CARD_DEF |
	THREE_PANE_IMAGE_DEF |
	TWO_PANE_IMAGE_DEF |
	VIDEO_DEF
);

export type PageItem = (
	PROJECT_PAGE_HEADER_DEF |
	PROJECT_PAGE_SECTION_DEF |
	LOADED_PROJECT_PAGE_SECTION_DEF
);
export type Page = PageItem[];

/* Layout */

export function page(...args: Page) {
	return args;
}

export function projectPageHeader(props: PageHeaderProps) {
	const obj: PROJECT_PAGE_HEADER_DEF = {
		type: Layout.PROJECT_PAGE_HEADER,
		props: props,
	};
	return obj;
}

export function section(configFn: () => ComponentConfig[]) {
	const obj: PROJECT_PAGE_SECTION_DEF = {
		type: Layout.PROJECT_PAGE_SECTION,
		configFn: configFn,
	};
	return obj;
}

export function bigQuote(textContent: string) {
	const obj: BIG_QUOTE_DEF = {
		type: Components.BIG_QUOTE,
		props: {
			children: textContent,
		},
	};
	return obj;
}

export function calloutsImage(props: PageCalloutsImageProps) {
	const obj: CALLOUTS_IMAGE_DEF = {
		type: Components.CALLOUTS_IMAGE,
		props: props,
	};
	return obj;
}

export function carousel(props: PageCarouselProps) {
	const obj: CAROUSEL_DEF = {
		type: Components.CAROUSEL,
		props: props,
	};
	return obj;
}

export function crossfadingImages(props: PageCrossfadingImagesProps) {
	const obj: CROSSFADING_IMAGES_DEF = {
		type: Components.CROSSFADING_IMAGES,
		props: props,
	};
	return obj;
}

export function coloredDotLegend(props: ColoredDotLegendProps) {
	const obj: COLORED_DOT_LEGEND_DEF = {
		type: Components.COLORED_DOT_LEGEND,
		props: props,
	};
	return obj;
}

export function compareImage(props: PageCompareImageProps) {
	const obj: COMPARE_IMAGE_DEF = {
		type: Components.COMPARE_IMAGE,
		props: props,
	};
	return obj;
}

export function downloadButton(props: DownloadButtonProps) {
	const obj: DOWNLOAD_BUTTON_DEF = {
		type: Components.DOWNLOAD_BUTTON,
		props: props,
	};
	return obj;
}

export function figureShowcase(...args: FigureShowcaseChild[]) {
	const obj: FIGURE_SHOWCASE_DEF = {
		type: Components.FIGURE_SHOWCASE,
		props: {
			children: args,
		},
	};
	return obj;
}

export function fullWidthFigureGroup(...args: FigureGroupChild[]) {
	const obj: FULL_WIDTH_FIGURE_GROUP_DEF = {
		type: Components.FULL_WIDTH_FIGURE_GROUP,
		props: {
			children: args,
		},
	};
	return obj;
}

export function nonStackingFullWidthFigureGroup(...args: FigureGroupChild[]) {
	const obj: NON_STACKING_FULL_WIDTH_FIGURE_GROUP_DEF = {
		type: Components.NON_STACKING_FULL_WIDTH_FIGURE_GROUP,
		props: {
			children: args,
		},
	};
	return obj;
}

export function fullWidthImage(props: PageImageProps) {
	const obj: FULL_WIDTH_IMAGE_DEF = {
		type: Components.FULL_WIDTH_IMAGE,
		props: props,
	};
	return obj;
}

export function fullWidthVideo(props: PageVideoProps) {
	const obj: FULL_WIDTH_VIDEO_DEF = {
		type: Components.FULL_WIDTH_VIDEO,
		props: props,
	};
	return obj;
}

export function emailLink(address: string) {
	const obj: EMAIL_LINK_DEF = {
		type: Components.EMAIL_LINK,
		props: {
			children: address,
		},
	};
	return obj;
}

export function h1(textContent: string) {
	const obj: H1_DEF = {
		type: Components.H1,
		props: {
			children: textContent,
		},
	};
	return obj;
}

export function h2(textContent: string) {
	const obj: H2_DEF = {
		type: Components.H2,
		props: {
			children: textContent,
		},
	};
	return obj;
}

export function h3(textContent: string) {
	const obj: H3_DEF = {
		type: Components.H3,
		props: {
			children: textContent,
		},
	};
	return obj;
}

export function image(props: PageImageProps) {
	const obj: IMAGE_DEF = {
		type: Components.IMAGE,
		props: props,
	};
	return obj;
}

export function figureGroup(...args: FigureGroupChild[]) {
	const obj: FIGURE_GROUP_DEF = {
		type: Components.FIGURE_GROUP,
		props: {
			children: args,
		},
	};
	return obj;
}

export function nonStackingFigureGroup(...args: FigureGroupChild[]) {
	const obj: NON_STACKING_FIGURE_GROUP_DEF = {
		type: Components.NON_STACKING_FIGURE_GROUP,
		props: {
			children: args,
		},
	};
	return obj;
}

export function lightboxThumbnails(props: LightboxThumbnailsProps) {
	const obj: LIGHTBOX_THUMBNAILS_DEF = {
		type: Components.LIGHTBOX_THUMBNAILS,
		props: props,
	};
	return obj;
}

export function link(props: PageLinkProps) {
	const obj: LINK_DEF = {
		type: Components.LINK,
		props: props,
	};
	return obj;
}

export function longQuote(textContent: string) {
	const obj: LONG_QUOTE_DEF = {
		type: Components.LONG_QUOTE,
		props: {
			children: textContent,
		},
	};
	return obj;
}

export function orderedListCards(props: OrderedListCardsProps) {
	const obj: ORDERED_LIST_CARDS_DEF = {
		type: Components.ORDERED_LIST_CARDS,
		props: props,
	};
	return obj;
}

export function overline(children: string) {
	const obj: OVERLINE_DEF = {
		type: Components.OVERLINE, 
		props: {
			children: children,
		},
	};
	return obj;
}

export function paragraph(...args: ParagraphChild[]) {
	const obj: PARAGRAPH_DEF = {
		type: Components.PARAGRAPH,
		props: {
			children: args,
		},
	};
	return obj;
}

export function projectIntroCard(props: ProjectIntroCardProps) {
	const obj: PROJECT_INTRO_CARD_DEF = {
		type: Components.PROJECT_INTRO_CARD,
		props: props,
	};
	return obj;
}

export function projectCTACard(props: ProjectCTACardProps) {
	const obj: PROJECT_CTA_CARD_DEF = {
		type: Components.PROJECT_CTA_CARD,
		props: props,
	};
	return obj;
}

export function threePaneImage(props: ThreePaneImageProps) {
	const obj: THREE_PANE_IMAGE_DEF = {
		type: Components.THREE_PANE_IMAGE,
		props: props,
	};
	return obj;
}

export function twoPaneImage(props: TwoPaneImageProps) {
	const obj: TWO_PANE_IMAGE_DEF = {
		type: Components.TWO_PANE_IMAGE,
		props: props,
	};
	return obj;
}

export function video(props: PageVideoProps) {
	const obj: VIDEO_DEF = {
		type: Components.VIDEO,
		props: props,
	};
	return obj;
}
