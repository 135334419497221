import * as React from 'react';

import {
	ColoredDotLegendText,
} from 'hannah/src/app/frontend/typography';

import * as classes from 'hannah/src/app/frontend/components/layout/colored_dot_legend/colored_dot_legend.css';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';

export interface ColoredDotLegendItemProps {
	color: string;
	text: string;
}

export interface ColoredDotLegendProps {
	items: ColoredDotLegendItemProps[];
}

const _ColoredDotLegend = (props: ColoredDotLegendProps) => {
	const {items} = props;
	return (
		<ul className={mediaSelectorClasses.colored_dot_legend_container}>
		{
			items.map(({color, text}: ColoredDotLegendItemProps, index: number) => {
				return (
					<li key={index} className={classes.item}>
						<span style={{backgroundColor: color}} className={mediaSelectorClasses.colored_dot_legend_dot} />
						<ColoredDotLegendText>
							{text}
						</ColoredDotLegendText>
					</li>
				);
			})
		}
		</ul>
	);
};

export const ColoredDotLegend = React.memo(_ColoredDotLegend);
