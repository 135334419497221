import * as React from 'react';
import {connect} from 'react-redux';

import {HannahAppReduxState} from 'hannah/src/app/frontend/default_state_template';

const HomePageView = () => {
	return (
		<div style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, zIndex: -1}}>
		</div>
	);
}

const mapStateToProps = (state: HannahAppReduxState) => {
  return {
  };
};

export const HomePage = connect(mapStateToProps)(HomePageView);
