import * as React from 'react';

import {
  AuthorCredit,
  AuthorEmail,
} from 'hannah/src/app/frontend/typography';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';

export interface AuthorNoteFooterProps {
	credit: string;
	email: string;
}

export const _AuthorNoteFooter = (props: AuthorNoteFooterProps) => {
	const {
		credit,
		email,
	} = props;

	return (
		<div className={mediaSelectorClasses.author_note_footer}>
			<AuthorCredit>
				{credit}
			</AuthorCredit>
			<AuthorEmail>
				{email}
			</AuthorEmail>
		</div>
	);
};

export const AuthorNoteFooter = React.memo(_AuthorNoteFooter);
