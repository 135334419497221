import {S3_ASSET_BUCKET_URL} from 'hannah/src/app/frontend/constants';
import hashedAssetsIndex from 'hannah/src/config/hashed_assets_index';

export const collectedUrls: string[] = [];

const VIDEO_FILE_EXTENSIONS = [
  'mov',
  'mp4',
];

export async function loadCollectedAssetUrls() {
  collectedUrls.map((url: string) => {
    return new Promise((resolve) => {
      const ext = url.split('.').pop();
      if (VIDEO_FILE_EXTENSIONS.indexOf(ext!) !== -1) {
        // TODO - fix video preloading
        // ideally we would preload the videos before rendering each section
        // but Amazon has some type of intermittent cors issue

        // const req = new XMLHttpRequest();
        // req.open('GET', url, true);
        // req.responseType = 'blob';
        // req.onload = resolve;
        // //req.onerror = resolve;

        // req.onerror = () => {
        //   alert(`video failed to load: ${url}`);
        // };
        // req.send();
        resolve();
      } else {
        const img = new Image(); // preload the image as a side effect
        img.src = url;
        img.onload = resolve;
        img.onerror = resolve;
      }
    });
  });

  return Promise.all(collectedUrls).then(() => {
    collectedUrls.length = 0;
  });
}

export function assetUrl(assetPath: keyof typeof hashedAssetsIndex) {
	const hash = hashedAssetsIndex[assetPath];
	const parts = assetPath.split('.');
	const ext = parts.pop();
	const assetPathNoExt = parts.join('.');
	const s3Url = `${S3_ASSET_BUCKET_URL}/${assetPathNoExt}-${hash}.${ext}`;
	// TODO(taylorm) this should really be an env block I think
	// that will be stripped out in the server build
	if ((window as any).Image) {
    collectedUrls.push(s3Url);
	}
	return s3Url;
}
