import * as React from 'react';
import classNames from 'classnames';

import {
	Carousel,
	CarouselProps,
} from 'hannah/src/app/frontend/components/carousel/carousel';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/page_carousel/page_carousel.css';

export interface PageCarouselProps extends CarouselProps {
	marginTop?: boolean;
	marginBottom?: boolean;
	aspectRatioWidth: number;
	aspectRatioHeight: number;
}

const _PageCarousel = (props: PageCarouselProps) => {
	const {
		items,
		marginTop,
		marginBottom,
		aspectRatioWidth,
		aspectRatioHeight,
	} = props;

	const cx = classNames({
		[classes.container]: true,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
	});

	return (
		<div className={cx}>
			<Carousel
				items={items}
				aspectRatioWidth={aspectRatioWidth}
				aspectRatioHeight={aspectRatioHeight}
			/>
		</div>
	);
};

export const PageCarousel = React.memo(_PageCarousel);
