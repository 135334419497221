import * as React from 'react';
import ReactCompareImage from 'react-compare-image';

import * as classes from 'hannah/src/app/frontend/components/compare_image/compare_image.css';

export interface CompareImageProps {
	leftImageUrl: string;
	rightImageUrl: string;
	leftImageAlt: string;
	rightImageAlt: string;
  sliderLineColor?: string;
  sliderLineWidth?: number;
	handle?: JSX.Element;
}

/* TODO(taylorm) I started to convert this to a class so I could store the
   instance so I can manipulate the internal state to make the slider snap
   but this is proving to not work.
*/
export class CompareImage extends React.Component<CompareImageProps> {

	render() {
		const {
			leftImageUrl,
			rightImageUrl,
			leftImageAlt,
			rightImageAlt,
			sliderLineColor,
			sliderLineWidth,
			handle,
		} = this.props;

		return (
			<div className={classes.container}>
				<ReactCompareImage
					leftImage={leftImageUrl}
					rightImage={rightImageUrl}
					leftImageAlt={leftImageAlt}
					rightImageAlt={rightImageAlt}
					sliderLineColor={sliderLineColor}
					sliderLineWidth={sliderLineWidth}
					handle={handle}
				/>
			</div>
		);
	}
};
