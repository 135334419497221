import {HannahAppReduxState} from 'hannah/src/app/frontend/default_state_template';

export enum IndexActionTypes {
	SET_STORE_STATE,
	SET_ACTIVE_TOP_LINK_HREF,
}

export function setStoreStateAction(storeState: HannahAppReduxState) {
	return {
		type: IndexActionTypes.SET_STORE_STATE,
		data: storeState,
	};
}

export function setActiveTopLinkHrefAction(href: string) {
	return {
		type: IndexActionTypes.SET_ACTIVE_TOP_LINK_HREF,
		data: {
			href,
		},
	};
}
