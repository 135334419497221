import {assetUrl} from 'hannah/src/app/frontend/static_utils';
import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';
//import {ThreePaneImageCaptionStyles} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';

import {
	//threePaneImage,
	//video,
	bigQuote,
	calloutsImage,
	carousel,
	coloredDotLegend,
	compareImage,
	figureShowcase,
	fullWidthFigureGroup,
	fullWidthImage,
	fullWidthVideo,
	h1,
	h2,
	h3,
	image,
	lightboxThumbnails,
	link,
	orderedListCards,
	overline,
	page,
	paragraph,
	projectIntroCard,
	projectPageHeader,
	section,
	video,
} from 'hannah/src/app/frontend/page_config';

const BROWSER_CHROME_PADDING = `5.8% .5% 2.1%`;

export default page(
	projectPageHeader({
		heading: `Global filtering`,
		subhead: `Transforming the cluttered navigation into an organized system.`,
	}),
	section(() => [
		overline(`Intro`),
		projectIntroCard({
			descriptionHeading: `The challenge`,
			descriptionParagraph: `
				Design a filter and configure flow that works on all global pages and allows for customization and page grouping.
			`,
			roleHeading: `My role`,
			roleParagraph: `
				Sole designer on this massive project leading all user research, product strategy, UX, and visual design. 
			`,
			timeframeHeading: `Timeframe`,
			timeframeParagraph: `April 2018 - July 2018`,
		}),
		paragraph(
			`
				TLDR; Our original configure and filter system was just a catch-all place to put all page filtering and configuring settings. 
				We would add to it whenever a new feature was needed, and every page had a different system. 
				It was painful to watch users try and navigate the flows. 
				I knew that we needed to introduce data visualization, which would connect to our filtering UX, and the current design would be a nightmare to integrate with. 
				This wasn’t originally a planned project, but I took the initiative to take it on. 
				The end result was greatly improved and worked seamlessly with the new data visualization project.
			`,
		),
	]),
	section(() => [
		overline(`Discover`),
		h1(`Problem`),
		paragraph(`
			There were so many problems with the original design. 
			Every page was set up differently (even though it was doing the same thing), it was not responsive, and there were so many hidden buttons and non-intuitive UX flows.
		`),
		carousel({
			aspectRatioWidth: 717,
			aspectRatioHeight: 416,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/global-filtering/2-problem/problems-c.gif`),
					captionHeader: 'User struggle video',
					caption: `This user session shows the common frustrations when using the dropdown.`,
				},				
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/global-filtering/2-problem/carousel/ia-problems.png`),
					captionHeader: 'IA problems',
					caption: `There wasn’t a consistent dropdown layout for each global page.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/global-filtering/2-problem/carousel/icon-problems.png`),
					captionHeader: 'Icon inconsistency',
					caption: `Every page had slightly different icons and buttons which made everything even more confusing.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/2-problem/carousel/filter-problems.png`),
					captionHeader: 'Filter flyout annoyance',
					caption: `Clicking on a row would reveal a side flyout that would disappear if the user accidentally scrolled the page.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/2-problem/carousel/configure-problems.png`),
					captionHeader: 'Confusing configure placement',
					caption: `Configure was mixed in with filtering which was no intuitive.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/2-problem/carousel/scrolling-problems.png`),
					captionHeader: 'Scrolling problems',
					caption: `The dropdown was a fixed height which meant you always needed to click and un-click the sections to reveal information.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/2-problem/carousel/search-problems.png`),
					captionHeader: 'Search problems',
					caption: `There wasn’t a global search bar which meant you had to search by individual tabs.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/global-filtering/2-problem/carousel/non-responsive-nav.png`),
					captionHeader: 'Non-responsive navigation',
					caption: `Text and buttons would overlap on small screens.`,
				},
			]
		}),
		h2(`Problem areas`),
		orderedListCards({
			items: [
				{
					topic: `1. Navigation:`,
					details: `
						Every global page had a different configure and filter setup even though it did the same action. 
						Also, the navigation bars were the only non-responsive elements in the product.
					`
				},
				{
					topic: `2. UX:`,
					details: `
						The user experience was not intuitive and many key features were hidden under dropdowns or tabs. 
						Searching and scrolling to see more fields is a crucial step in filtering and both of these weren’t implemented.
					`
				},
				{
					topic: `3. Iconography:`,
					details: `
						Not only were the dropdowns not consistent, but the icons weren’t as well. 
						The button styles and icons needed to match across all pages for easier use.
					`
				},
			]
		}),
	]),
	section(() => [
		h1(`Research`),
		paragraph(`
			The first step to improving this system was to do extensive research. 
			Deal info, Deal tasks, Dashboard, Reporting, and Assignments were our highest viewed pages. 
			All of these pages use the configure and filter dropdown so we knew focusing on improving this would have a huge payoff.
		`),
		image({
			src: assetUrl(`images/global-filtering/3-research/page-views-noborder.png`),
			caption: `Top viewed product pages`,
		}),
		h2(`User research`),
		paragraph(`
			I knew what my problems were when trying to use the product, but every user is different. 
			I interviewed several customers as well as tracked sessions using FullStory. 
			There were the same common complaints in every interview; too cramped, inability to search, desire to filter on all fields, no quick way to reset filters.
		`),
		bigQuote(`\u201C Why are my deals not showing up?\u201D`),
		image({
			src: assetUrl(`images/global-filtering/3-research/fullstory-hotspots.png`),
			caption: `Heat map showing frequent clicks`,
		}),
	]),
	section(() => [
		h1(`Hypothesis`),
		paragraph(`
			If filter and configure are separated into two separate sidebars users will use the functionality more and the customer support team won’t have to set up the filters for the teams. 
			This will limit customer frustration and set up the product better for the data visualization project.
		`),
	]),
	section(() => [
		overline(`Define`),
		h1(`Project Goals`),
		paragraph(`
			There were a few key goals in mind when starting this project:`
		),
		orderedListCards({
			items: [
				{
					topic: `Goal 1: `,
					details: `Reduce customer complaints by 25% by making it clear when a filter is set. `,
				},
				{
					topic: `Goal 2: `,
					details: `Implement one cohesive configure and filter UX that works on all global pages.`,
				},
				{
					topic: `Goal 3: `,
					details: `Allow users to filter on 100% of fields, not just the configured fields.`,
				},
				{
					topic: `Goal 4: `,
					details: `Separate configure and filter so that the data visualization project can start before the end of 2018.`,
				},
			],
		}),
		paragraph(`
			As the only designer at the time, I knew that I needed to figure out a roadmap to integrate this across all pages without disrupting the users. 
			This meant I needed to roll out the feature on the least used pages first and work our way up to the highest traffic pages.
		`),
	]),
	section(() => [
		overline(`Ideate`),
		h1(`Navigation`),
		h2(`IA exploration`),
		paragraph(`
			To start ideating on this project, I needed to understand where and what features were in each page’s dropdown. 
			As you can see from the images below, each dropdown had a different style and order.
		`),
		coloredDotLegend({
			items: [
				{
					color: `#c1d2f2`,
					text: `Filter`,
				},
				{
					color: `#fff4cd`,
					text: `Options`,
				},
				{
					color: `#c9e3e8`,
					text: `Group by`,
				},
				{
					color: `#bbc8d1`,
					text: `Configure + Filter`,
				},
				{
					color: `#e7f3e6`,
					text: `Configure`,
				},
			],
		}),
		carousel({
			aspectRatioWidth: 627,
			aspectRatioHeight: 364,
			items: [
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/old-carousel/dashboard-ia.png`),
					captionHeader: 'Dashboard IA',
					caption: `Filter, options, group by, and configure are all located in one dropdown.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/old-carousel/assignments-ia.png`),
					captionHeader: 'Reporting IA',
					caption: `The reporting dropdown combined all set with a separate, more robust options dropdown.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/old-carousel/reporting-ia.png`),
					captionHeader: 'Assignments IA',
					caption: `The filter and options were completely separated on the assignments page.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/old-carousel/tasks-ia.png`),
					captionHeader: 'Tasks IA',
					caption: `The task dropdown mimicked the same style as the assignments IA with only filtering and display option.`,
				},
			]
		}),
		paragraph(`
			This was a beneficial exercise because it finally broke down where each setting was located. 
			It confirmed my belief that the dropdowns were confusing and different across all pages. 
			In addition to the dropdowns, I wanted to gather all navigation bars to figure out the disparities across all pages.
		`),	
		figureShowcase(
			h3(`Inconsistent icons`),
			image({
				src: assetUrl(`images/global-filtering/4-navigation/old-navigation.png`),
				caption: `Navigation bar disparaties`,
			}),
			h3(`Non-responsive nav bar`),	
			image({
				src: assetUrl(`images/global-filtering/4-navigation/old-filter-nav.png`),
				caption: `Old navigation bar`,
			}),	
			image({
				src: assetUrl(`images/global-filtering/4-navigation/old-filter-nav-responsive.png`),
				caption: `Old responsive issues`,
			}),
		),
		paragraph(`
			After gathering all the navigation bars and dropdowns from all pages, it was clear that the main areas to focus on were:
		`),
		orderedListCards({
			items: [
				{
					topic: `Unified dropdowns: `,
					details: `
						All dropdowns need to have the same IA.
				`
				},
				{
					topic: `Consistency: `,
					details: `
						Each navigation bar should be ordered the same way with the same icons.
					`
				},
				{
					topic: `Responsive: `,
					details: `
						The dropdown and navigation bars should be responsive and usable for all screen sizes.
					`
				},
			]
		}),
		h2(`New IA`),
		paragraph(`
			To declutter the single dropdown, the best solution was to break the dropdown into four components: configure, filter, group by, and settings.
		`),				
		carousel({
			aspectRatioWidth: 485,
			aspectRatioHeight: 368,
			items: [
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/new-carousel/new-dashboard-ia.png`),
					captionHeader: 'Updated dashboard IA',
					caption: `Configure, filter, group by, and settings are now separated into clean, organized components.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/new-carousel/new-assignments-ia.png`),
					captionHeader: 'Updated reporting IA',
					caption: `Similar to the dashboard, the reporting page has the same four components.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/new-carousel/new-reporting-ia.png`),
					captionHeader: 'Updated assignments IA',
					caption: `Assignments didn’t use to allow configuration, so now it matches all the other global pages.`,
				},
				{
					type: CarouselItemType.IMAGE,
					url: assetUrl(`images/global-filtering/4-navigation/new-carousel/new-tasks-ia.png`),
					captionHeader: 'Updated tasks IA',
					caption: `The task pages don’t need to configure or group by so the only two components required are filter and settings.`,
				},
			]
		}),
		paragraph(`
			Now all of the global pages use the same components, which not only makes it easier for the user but engineering as well. 
			Next, we needed to tackle the navigation bar icons and buttons.
		`),	
		figureShowcase(
			h3(`Consistent icons`),	
			image({
				src: assetUrl(`images/global-filtering/4-navigation/new-navigation.png`),
				caption: `Updated navigation bars`,
			}),
			h3(`Responsive nav bar`),	
			image({
				src: assetUrl(`images/global-filtering/4-navigation/new-filter-nav.png`),
				caption: `New navigation bar`,
			}),	
			image({
				src: assetUrl(`images/global-filtering/4-navigation/new-filter-nav-responsive.png`),
				caption: `Responsive navigation bar`,
			}),
		),
		paragraph(`
			The improved navigation now accommodates all screen sizes, and all icons and buttons are identical across all pages.
		`),
	]),
	section(() => [
		h1(`User Experience`),
		h2(`The pain points`),
		paragraph(`
			To reach the final design of the separate components, I needed to see how customers were currently using the product. 
			We implemented FullStory as a way to track customer sessions to get the raw examples of issues they were having.
		`),
		carousel({
			aspectRatioWidth: 734,
			aspectRatioHeight: 376,
			items: [
				{
					type: CarouselItemType.VIDEO,
					url: assetUrl(`images/global-filtering/5-user-experience/carousel/problems-search-c.mp4`),
					captionHeader: 'Inability to search',
					caption: `There was a way to search for deals on top, but no way to search for fields to filter.`,
				},
				{
					type: CarouselItemType.VIDEO,
					url: assetUrl(`images/global-filtering/5-user-experience/carousel/problems-scrolling-c.mp4`),
					captionHeader: 'Limited scrolling window',
					caption: `Each section in the original dropdown has a separate scroll. This means on small screens you can only see one item at a time.`,
				},
				{
					type: CarouselItemType.VIDEO,
					url: assetUrl(`images/global-filtering/5-user-experience/carousel/problems-filter-reset-c.mp4`),
					captionHeader: 'Hidden filters',
					caption: `Filtering was hidden under configure, so it was sometimes impossible to see what was being filtered out.`,
				},
			]
		}),
		paragraph(`
			After watching 100+ user sessions in FullStory, it was clear that the main areas to focus on were:
		`),
		orderedListCards({
			items: [
				{
					topic: `Scrolling: `,
					details: `
						Implement a sidebar instead of a dropdown that has full scrolling capabilities.
				`
				},
				{
					topic: `Filter set/reset: `,
					details: `
						Make it super clear when a filter is set and have a way to reset filters in the navigation bar.
					`
				},
				{
					topic: `Configure search: `,
					details: `
						Allow global searching so that all fields are searched at once.
					`
				},
			]
		}),
		paragraph(`
			Hover over the numbers below to learn more about the issues with the old design.
		`),	
		fullWidthFigureGroup(
			calloutsImage({
				src: assetUrl(`images/global-filtering/5-user-experience/old-panel-1.png`),
				caption: `Scrolling issues`,
				items: [
					{
						targetText: `1`,
						description: `
							The top search bar only filters by deals and not fields.
						`,
						position: {
							top: `8%`,
							left: `0%`,
						},
					},
					{
						targetText: `2`,
						description: `
							Each section had to be expanded or collapsed to view other sections.
						`,
						position: {
							right: `17%`,
							bottom: `82.5%`,
						},
					},
					{
						targetText: `3`,
						description: `
							It is unclear that you have to hover over the fields to set a filter.
						`,
						position: {
							top: `43.5%`,
							left: `0%`,
						},
					},
				],		
			}),
			calloutsImage({
				src: assetUrl(`images/global-filtering/5-user-experience/old-panel-2.png`),
				caption: `Filter/reset issues`,
				items: [
					{
						targetText: `1`,
						description: `
							The filter reset design was out of date and took up too much space.
						`,
						position: {
							right: `97%`,
							bottom: `93.5%`,
						},
					},
									{
						targetText: `2`,
						description: `
							The filter and reorder icons were tiny and easily missable.
						`,
						position: {
							top: `45.5%`,
							left: `68%`,
						},
					},
					{
						targetText: `3`,
						description: `
							Reset filters always shows on the bottom even if a filter isn’t set.
						`,
						position: {
							right: `60%`,
							bottom: `4%`,
						},
					},
				],					
			}),
			calloutsImage({
				src: assetUrl(`images/global-filtering/5-user-experience/old-panel-3.png`),
				caption: `Search issues`,
					items: [
					{
						targetText: `1`,
						description: `
							Fields were grouped into tabs making it hard to find them.
						`,
						position: {
							top: `46.5%`,
							left: `91%`,
						},
					},
					{
						targetText: `2`,
						description: `
							Search only applied to the tab you were in.
						`,
						position: {
							right: `1%`,
							bottom: `44%`,
						},
					},
				],
			}),
		),	
		h2(`Design iterations`),
		paragraph(`
			Below is a compilation of my design process for figuring out the best design solution for the dropdowns. 
			I started with sketching, then went to wireframes, interactions, and eventually engineering handoff.
		`),
		image({
			src: assetUrl(`images/global-filtering/5-user-experience/Process-animation-c.gif`),
			caption: `Design process`,
			dropshadow: true,
			maxWidth: 1100,
			grayBackground: true,
		}),	
		paragraph(`
			Although I was the only designer on this project, I frequently did whiteboarding sketches with the VP of product as well as held sessions with the customer team. 
			I got feedback from customers and was continually improving based on comments.
		`),
		h2(`Final design`),
		paragraph(`
			Hover over the numbers below to learn more about the new side panel design.
		`),
		fullWidthFigureGroup(
			calloutsImage({
				src: assetUrl(`images/global-filtering/5-user-experience/new-panel-1.png`),
				caption: `Improved filter visibility`,
				items: [
				{
					targetText: `1`,
					description: `
						Each filter is separated into a card that clearly shows the filter set.
					`,
					position: {
						top: `15%`,
						left: `8%`,
					},
				},
				{
					targetText: `2`,
					description: `
						Reset filters only shows on the bottom if a filter is set.
					`,
					position: {
						right: `90%`,
						bottom: `3%`,
						},
					},
				],
			}),
			calloutsImage({
				src: assetUrl(`images/global-filtering/5-user-experience/new-panel-2.png`),
				caption: `Improved filter/reset`,
				items: [
				{
					targetText: `1`,
					description: `
						When a filter is set, a notification badge shows how many are set.
					`,
					position: {
						right: `38%`,
						bottom: `94%`,
					},
				},
				{
					targetText: `2`,
					description: `
						The filter parameters are shown in blue, making it very clear to see what is being filtered.
					`,
					position: {
						top: `60%`,
						left: `7%`,
					},
				},
				{
					targetText: `3`,
					description: `
						To reset or remove a filter you can click on the top right part of the card.
					`,
					position: {
						right: `2%`,
						bottom: `40%`,
						},
					},
				],
			}),
			calloutsImage({
				src: assetUrl(`images/global-filtering/5-user-experience/new-panel-3.png`),
				caption: `Improved search`,
					items: [
				{
					targetText: `1`,
					description: `
						There is now a global search that looks through all fields.
					`,
					position: {
						top: `52%`,
						left: `90%`,
					},
				},
				{
					targetText: `2`,
					description: `
						The fields are separated into sections to make it easy to find what you are looking for.
					`,
					position: {
						right: `53%`,
						bottom: `26.5%`,
					},
				},
			],
		}),
		),					
		paragraph(`
			One of our project goals was to make it visible when a filter is set. 
			It is alarming as a user to not know why part of your page isn’t showing up so now we have a filter page that can be reset on hover.
		`),
		fullWidthImage({
			src: assetUrl(`images/global-filtering/5-user-experience/filter-matrix.png`),
			caption: `Old and new filter reset UI`,
			dropshadow: true,
			maxWidth: 915,
		}),
		paragraph(`
			Below is the finished product showing the separate sidebars and the fun flourishes we added to make setting filters and configuring your page an enjoyable experience.
		`),		
		fullWidthVideo({
			chromeSrc: assetUrl(`images/global-filtering/5-user-experience/final-mockup.png`),
			chromePadding: BROWSER_CHROME_PADDING,
			src: assetUrl(`images/global-filtering/5-user-experience/final-full-screen-recording-c.mp4`),
			caption: `Final configure and filter walkthrough`,
		}),	
		paragraph(`
			This improved UX has saved the customer success and engineering team countless hours. There are no more frustrated complaints coming through to the customer team, and the engineers are thrilled to have cleaner code with only a few components.
		`),
	]),				
	section(() => [
		h1(`Iconography`),
		h2(`Old vs. new`),
		paragraph(`
			The iconography needed some love. 
			Our icons were a mix of Font Awesome, homemade, and Google's Material Design. 
			This meant everything was a different weight and style and just looked messy.
		`),		
		compareImage({
			leftImageUrl: assetUrl(`images/global-filtering/6-iconography/old-slider.png`),
			rightImageUrl: assetUrl(`images/global-filtering/6-iconography/new-slider.png`),
			leftImageAlt: `A lake and hills.`,
			rightImageAlt: `A bear.`,
			caption: `Old vs. new filter dropdown`,
		}),
		paragraph(`
			The existing icons were redundant and all different colors and weights. 
			This not only looked bad, but is confusing for the user. 
			The original icons ranged from 24px to 48px and had different padding inside each container. 
			Engineers were continually asking about padding and what to do with icons inside of buttons.
		`),
	]),				
	section(() => [
		figureShowcase(
			fullWidthFigureGroup(
				image({
					src: assetUrl(`images/global-filtering/6-iconography/icons-before.png`),
					caption: `Old iconography`,
					dropshadow: true,
				}),
				image({
					src: assetUrl(`images/global-filtering/6-iconography/icons-after.png`),
					caption: `New iconography`,
					dropshadow: true,
				}),
			),
		),		
	]),				
	section(() => [
		paragraph(`
			I created an entirely new icon set to increase legibility at small sizes. 
			These icons went through several iterations to accurately convey meaning and maintain continuity with our existing voice. 
			This is the final polish that was needed to make the new navigation shine.
		`),
		h1(`User adoption`),
		paragraph(`
			Since this change was being applied to all major pages, we needed to roll it out in an organized way. 
			First, we implemented it on the assignments page because this was one of the less-visited pages. 
			I partnered with our customer success team and marketing team to make sure customers were aware of the changes and even provided this animation to show how to use it. 
			After a successful rollout, we launched on all major pages without a single complaint!
		`),	
		video({
			src: assetUrl(`images/global-filtering/7-user-adoption/ftue-video-c.mp4`),
			caption: `Marketing animation showing updated UX`,
			dropshadow: true,
		}),	
	]),				
	section(() => [
		overline(`Evaluate`),		
		h1(`Results`),
		paragraph(`
			This project was a lot to take on as the only designer, but it paid off. 
			All of the changes were received positively from users (and our team), and we received several complimentary emails from customers as well as kind notes from the customer success team. 
		`),					
		bigQuote(`\u201C This is a testament to how great you guys have been with understanding our needs. \u201D`),
		image({
			src: assetUrl(`images/global-filtering/8-results/metric-numbers.png`),
		}),		
	]),				
	section(() => [
		h1(`Next step`),
		paragraph(`
			This was the perfect setup for the data visualization project, which kicked off a month later. 
			To have data visualization on the deal dashboard and reporting page, we needed a way to group the page and set filters. 
			I designed the new system with this in mind, and everything meshed seamlessly together. 
		`),
		paragraph(
			`See the full data visualization case study `,
			link({
				text: `here!`,
				to: `/data-visualizations`,
			}),
		),
		fullWidthImage({
			src: assetUrl(`images/global-filtering/9-next-step/charts-vision-mock.png`),
			caption: `Upcoming data visualization project`,
			dropshadow: true,
			maxWidth: 1100,
		}),		
		paragraph(`
			At the end of this project, I remember sitting down with my VP of Product and he pointed out how much I had grown since I joined. 
			It was the first massive project initiated and led by me and helped me secure the Senior Product Designer title. 
			I was voted employee of the quarter and reflecting now this was a pivotal project in my career. 
			I’m grateful the team took a chance on this, and it was a huge success.
		`),						
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/global-filtering/10-gallery/dashboard-filter-flyout.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/dashboard-filter-hover.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/dashboard-configure-hover.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/dashboard-configure-search.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/charts.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/charts-filter.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/navigation.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/tasklist-filter.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/filter-set-flow.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/filter-add-flow.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/set-configure-flow.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/global-filtering/10-gallery/order-configure-flow.png`),
					caption: `blah blah.`,
				}							
			],
		}),
	]),
);
