import * as React from 'react';
import classNames from 'classnames';

import {
	Caption2,
} from 'hannah/src/app/frontend/typography';
import {
	CompareImage,
	CompareImageProps,
} from 'hannah/src/app/frontend/components/compare_image/compare_image';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/page_compare_image/page_compare_image.css';

export interface PageCompareImageProps extends CompareImageProps {
	caption?: string;
	marginTop?: boolean;
	marginBottom?: boolean;
}

const PageCompareImageHandle = () => {
	return (
		<React.Fragment>
			<div className={classes.handle} />
			<div className={classes.dropshadow_patch_container}>
			  <div className={classes.dropshadow_patch} />
			</div>
		</React.Fragment>
	);
};

const _PageCompareImage = (props: PageCompareImageProps) => {
	const {
		caption,
		leftImageUrl,
		rightImageUrl,
		leftImageAlt,
		rightImageAlt,
		marginBottom,
		marginTop,
	} = props;

	const cx = classNames({
		[classes.container]: true,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
	});

	return (
		<figure className={cx}>
			<div className={classes.compare_image_container}>
				<CompareImage
					leftImageUrl={leftImageUrl}
					rightImageUrl={rightImageUrl}
					leftImageAlt={leftImageAlt}
					rightImageAlt={rightImageAlt}
					sliderLineColor={'#000'}
	  			sliderLineWidth={1}
					handle={<PageCompareImageHandle />}
				/>
			</div>
			{
				caption && (
					<figcaption>
						<Caption2>{caption}</Caption2>
					</figcaption>
				)
			}
		</figure>
	);
};

export const PageCompareImage = React.memo(_PageCompareImage);
