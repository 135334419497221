import * as React from 'react';

import {
	ImageMeta,
	ImageTypes,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';

import {
	LightboxContext,
	addImageActionPayload,
	removeImageActionPayload,
	openLightboxActionPayload,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_context_manager';

import * as classes from 'hannah/src/app/frontend/components/lightbox/lightbox_thumbnails.css';

export interface LightboxThumbnailsProps {
	images: ImageMeta[];
}

export const LightboxThumbnail = (props: ImageMeta) => {
	const {url, caption} = props;
  	const {dispatch} = React.useContext(LightboxContext);

	React.useEffect(() => {
		if (dispatch) {
	  		dispatch(addImageActionPayload(url, ImageTypes.IMAGE, url, caption || ''));
		}
		return () => {
			if (dispatch) {
		  		dispatch(removeImageActionPayload(url));
			}
		}
	}, []);


	const handleClickImage = () => {
		if (dispatch) {
	  		dispatch(openLightboxActionPayload(url));
		}
	};

	return (
		<li
			className={classes.list_item}
			onClick={handleClickImage}>
			<div className={classes.aspect_ratio_sizing_parent}>
				<div className={classes.aspect_ratio_sizing_child}>
					<img
						src={url}
						alt={caption}
						className={classes.list_item_image}
					/>
				</div>
			</div>
		</li>
	);
}

export const LightboxThumbnails = (props: LightboxThumbnailsProps) => {
	const {images} = props;

	return (
		<ul className={classes.list}>
			{
				images.map((image: ImageMeta) => {
					return <LightboxThumbnail {...image} />;
				})
			}
		</ul>
	);
};
