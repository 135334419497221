import * as React from 'react';

import * as classes from 'hannah/src/app/frontend/components/layout/page_section/page_section.css';

const _PageSection = (props: React.HTMLAttributes<HTMLDivElement>) => {
	const {children, ...rest} = props;
	return (
		<section className={classes.container} {...rest}>
			{children}
		</section>
	);
};

export const PageSection = React.memo(_PageSection);
