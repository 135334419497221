import * as React from 'react';
import classNames from 'classnames';

import {
	PageTopNavAuthorLink,
	PageTopNavLink,
} from 'hannah/src/app/frontend/typography';

import * as classes from 'hannah/src/app/frontend/components/layout/page_top_navigation/page_top_navigation.css';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';

export interface PageTopNavigationLink {
	href: string;
	text: string;
	hideOnMobile?: boolean;
}

export interface PageTopNavigationState {
	scrollIsAtTopOfPage: boolean;
}

export interface PageTopNavigationProps {
	authorName: string;
	links: PageTopNavigationLink[];
}

export class PageTopNavigation extends React.PureComponent<PageTopNavigationProps, PageTopNavigationState> {

	state = {
		scrollIsAtTopOfPage: true,
	};

	handleScroll = () => {
		if (window.pageYOffset === 0) {
			this.setState({
				scrollIsAtTopOfPage: true,
			});
		} else {
			this.setState({
				scrollIsAtTopOfPage: false,
			});
		}
	}

	componentDidMount() {
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	render() {
		const {authorName, links} = this.props;
		const {scrollIsAtTopOfPage} = this.state;

		const cx = classNames({
			[mediaSelectorClasses.page_top_nav_container]: true,
			[mediaSelectorClasses.page_top_nav_container_scroll_at_top]: scrollIsAtTopOfPage,
		});

		return (
			<nav className={cx}>
				<PageTopNavAuthorLink to="/">{authorName}</PageTopNavAuthorLink>
				<ul className={classes.link_list}>
				{links.map(({text, href, hideOnMobile = false}: PageTopNavigationLink) => {
					const linkCx = classNames({
						[mediaSelectorClasses.page_top_nav_link_list_item]: true,
						[mediaSelectorClasses.page_top_nav_link_list_item_hidden_on_mobile]: hideOnMobile,
					});
					return (
						<li key={href} className={linkCx}>
							<PageTopNavLink to={href} exact>{text}</PageTopNavLink>
						</li>
					);
				})}
				</ul>
			</nav>
		);
	}
};
