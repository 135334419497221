// TODO(taylorm) break this out to a shared types file

export interface ImageMeta {
	url: string;
	caption: string;
}

// TODO(taylorm) rename all of this to "figure"
export enum ImageTypes {
	IMAGE,
	VIDEO,
}

export interface ImageItemMeta extends ImageMeta {
	id: string;
	type: ImageTypes;
}
