import * as React from 'react';

import * as classes from 'hannah/src/app/frontend/components/callouts_image/callouts_image.css';

import PopperJs from 'popper.js';

export interface CalloutContentProps {
	target: JSX.Element;
	popper: JSX.Element;
	arrow: JSX.Element;
}

interface CalloutContentState {
	displayPopper: boolean;
}

export interface CalloutsImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	items: CalloutContentProps[];
	maxWidth?: number;
}

export class CalloutContent extends React.Component<CalloutContentProps, CalloutContentState> {

	private targetElem: Element;
	private popperElem: Element;
	private arrowElem: Element;
	private popperInstance: PopperJs;
	private scrollListener: any;
	private touchStartListener: any;
	private mouseOnTarget: boolean;

	state = {
		displayPopper: false,
	};

	componentDidMount() {
		this.popperInstance = new PopperJs(
			this.targetElem,
			this.popperElem,
			{
    		placement: 'auto',
    		modifiers: {
    			arrow: {
    				element: this.arrowElem,
    			}
    		}
			},
		);

		let ticking = false;

		const resizeHandler = () => {
			if (!this.mouseOnTarget) {
				this.setState({
					displayPopper: false,
				}, () => {
					ticking = false;
				});
			} else {
				ticking = false;
			}
		};

		this.scrollListener = () => {
			if (!ticking) {
				ticking = true;
				requestAnimationFrame(resizeHandler);
			}
		};

		this.touchStartListener = (event: MouseEvent) => {
			// NOTE(taylorm) this should be managed by a React context
			if (event.target !== this.targetElem) {
				this.setState({
					displayPopper: false,
				});
			}
		};

		window.addEventListener('scroll', this.scrollListener);
		window.addEventListener('touchstart', this.touchStartListener);
	}

	componentDidUpdate() {
		this.popperInstance.scheduleUpdate();
	}

	componentWillUnmount() {
		this.popperInstance.destroy();
		window.removeEventListener('scroll', this.scrollListener);
		window.removeEventListener('touchstart', this.touchStartListener);
	}

	handleMouseEnterTarget = () => {
		this.mouseOnTarget = true;
		this.setState({
			displayPopper: true,
		});
	}

	handleMouseLeaveTarget = () => {
		this.mouseOnTarget = false;
		this.setState({
			displayPopper: false,
		});
	}

	render() {
		const {
			target,
			popper,
			arrow,
		} = this.props;
		const {
			displayPopper,
		} = this.state;

		return (
			<React.Fragment>
				{React.cloneElement(target, {
					onMouseEnter: this.handleMouseEnterTarget,
					onMouseLeave: this.handleMouseLeaveTarget,
					onTouchEnd: this.handleMouseEnterTarget,
					ref: (elem: Element) => {
						this.targetElem = elem;
					},
				})}
				{React.cloneElement(popper, {
					style: {
						display: displayPopper ? 'block': 'none',
					},
					ref: (elem: Element) => {
						this.popperElem = elem;
					},
					children: (
						<React.Fragment>
							{popper.props.children}
							{React.cloneElement(arrow, {
								ref: (elem: Element) => {
									this.arrowElem = elem;
								},
							})}
						</React.Fragment>
					),
				})}
			</React.Fragment>
		);
	}
}

// I don't really understand the HTMLAttributes typings...
export class CalloutsImage extends React.Component<CalloutsImageProps> {

	render() {
		const {items, maxWidth, ...rest} = this.props;

		const imageStyles = {
			maxWidth,
		};

		return (
			<div className={classes.container}>
				<ul className={classes.callouts_list}>
					{
						items.map((item: CalloutContentProps, index: number) => {
							return (
								<li key={index} className={classes.callout}>
									<CalloutContent {...item} />
								</li>
							)
						})
					}
				</ul>
				<img style={imageStyles} className={classes.image} {...rest} />
			</div>
		);
	}
};
