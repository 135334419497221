import * as React from 'react';
import {connect} from 'react-redux';

import {
  Paragraph,
  Overline,
  H1,
  H2,
  H3,
  Quote1,
  Quote2,
} from 'hannah/src/app/frontend/typography';

import {LightboxContextManager} from 'hannah/src/app/frontend/components/lightbox/lightbox_context_manager';

import {loadCollectedAssetUrls} from 'hannah/src/app/frontend/static_utils';
import {ProjectIntroCard} from 'hannah/src/app/frontend/components/layout/project_intro_card/project_intro_card';
import {PageSection} from 'hannah/src/app/frontend/components/layout/page_section/page_section';
import {PageSubsection} from 'hannah/src/app/frontend/components/layout/page_subsection/page_subsection';
import {OrderedListCards} from 'hannah/src/app/frontend/components/layout/ordered_list_cards/ordered_list_cards';
import {PageImage} from 'hannah/src/app/frontend/components/layout/page_image/page_image';
import {ColoredDotLegend} from 'hannah/src/app/frontend/components/layout/colored_dot_legend/colored_dot_legend';
import {PageCompareImage} from 'hannah/src/app/frontend/components/layout/page_compare_image/page_compare_image';
import {PageCalloutsImage} from 'hannah/src/app/frontend/components/layout/page_callouts_image/page_callouts_image';
import {PageCarousel} from 'hannah/src/app/frontend/components/layout/page_carousel/page_carousel';
import {LightboxThumbnails} from 'hannah/src/app/frontend/components/lightbox/lightbox_thumbnails';
import {PageHeader} from 'hannah/src/app/frontend/components/layout/page_header/page_header';
import {ThreePaneImage} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';
import {TwoPaneImage} from 'hannah/src/app/frontend/components/layout/two_pane_image/two_pane_image';
import {PageVideo} from 'hannah/src/app/frontend/components/layout/page_video/page_video';
import {FigureShowcase} from 'hannah/src/app/frontend/components/layout/figure_showcase/figure_showcase';
import {ProjectCTACard} from 'hannah/src/app/frontend/components/layout/project_cta_card/project_cta_card';
import {FigureGroup} from 'hannah/src/app/frontend/components/layout/figure_group/figure_group';
import {HannahAppReduxState} from 'hannah/src/app/frontend/default_state_template';
import {PageLink} from 'hannah/src/app/frontend/components/layout/page_link/page_link';
import {PageCrossfadingImages} from 'hannah/src/app/frontend/components/layout/page_crossfading_images/page_crossfading_images';
import {EmailLink} from 'hannah/src/app/frontend/components/layout/email_link/email_link';
import {DownloadButton} from 'hannah/src/app/frontend/components/layout/download_button/download_button';

import {
  LOADED_PROJECT_PAGE_SECTION_DEF,
  ComponentConfig,
  Components,
  Layout,
  Page,
  PageItem,
} from 'hannah/src/app/frontend/page_config';

import * as classes from 'hannah/src/app/frontend/page_generator.css';

function renderComponent(
  config: ComponentConfig,
  allComponentConfigs: ComponentConfig[],
  parentComponentConfig?: ComponentConfig,
): JSX.Element | null {

  function renderChildren(componentDefs: any /* TODO */) {
    return componentDefs.map((def: any /* TODO */, index: number) => {
      // TODO(taylorm) text should have it's own ts DEF in page_config
      // so that we don't need to special case strings here
      // to support paragraphs with a mix of strings and nested components
      if (typeof def === 'string') {
        return def;
      }
      const elem = renderComponent(def, componentDefs, config);
      if (elem) {
        // add key
        return React.cloneElement(elem, {key: index});
      }
      return elem;
    });
  }

  const curIndex = allComponentConfigs.indexOf(config);
  const prevComponent = allComponentConfigs[curIndex - 1];
  const nextComponent = allComponentConfigs[curIndex + 1];
  const isLast = curIndex === allComponentConfigs.length - 1;
  const addMarginBottom = !parentComponentConfig && !isLast;

  const figureShouldHaveMarginTop = !parentComponentConfig && (prevComponent && (
    [
      Components.IMAGE,
      Components.VIDEO,
      Components.FULL_WIDTH_IMAGE,
      Components.FULL_WIDTH_VIDEO,
      Components.BIG_QUOTE,
      Components.H2,
    ].indexOf(prevComponent.type) === -1
  ));

  switch (config.type) {
    case Components.BIG_QUOTE: {
      return <Quote1 {...config.props} />;
    }
    case Components.CALLOUTS_IMAGE: {
      return (
        <PageCalloutsImage
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.CAROUSEL: {
      return (
        <PageCarousel
          marginTop
          marginBottom={addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.CROSSFADING_IMAGES: {
      return (
        <PageCrossfadingImages
          marginTop={figureShouldHaveMarginTop}
          marginBottom={!parentComponentConfig && addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.COLORED_DOT_LEGEND: {
      return <ColoredDotLegend {...config.props} />;
    }
    case Components.COMPARE_IMAGE: {
      return (
        <PageCompareImage
          marginTop
          marginBottom={addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.DOWNLOAD_BUTTON: {
      return <DownloadButton {...config.props} />;
    }
    case Components.EMAIL_LINK: {
      return (
        <EmailLink {...config.props} />
      );
    }
    case Components.FIGURE_SHOWCASE: {
      return (
        <FigureShowcase>
          {renderChildren(config.props.children)}
        </FigureShowcase>
      );
    }
    case Components.FULL_WIDTH_FIGURE_GROUP: {
      return (
        <FigureGroup
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          stackOnMobile={true}
          sideMargins>
          {renderChildren(config.props.children)}
        </FigureGroup>
      );
    }
    case Components.NON_STACKING_FULL_WIDTH_FIGURE_GROUP: {
      return (
        <FigureGroup
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          stackOnMobile={false}
          sideMargins>
          {renderChildren(config.props.children)}
        </FigureGroup>
      );
    }
    case Components.FULL_WIDTH_IMAGE: {
      return (
        <PageImage
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          sideMargins
          {...config.props}
        />
      );
    }
    case Components.FULL_WIDTH_VIDEO: {
      return (
        <PageVideo
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          sideMargins
          {...config.props}
        />
      );
    }
    case Components.H1: {
      return <H1 {...config.props} />;
    }
    case Components.H2: {
      return <H2 {...config.props} />;
    }
    case Components.H3: {
      return <H3 {...config.props} />;
    }
    case Components.IMAGE: {
      return (
        <PageImage
          marginTop={figureShouldHaveMarginTop}
          marginBottom={!parentComponentConfig && addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.FIGURE_GROUP: {
      return (
        <FigureGroup
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          stackOnMobile={true}>
          {renderChildren(config.props.children)}
        </FigureGroup>
      );
    }
    case Components.NON_STACKING_FIGURE_GROUP: {
      return (
        <FigureGroup
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          stackOnMobile={false}>
          {renderChildren(config.props.children)}
        </FigureGroup>
      );
    }
    case Components.LIGHTBOX_THUMBNAILS: {
      return <LightboxThumbnails {...config.props} />;
    }
    case Components.LINK: {
      return <PageLink {...config.props} />;
    }
    case Components.LONG_QUOTE: {
      return <Quote2 {...config.props} />;
    }
    case Components.ORDERED_LIST_CARDS: {
      return (
        <OrderedListCards
          marginTop={prevComponent && (
            [
              Components.PARAGRAPH,
            ].indexOf(prevComponent.type) !== -1
          )}
          marginBottom={addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.OVERLINE: {
      return <Overline {...config.props} />;
    }
    case Components.PARAGRAPH: {
      const {
        children,
        ...rest,
      } = config.props;
      return (
        <Paragraph
          marginTop={prevComponent && (
            [
              Components.BIG_QUOTE,
              Components.CAROUSEL,
              Components.CALLOUTS_IMAGE,
              Components.COMPARE_IMAGE,
              Components.CROSSFADING_IMAGES,
              Components.FIGURE_GROUP,
              Components.NON_STACKING_FIGURE_GROUP,
              Components.FULL_WIDTH_FIGURE_GROUP,
              Components.NON_STACKING_FULL_WIDTH_FIGURE_GROUP,
              Components.FULL_WIDTH_IMAGE,
              Components.FULL_WIDTH_VIDEO,
              Components.H1,
              Components.H2,
              Components.IMAGE,
              Components.ORDERED_LIST_CARDS,
              Components.PARAGRAPH,
              Components.THREE_PANE_IMAGE,
              Components.TWO_PANE_IMAGE,
              Components.VIDEO,
            ].indexOf(prevComponent.type) === -1
          )}
          marginBottom={addMarginBottom && nextComponent && (
            [
              Components.BIG_QUOTE,
              Components.CAROUSEL,
              Components.CALLOUTS_IMAGE,
              Components.COMPARE_IMAGE,
              Components.CROSSFADING_IMAGES,
              Components.FIGURE_GROUP,
              Components.NON_STACKING_FIGURE_GROUP,
              Components.FULL_WIDTH_FIGURE_GROUP,
              Components.NON_STACKING_FULL_WIDTH_FIGURE_GROUP,
              Components.FULL_WIDTH_IMAGE,
              Components.FULL_WIDTH_VIDEO,
              Components.IMAGE,
              Components.ORDERED_LIST_CARDS,
              Components.PARAGRAPH,
              Components.THREE_PANE_IMAGE,
              Components.TWO_PANE_IMAGE,
              Components.VIDEO,
            ].indexOf(nextComponent.type) === -1
          )}
          marginTopSmall={prevComponent && (
            [
              Components.PARAGRAPH,
            ].indexOf(prevComponent.type) !== -1
          )}
          {...rest}
        >
          {renderChildren(children)}
        </Paragraph>
      );
    }
    case Components.PROJECT_INTRO_CARD: {
      return <ProjectIntroCard {...config.props} />;
    }
    case Components.PROJECT_CTA_CARD: {
      return <ProjectCTACard {...config.props} />;
    }
    case Components.THREE_PANE_IMAGE: {
      return (
        <ThreePaneImage
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.TWO_PANE_IMAGE: {
      return (
        <TwoPaneImage
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          {...config.props}
        />
      );
    }
    case Components.VIDEO: {
      return (
        <PageVideo
          marginTop={figureShouldHaveMarginTop}
          marginBottom={addMarginBottom}
          {...config.props}
        />
      );
    }
  }

  return null;
}

function processComponentConfig(
  config: ComponentConfig,
  allComponentConfigs: ComponentConfig[]
) {
  let wrappedElem = renderComponent(config, allComponentConfigs);

  if ([
    // make these top level things open a lightbox
    Components.CAROUSEL,
    Components.FIGURE_GROUP,
    Components.FULL_WIDTH_FIGURE_GROUP,
    Components.NON_STACKING_FULL_WIDTH_FIGURE_GROUP,
    Components.FULL_WIDTH_IMAGE,
    Components.FULL_WIDTH_VIDEO,
    Components.IMAGE,
    Components.LIGHTBOX_THUMBNAILS,
    Components.FIGURE_SHOWCASE,
  ].indexOf(config.type) !== -1) {
    wrappedElem = (
      <LightboxContextManager>
        {wrappedElem!}
      </LightboxContextManager>
    );
  }

  if ([
    // do not wrap these in PageSubsection
    Components.CALLOUTS_IMAGE,
    Components.CAROUSEL,
    Components.FIGURE_SHOWCASE,
    Components.FULL_WIDTH_FIGURE_GROUP,
    Components.FULL_WIDTH_IMAGE,
    Components.FULL_WIDTH_VIDEO,
  ].indexOf(config.type) === -1) {
    wrappedElem = (
      <PageSubsection>
        {wrappedElem}
      </PageSubsection>
    );
  }

  return wrappedElem;
}

export interface ProgressivePageGeneratorProps {
  page: Page;
  urlPathOnPageLoad: string;
}

/* TODO(taylorm) this should be refactored */

const LOAD_DELAY_MS = 500;

export class ProgressivePageGeneratorView extends React.PureComponent<ProgressivePageGeneratorProps> {
  
  state = {
    loadedSections: [],
  };

  private pendingTimeout: number;

  async componentDidMount() {
    const {page, urlPathOnPageLoad} = this.props;

    const pageSections = page.filter((pageItem: PageItem) => {
      return pageItem.type === Layout.PROJECT_PAGE_SECTION;
    });

    // HACK timeout exists to make nav to fullpage transition smoother
    const isInitialPageLoad = location.pathname === urlPathOnPageLoad;
    if (isInitialPageLoad) {
      this.preloadNextSection(pageSections);
    } else {
      this.pendingTimeout = setTimeout(() => {
        this.preloadNextSection(pageSections);
      }, LOAD_DELAY_MS);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.pendingTimeout);
  }

  async preloadNextSection(pageSections: PageItem[]) {
    const {loadedSections} = this.state;
    const section = pageSections.shift();
    // help TS out
    if (section && section.type === Layout.PROJECT_PAGE_SECTION) {
      const components = section.configFn();
      await loadCollectedAssetUrls();
      const loadedPageSection: LOADED_PROJECT_PAGE_SECTION_DEF = {
        type: Layout.LOADED_PROJECT_PAGE_SECTION,
        components: components,
      };

      this.setState({
        loadedSections: [
          ...loadedSections,
          loadedPageSection,
        ],
      }, () => {
        this.preloadNextSection(pageSections);
      });
    }
  }

  render() {
    const {page} = this.props;
    const {loadedSections} = this.state;

    // NOTE relying on index here is OK because we don't have updated
    // but obviously is not ideal

    return (
      <div className={classes.page_content_container}>
      {
        [...page, ...loadedSections].map((pageItem: PageItem, outerIndex: number) => {
          switch (pageItem.type) {
            case Layout.PROJECT_PAGE_HEADER: {
              return (
                <PageHeader key={outerIndex} {...pageItem.props} />
              );  
            }
            case Layout.LOADED_PROJECT_PAGE_SECTION: {
              const {components} = pageItem;
              return (
                <PageSection key={outerIndex}>
                  {
                    components.map((config: ComponentConfig, innerIndex: number) => {
                      const elem = processComponentConfig(config, components);
                      if (elem) {
                        // clone to add key
                        return React.cloneElement(elem, {key: innerIndex});
                      }
                      return elem;
                    })
                  }
                </PageSection>
              );  
            }
          }
        })
      }
      </div>
    )
  }
}

const mapStateToProps = (state: HannahAppReduxState) => {
  const {
    urlPathOnPageLoad,
  } = state;
  return {
    urlPathOnPageLoad,
  };
};

export const ProgressivePageGenerator = connect(
  mapStateToProps,
)(ProgressivePageGeneratorView);
