import * as React from 'react';
import classNames from 'classnames';

import * as classes from 'hannah/src/app/frontend/components/layout/expand_icon/expand_icon.css';

export const ExpandIcon = (props: React.HTMLAttributes<HTMLDivElement>) => {

	const {
		className,
		...rest,
	} = props;

	const cx = classNames(classes.icon, className);

	return (
		<div className={cx} {...rest}>
			<svg xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path d="M0 0h24v24H0z" fill="none" />
				<path fill="#545C65" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
			</svg>
		</div>
	);
};
