import * as React from 'react';
import classNames from 'classnames';

import * as classes from 'hannah/src/app/frontend/components/autoplay_video/autoplay_video.css';

export interface AutoplayVideoProps {
	playButton: boolean;
	retryDownloadIntervalMS?: number;
	retryDownloadOnErrorTimes?: number;
}

interface AutoplayVideoState {
	isPlaying: boolean;
	isHovered: boolean;
}

function elementIsInView(el: Element) {
	const scroll = window.scrollY || window.pageYOffset
	const boundsTop = el.getBoundingClientRect().top + scroll
	
	const viewport = {
		top: scroll,
		bottom: scroll + window.innerHeight,
	};
	
  const bounds = {
		top: boundsTop,
		bottom: boundsTop + el.clientHeight,
	};
	
	return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) 
		|| (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
}


interface PlayButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
	isPlaying: boolean;
}

const PlayButton = (props: PlayButtonProps) => {
	const {isPlaying} = props;
	const cx = classNames({
		[classes.play_button_overlay]: true,
		[classes.play_button_overlay_playing]: isPlaying, 
	});
	return (
		<div className={cx}>
			<button className={classes.play_button} {...props}>
				{
					isPlaying ? (
						<span className={classes.pause_button_symbol} />
					) : (
						<span className={classes.play_button_symbol} />
					)
				}
			</button>
		</div>
	);
};

// React.HTMLAttributes<HTMLVideoElement> not working
export class AutoplayVideo extends React.PureComponent<any, AutoplayVideoState> {
	
	state = {
		isPlaying: false,
		isHovered: false,
	};

	private scrollListener: EventListener;
	private elem: HTMLVideoElement;
	private lastAnimationFrameId: number;
	private retryDownloadIntervalId: any;

	componentDidMount() {

		const {
			retryDownloadIntervalMS = 1000,
			retryDownloadOnErrorTimes = 10,
		} = this.props;

		let retriedTimes = 0;

		this.retryDownloadIntervalId = setInterval(() => {
			const inferServerConnectionStalled = this.elem.readyState === 1;
			if (inferServerConnectionStalled) {
				this.elem.load();
				if (retriedTimes >= retryDownloadOnErrorTimes) {
					console.log('already retried maximum number of times to re download video.');
					clearInterval(this.retryDownloadIntervalId);
				}
				retriedTimes++;
			} else if (this.elem.readyState === 4) {
				clearInterval(this.retryDownloadIntervalId);
			}
		}, retryDownloadIntervalMS);

		this.elem.onerror = () => {
			if (retriedTimes >= retryDownloadOnErrorTimes) {
				return
			}
			this.elem.load();
			retriedTimes++;
			console.log('There was an error loading the video, so trying again');
		};

		let ticking = false;

		const scrollCallback = () => {
			ticking = true;
			const {isPlaying} = this.state;
			const {playButton} = this.props;
			const isDownloaded = this.elem.readyState === 4;
			if (!isDownloaded) {
				ticking = false;
				return;
			}
			if (!isPlaying && elementIsInView(this.elem) && this.elem.paused && !playButton && !isPlaying) {
				this.setState({
					isPlaying: true,
				}, () => {
					ticking = false;
				});
			} else if (!elementIsInView(this.elem) && isPlaying) {
				this.setState({
					isPlaying: false,
				}, () => {
					ticking = false;
				});
			} else {
				ticking = false;
			}
		};

		this.scrollListener = () => {
			if (!ticking) {
				this.lastAnimationFrameId = requestAnimationFrame(scrollCallback);
			}
		};

		this.elem.onloadeddata = () => {
			clearInterval(this.retryDownloadIntervalId);
			if (!ticking) {
				scrollCallback();
			}
		};

		window.addEventListener('scroll', this.scrollListener);
	}

	componentDidUpdate() {
		const {
			isPlaying,
		} = this.state;

		if (isPlaying && this.elem.paused) {
			this.elem.play();
		} else if (!isPlaying && !this.elem.paused) {
			this.elem.pause();
		}
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.lastAnimationFrameId);
		window.removeEventListener('scroll', this.scrollListener);
	}

	handleClickPlayButton = () => {
		const {
			isPlaying,
		} = this.state;
		this.setState({
			isPlaying: !isPlaying,
		});
	}

	handleMouseEnter = () => {
		this.setState({
			isHovered: true,
		});
	};

	handleMouseLeave = () =>{
		this.setState({
			isHovered: false,
		});
	}

	render() {
		const {
			playButton = false,
			...rest,
		} = this.props;
		const {
			isPlaying,
			isHovered,
		} = this.state;

		return (
			<div
				className={classes.container}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}>
				{
					playButton && (!isPlaying || isHovered) && (
						<PlayButton isPlaying={isPlaying} onClick={this.handleClickPlayButton} /> 
					)
				}
				<video
					ref={(el: HTMLVideoElement) => this.elem = el}
					{...rest}
				/>
			</div>
		);
	}
}
