import * as React from 'react';
import {SpringHelperConfig} from 'react-motion';

import {assetUrl} from 'hannah/src/app/frontend/static_utils';
import {TypographyPage} from 'hannah/src/app/frontend/pages/typography_page';

import adventurePageConfig from 'hannah/src/app/frontend/page_configs/adventure';
import dataVisualizationsPageConfig from 'hannah/src/app/frontend/page_configs/data_visualizations';
import dealpathDesignKitPageConfig from 'hannah/src/app/frontend/page_configs/dealpath_design_kit';
import globalFilteringPageConfig from 'hannah/src/app/frontend/page_configs/global_filtering';
import guidingTheGridPageConfig from 'hannah/src/app/frontend/page_configs/guiding_the_grid';
import archivePageConfig from 'hannah/src/app/frontend/page_configs/archive';
import aboutPageConfig from 'hannah/src/app/frontend/page_configs/about';
import dealpathPageConfig from 'hannah/src/app/frontend/page_configs/dealpath';

import {
	IndexPageConfigItem,
	PageConfigItem,
	PageRenderConfigItem,
	AuthorInfo,
	HomePageContent,
} from 'hannah/src/app/frontend/site_config_types';

export const INDEX_PAGE: IndexPageConfigItem = {
	title: 'Hannah McIntyre&rsquo;s Design Portfolio',
	text: 'Projects',
	href: '/',
	coverImageUrl: 'https://placekitten.com/800/600',
};

export const PROJECT_PAGES: PageConfigItem[] = [
	{
		title: 'Global filtering',
		text: 'Global filtering',
		href: '/global-filtering',
		coverImageUrl: assetUrl('images/global-filtering/1-hero/hero-filtering-alternative.jpg'),
		config: globalFilteringPageConfig,
	},
	{
		title: 'Data Visualizations',
		text: 'Data visualizations',
		href: '/data-visualizations',
		coverImageUrl: assetUrl('images/data-visualization/1-hero/charts-hero-image.jpg'),
		config: dataVisualizationsPageConfig,
	},
	{
		title: 'Guiding the Grid',
		text: 'Guiding the grid',
		href: '/guiding-the-grid',
		coverImageUrl: assetUrl('images/grid/1-hero/grid-hero-image.png'),
		config: guidingTheGridPageConfig,
	},
	{
		title: 'Dealpath Design Kit',
		text: 'Dealpath design kit',
		href: '/dealpath-design-kit',
		coverImageUrl: assetUrl('images/guiding-the-style/1-hero/styleguide-shorter-c.jpg'),
		config: dealpathDesignKitPageConfig,
	},	
	{
		title: 'Adventure',
		text: 'Adventure',
		href: '/adventure',
		coverImageUrl: assetUrl('images/adventure/1-hero/hero-800.jpg'),
		config: adventurePageConfig,
	},
];

export const INFO_PAGES: PageConfigItem[] = [
	{
		title: 'Archive',
		hideOnMobile: true,
		text: 'Archive',
		href: '/archive',
		coverImageUrl: assetUrl('images/archive/1-hero/archive-hero.jpg'),
		config: archivePageConfig,
	},
	{
		title: 'About',
		text: 'About',
		href: '/about',
		coverImageUrl: assetUrl('images/about-me/hannah-silly.jpg'),
		config: aboutPageConfig,
	},
];

export const NO_NAV_PAGE_CONFIGS: PageConfigItem[] = [
	{
		title: 'Dealpath',
		text: 'Dealpath',
		href: '/dealpath',
		coverImageUrl: assetUrl('images/dealpath/1-hero/hero-800.jpg'),
		config: dealpathPageConfig,
	},
];

export const NO_NAV_PAGE_CONFIGS_WITH_CUSTOM_RENDER: PageRenderConfigItem[] = [
	{
		text: 'Typography (dev only)',
		title: 'Typography (dev only)',
		href: '/typography',
		coverImageUrl: 'https://placekitten.com/800/650',
		render: () => <TypographyPage />,
	},
];

export const AUTHOR_INFO: AuthorInfo = {
	name: 'Hannah McIntyre',
	title: 'Product designer',
	credit: 'Designed and built by Hannah McIntyre.',
	email: 'hannahnmcintyre@gmail.com',
};

export const HOME_PAGE_CONTENT: HomePageContent = {
	contactBlurb: `coffee? drink? ${AUTHOR_INFO.email}`,
};

export const HOMEPAGE_COVER_IMAGE_SCALE_UP_RATIO = 1.05;

// http://chenglou.github.io/react-motion/demos/demo5-spring-parameters-chooser/
export const TOP_NAV_SPRING_CONFIG: SpringHelperConfig = {
	stiffness: 196,
	damping: 24,
};

export const HOMEPAGE_COVER_IMAGE_SLIDE_SPRING_CONFIG: SpringHelperConfig = {
	stiffness: 196,
	damping: 24,
};

export const HOMEPAGE_COVER_IMAGE_CROSS_FADE_SPRING_CONFIG: SpringHelperConfig = {
	stiffness: 196,
	damping: 24,
};

export const HOMEPAGE_COVER_IMAGE_ZOOM_SPRING_CONFIG: SpringHelperConfig = {
	stiffness: 196,
	damping: 24,
};

export const PAGE_TOP_NAVIGATION_SPRING_CONFIG: SpringHelperConfig = {
	stiffness: 196,
	damping: 24,
};
