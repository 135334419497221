import * as React from 'react';
import {spring, Motion, SpringHelperConfig} from 'react-motion';
import classNames from 'classnames';

interface TranslateXMotionProps extends React.HTMLAttributes<HTMLDivElement> {
	isOn: boolean;
	onAndRestedClassName?: string;
	onClassName?: string;
	offTranslateX: number;
	onTranslateX: number;
	springConfig?: SpringHelperConfig;
}

interface TranslateXMotionState {
	didMount: boolean;
	restedIsOn: boolean;
}

export class TranslateXMotion extends React.Component<TranslateXMotionProps, TranslateXMotionState> {

	constructor(props: TranslateXMotionProps) {
		super(props);
		const {isOn} = props;
		this.state = {
			didMount: false,
			restedIsOn: isOn,
		};
	}

	componentDidMount() {
		this.setState({
			didMount: true,
		});
	}

	private handleOnRest = () => {
		const {isOn} = this.props;
		this.setState({
			restedIsOn: isOn,
			didMount: isOn,
		});
	};

	render() {
		const {
			isOn,
			children,
			className,
			onAndRestedClassName,
			onClassName,
			offTranslateX,
			onTranslateX,
			springConfig,
			...rest
		} = this.props;
		const {didMount, restedIsOn} = this.state;

		const endTranslateX = isOn ? onTranslateX : offTranslateX;
		let startTranslateX: number;

		if (!didMount) {
			// first, initial, default style
			// the rest will be interpolated from current spring value
			startTranslateX = endTranslateX;
		}

		return (
			<Motion
				defaultStyle={{translateXPercent: startTranslateX!}}
				style={{translateXPercent: spring(endTranslateX, springConfig)}}
				onRest={this.handleOnRest}
			>{({translateXPercent}) => {
				const classes = [];
				if (className) {
					classes.push(className);
				}
				if (onAndRestedClassName && isOn || restedIsOn) {
					classes.push(onAndRestedClassName);
				}
				if (onClassName && isOn) {
					classes.push(onClassName);
				}
		  	return (
					<div
						style={{
							transform: `translateX(${translateXPercent}%)`,
						}}
						className={classNames(classes)}
						{...rest}>
						{children}
					</div>
		  	);
			}}</Motion>
		);
	}
};
