import {assetUrl} from 'hannah/src/app/frontend/static_utils';
//import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';
//import {ThreePaneImageCaptionStyles} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';

import {
	//carousel,
	//coloredDotLegend,
	//compareImage,
	h1,
	h2,
	//image,
	//lightboxThumbnails,
	//orderedListCards,
	overline,
	page,
	paragraph,
	projectIntroCard,
	projectPageHeader,
	projectCTACard,
	//bigQuote,
	section,
	//video,
	//threePaneImage,
} from 'hannah/src/app/frontend/page_config';

export default page(
	projectPageHeader({
		heading: `Dealpath`,
		subhead: `Streamlining the way CRE teams manage deals.`,
	}),
	section(() => [
		overline(`Intro`),
		projectIntroCard({
			descriptionHeading: `The company`,
			descriptionParagraph: `
				Dealpath is the leading deal management platform for real estate investment and development teams. 
				It provides a suite of features for smart pipeline tracking, deal analytics, and collaborative workflows. 
				It is used by companies like WeWork, Blackstone, and CBRE so teams can focus on value-add work and produce optimal risk-adjusted returns.
			`,
			roleHeading: `My role`,
			roleParagraph: `
				Lead Designer owning all user research, product strategy, UX, and visual design.
			`,
			timeframeHeading: `Timeframe`,
			timeframeParagraph: `September 2015 - August 2019`,
		}),
	]),
	section(() => [
		h2(`Company mission`),
		paragraph(
			`
				To support professionals in acquisitions, financing, and development be more efficient and effective in their work by providing the tools, resources, and information they need to create and capture value.
			`,
		),	
		h2(`Our customers`),
		paragraph(
			`
				Dealpath customers range from the largest public REITs and PERE firms to agile investment teams who deploy capital in the acquisition, development, and financing of assets across all property types.
			`,
		),
		h2(`Joining the team`),
		paragraph(
			`
				I joined Dealpath right after closing a Series A round of funding when the team was only 8 people. 
				At this size, everyone was wearing many hats, so I stepped up as the Head of People Operations. 
				I previously had experience with hiring and growing teams, and we needed to add teammates to build a product. 
				I increased the team by 3x and shifted into product design. 
				I was the only product designer for a few years, and at the beginning of 2019, we hired another designer who I mentored and managed. 
				It was amazing watching the company grow from only a few customers to thousands, and we even secured a large Series B round of funding.
			`,
		),		
	]),
	section(() => [
		overline(`Selected projects`),		
		h1(`Projects`),
		paragraph(`
			I had the opportunity to work on so many amazing projects over the past four years that it was tricky to pick only a few case studies. 
			Projects ranged from complicated settings flows to visual and style guide guidelines. 
			Click on a case study below to find out more.
		`),
		projectCTACard({
			imageSrc: assetUrl('images/dealpath/2-cards/graphic-filtering.png'),
			displayText: 'Global filtering',
			buttonText: 'Go to case study',
			background: 'linear-gradient(244deg, #687bf5, rgba(195, 190, 247, 0.99) 49%, #d2e8ea)',
			to: '/global-filtering',
		}),
		projectCTACard({
			imageSrc: assetUrl('images/dealpath/2-cards/graphic-data-viz.png'),
			displayText: 'Data visualization',
			buttonText: 'Go to case study',
			background: 'linear-gradient(244deg, #68ddc1, #71df89 44%, #e6fb86)',
			to: '/data-visualizations',
		}),
		projectCTACard({
			imageSrc: assetUrl('images/dealpath/2-cards/graphic-grid.png'),
			displayText: 'Guiding the grid',
			buttonText: 'Go to case study',
			background: 'linear-gradient(244deg, #ffe773, #ffc485 52%, #ffa192)',
			to: '/guiding-the-grid',
		}),
		projectCTACard({
			imageSrc: assetUrl('images/dealpath/2-cards/graphic-design-kit.png'),
			displayText: 'Design kit',
			buttonText: 'Go to case study',
			background: 'linear-gradient(64deg, #d0e2eb, #c6c6f5 49%, #ffa491)',
			to: '/dealpath-design-kit',
		}),
		/* Text shouldn't wrap. Add hover state on buttons 
		Also, clicking home shouldn't still show DP image
		*/
	]),
);




