//import {assetUrl} from 'hannah/src/app/frontend/static_utils';
//import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';
//import {ThreePaneImageCaptionStyles} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';
import {S3_ASSET_BUCKET_URL} from 'hannah/src/app/frontend/constants';

import {
	AUTHOR_INFO,
} from 'hannah/src/app/frontend/site_config';

import {
	//carousel,
	//coloredDotLegend,
	//compareImage,
	downloadButton,
	emailLink,
	h1,
	h2,
	//image,
	//lightboxThumbnails,
	link,
	//orderedListCards,
	overline,
	page,
	paragraph,
	//projectIntroCard,
	projectPageHeader,
	//bigQuote,
	section,
	//video,
	//threePaneImage,
} from 'hannah/src/app/frontend/page_config';

export default page(
	projectPageHeader({
		heading: `About me`,
		subhead: `A little about my story.`,
	}),
	section(() => [
		overline(`Intro`),
		h1(`My story`),
		h2(`Keeping a long story short`),		
		paragraph(`
			Hi! I’m Hannah, and I’m a product designer located in San Francisco. 
			I’ve spent the past four years at `,
			link({
					text: `Dealpath`,
					to: '/dealpath',
			}),			
			` building a workflow platform for commercial real estate investors.
		`),	
		paragraph(`
			With 4+ years in product and startups, I’ve designed mobile and web products in industries such as beauty, recruiting, travel, real estate, and legal. 
			My focus is always to build products that are intuitive, accessible, beautiful, and fun. 
			I give 100% to everything I do and thrive with complex challenges.
		`),
		paragraph(`
			I was born and raised in sunny Santa Barbara and graduated with a degree in Landscape Architecture from Cal Poly, San Luis Obispo in 2014. 
			I moved with my husband to San Francisco the same year and immediately discovered and fell in love with product design. 
			When I’m not designing, you might find me in the kitchen testing out new recipes, wine tasting in Sonoma, or exploring the outdoors.
		`),	
	]),
	section(() => [
		overline(`Next step`),
		h1(`Let's connect!`),		
		paragraph(`Message me at `,
			emailLink(AUTHOR_INFO.email),
			`—my inbox is always open. If you’re in San Francisco, coffee or drinks are welcome too!`,
		),
		downloadButton({
			href: `${S3_ASSET_BUCKET_URL}/pdfs/hm_resume.pdf`,
			text: 'Download resume',
		}),
	]),
);



