import classNames from 'classnames';
import * as React from 'react';
import {connect} from 'react-redux';
import {SpringHelperConfig} from 'react-motion';
import {HannahAppReduxState} from 'hannah/src/app/frontend/default_state_template';
import {setActiveTopLinkHrefAction} from 'hannah/src/app/frontend/index_actions';

import {TopLeftNav} from 'hannah/src/app/frontend/components/top_left_nav/top_left_nav';
import {CrossfadingScalingImage} from 'hannah/src/app/frontend/components/crossfading_scaling_image/crossfading_scaling_image';
import {
	TranslateXMotion,
} from 'hannah/src/app/frontend/components/translate_x_motion/translate_x_motion';

import {IS_MOBILE_OR_TABLET} from 'hannah/src/app/frontend/environment_utils';

import * as classes from 'hannah/src/app/frontend/components/homepage_cover_flow/homepage_cover_flow.css';

import {
	PageConfigItem,
	PageRenderConfigItem,
	AuthorInfo,
	HomePageContent,
} from 'hannah/src/app/frontend/site_config_types';

export interface HomepageCoverFlowProps {
	navIsOpen: boolean;
	projectPageConfigs: PageConfigItem[];
	infoPageConfigs: PageConfigItem[];
	noNavPageConfigs: (PageConfigItem | PageRenderConfigItem)[];
	authorInfo: AuthorInfo,
	homePageContent: HomePageContent;
	coverImageSlideSpringConfig?: SpringHelperConfig;
	coverImageCrossFadeSpringConfig?: SpringHelperConfig;
	coverImageZoomSpringConfig?: SpringHelperConfig;
	coverImageScaleUpRatio: number;
	navSpringConfig?: SpringHelperConfig;
	setActiveTopLinkHref: typeof setActiveTopLinkHrefAction;
	activeTopLinkHref: string;
	urlPathOnPageLoad: string;
}

export interface HomepageCoverFlowState {
	imageScalingTransitionIsEnabled: boolean;
}

export class HomepageCoverFlowView extends React.Component<HomepageCoverFlowProps, HomepageCoverFlowState> {

	private resizeListener: any;

	state = {
		// XXX super confusing state variable that actually controls
		// whether the hero image scales by animating the padding top
		// we don't want this to happen if the nav takes up the whole screen
		// and you can't see the hero image. This happens on mobile
		// and is controlled in media queries in top_left_nav.css
		imageScalingTransitionIsEnabled: false,
	}

	componentWillMount() {

		let ticking = false;

		const resizeHandler = () => {
			const {imageScalingTransitionIsEnabled} = this.state;
			// XXX unify with media queries in top_left_nav.css
			const isMobileWidth = window.innerWidth <= 600;

			if (!isMobileWidth !== imageScalingTransitionIsEnabled) {
				this.setState({
					imageScalingTransitionIsEnabled: !isMobileWidth,
				}, () => {
					ticking = false;
				});
			} else {
				ticking = false;
			}
		};

		this.resizeListener = () => {
			if (!ticking) {
				ticking = true;
				requestAnimationFrame(resizeHandler);
			}
		};

		window.addEventListener('resize', this.resizeListener);
		this.resizeListener();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeListener);
	}

	handleMouseEnterNavItem = (pageConfig: PageConfigItem) => {
		const {
			setActiveTopLinkHref,
		} = this.props;
		const {
			href,
		} = pageConfig;
		setActiveTopLinkHref(href);
	};

	render() {
		const {
			navIsOpen,
			projectPageConfigs,
			infoPageConfigs,
			noNavPageConfigs,
			authorInfo,
			homePageContent,
			coverImageSlideSpringConfig,
			coverImageCrossFadeSpringConfig,
			coverImageZoomSpringConfig,
			coverImageScaleUpRatio,
			navSpringConfig,
			activeTopLinkHref,
			urlPathOnPageLoad,
		} = this.props;

		const {
			imageScalingTransitionIsEnabled,
		} = this.state;

		function findPageConfigByHref(searchHref?: string) {
			const pageConfigs = [
				...projectPageConfigs,
				...infoPageConfigs,
				...noNavPageConfigs,
			];
			return searchHref && pageConfigs.find(({href}: Partial<PageConfigItem>) => {
				return href === searchHref;
			});
		}

		const displayedPageConfig = findPageConfigByHref(activeTopLinkHref);
		const {coverImageUrl = ''} = displayedPageConfig || {};

		// HACK prevent animation if this is the initial page load
    const isInitialPageLoad = location.pathname === urlPathOnPageLoad;

		const crossfadingImageContainerCx = classNames({
			[classes.crossfading_scaling_image_container]: true,
			[classes.crossfading_scaling_image_container_nav_closed]: (
				!imageScalingTransitionIsEnabled || !navIsOpen
			),
			[classes.crossfading_scaling_image_container_transition]: (
				imageScalingTransitionIsEnabled && !navIsOpen && !isInitialPageLoad
			),
		});

		return (
			<React.Fragment>
				<TopLeftNav
					isOpen={navIsOpen}
					projectPageConfigs={projectPageConfigs}
					infoPageConfigs={infoPageConfigs}
					authorInfo={authorInfo}
					homePageContent={homePageContent}
					springConfig={navSpringConfig}
					onMouseEnterNavItem={this.handleMouseEnterNavItem}
					activeTopLinkHref={activeTopLinkHref}
				/>
				<div className={classes.cover_image_container}>
					<TranslateXMotion
						isOn={navIsOpen}
						onTranslateX={33}
						offTranslateX={0}
						onClassName={classes.nav_open_and_rested}
						springConfig={coverImageSlideSpringConfig}>
						<CrossfadingScalingImage
							className={crossfadingImageContainerCx}
							enable={!IS_MOBILE_OR_TABLET}
							url={coverImageUrl}
							imageClassName={classes.crossfading_scaling_image_image}
							scaleUpRatio={coverImageScaleUpRatio}
							opacitySpringConfig={coverImageCrossFadeSpringConfig}
							scaleSpringConfig={coverImageZoomSpringConfig}
						/>
					</TranslateXMotion>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: HannahAppReduxState) => {
	const {
		activeTopLinkHref,
		urlPathOnPageLoad,
	} = state;
  return {
  	activeTopLinkHref,
  	urlPathOnPageLoad,
  };
};

const mapDispatchToProps = {
	setActiveTopLinkHref: setActiveTopLinkHrefAction,
};

export const HomepageCoverFlow = connect(
	mapStateToProps,
	mapDispatchToProps,
)(HomepageCoverFlowView);
