import * as React from 'react';
import classNames from 'classnames';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/chrome_underlay/chrome_underlay.css';

export interface ChromeUnderlayProps {
	src: string;
	padding?: string;
	children: React.ReactChild;
}

export const ChromeUnderlay = (props: ChromeUnderlayProps) => {
	const {
		src,
		padding = '',
		children,
	} = props;

	const styles = {
		backgroundImage: `url(${src})`,
		padding: padding,
	};

	const chromeCx = classNames({
		[classes.chrome]: true,
		[mediaSelectorClasses.content_width_max_wide]: true,
	});

	return (
		<div className={classes.container}>
			<div className={chromeCx} style={styles}>
				{children}
			</div>
		</div>
	);
};
