import {assetUrl} from 'hannah/src/app/frontend/static_utils';
//import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';

import {
	// carousel,
	coloredDotLegend,
	// compareImage,
	//figureGroup,
	h1,
	h2,
	image,
	//fullWidthVideo,
	lightboxThumbnails,
	orderedListCards,
	overline,
	page,
	paragraph,
	projectIntroCard,
	projectPageHeader,
	bigQuote,
	section,
	video,
	fullWidthImage,
	fullWidthFigureGroup,
	nonStackingFullWidthFigureGroup,
	// threePaneImage,
} from 'hannah/src/app/frontend/page_config';

const IPHONE_CHROME_PADDING = '22.9% 12.1% 25%';
const LARGE_IPHONE_CHROME_PADDING = '29.9% 13.1% 29%';

export default page(
	projectPageHeader({
		heading: `Adventure`,
		subhead: `Seamlessly plan and coordinate a trip.`,
	}),
	section(() => [
		overline(`Intro`),
		projectIntroCard({
			descriptionHeading: `The challenge`,
			descriptionParagraph: `
				Design a mobile app from scratch that allows users to plan trips collaboratively.
			`,
			roleHeading: `My role`,
			roleParagraph: `
				Lead designer owning all user research, product strategy, UX, and visual design.
			`,
			timeframeHeading: `Timeframe`,
			timeframeParagraph: `July 2016 - September 2016`,
		}),
		paragraph(`
			TLDR; Trip planning can be a daunting task because it requires hours of research. 
			This application is designed to help users find activities and schedule an itinerary with friends. 
			This was a side project that started when I was planning a long trip abroad and realized there was not a good way to plan a trip with a group of friends. 
			After the mobile app was designed, a friend also was building a trip planning application so meshing our ideas is something we are working towards now.
		`),
	]),
	section(() => [
		overline(`Discover`),
		h1(`Problem`),
		paragraph(`
			Travelers need a way to do their trip planning more effectively and collaboratively because trip planning is currently an independent, time-consuming task.
		`),
	]),
	section(() => [
		h1(`Research`),
		paragraph(`
			Starting the project, I had many assumptions about the issues that people were facing when it came to trip planning. 
			I assumed that most people do their trip planning through online research and word of mouth from friends.
		`),
		coloredDotLegend({
			items: [
				{
					color: `#c1d2f2`,
					text: `Filter`,
				},
				{
					color: `#fff4cd`,
					text: `Options`,
				},
				{
					color: `#c9e3e8`,
					text: `Group by`,
				},
				{
					color: `#bbc8d1`,
					text: `Configure + Filter`,
				},
			],
		}),		
		image({
			src: assetUrl(`images/adventure/2-research/chart-assumptions.jpg`),
			caption: `Assumed breakdown`,
			maxWidth: 350
		}),
		h2(`User interviews`),
		paragraph(`
			To accurately understand what issues people were facing, I conducted several user interviews. 
			The main questions that I focused on were:
		`),			
		orderedListCards({
			items: [
				{
					topic: `Q1: `,
					details: `What have been some of your favorite travel experiences?`,
				},
				{
					topic: `Q2: `,
					details: `How did you plan what to do?`,
				},
				{
					topic: `Q3: `,
					details: `Was there anything frustrating about the travel experience?`,
				},
				{
					topic: `Q4: `,
					details: `Have you ever used an online service to plan a trip?`,
				},
								{
					topic: `Q5: `,
					details: `How do you research what to do on your trip?`,
				},
			],
		}),
		paragraph(`
			I interviewed 20 people, all with varying backgrounds and ages, to ensure I was getting a wide array of responses.
		`),	
		h2(`Pain points`),
		orderedListCards({
			items: [
				{
					topic: `1. Time restrictions:`,
					details: `Most people only get a limited amount of PTO, so they need a way to quickly plan a vacation while fitting in every activity in the short trip.`,
				},
				{
					topic: `2. Hard to find activities:`,
					details: `It is hard to find activities that aren’t just an advertisement for that company.`,
				},
				{
					topic: `3. Too many ways to research:`,
					details: `There isn’t one cohesive place where you go to do trip planning.`,
				},
				{
					topic: `Itinerary planning:`,
					details: `Figuring out logistics can be a nightmare, and people are continually shifting activities around to make everything work.`,
				},
								{
					topic: `No collaboration:`,
					details: `Usually, there is one leader that does all the planning. This process isn’t desired, but it just falls in one person’s lap.`,
				},
			],
		}),
		paragraph(`
			After conducting the interviews, I learned that my assumptions were reasonably accurate, with a few differences. 
			Word of mouth was more common, and fewer people wing the trip than I anticipated.
		`),	
		coloredDotLegend({
			items: [
				{
					color: `#c1d2f2`,
					text: `Filter`,
				},
				{
					color: `#fff4cd`,
					text: `Options`,
				},
				{
					color: `#c9e3e8`,
					text: `Group by`,
				},
				{
					color: `#bbc8d1`,
					text: `Configure + Filter`,
				},
			],
		}),		
		image({
			src: assetUrl(`images/adventure/2-research/chart-actual.jpg`),
			caption: `Actual breakdown`,
			maxWidth: 350
		}),
		paragraph(`
			User research not only answered questions I had around how other people plan trips, but it also confirmed the need for this application. 
			Everyone had frustrations when it came to planning a trip and wished the itinerary process was a more enjoyable experience.
		`),	
		h2(`Competitive research`),
		paragraph(`
			I knew there was competition in the space, so I did extensive competitive analysis to determine what each application was missing and how our app could improve this. 
			Most interviewees mentioned the same trip applications that they use for planning trips: Pinterest, Trip Advisor, and Google.
		`),	
		paragraph(`
			Pinterest, Trip Advisor, and Google docs each have several pros and cons. Hover over each card to see what they are.
		`),	
		nonStackingFullWidthFigureGroup(
			image({
				src: assetUrl(`images/adventure/2-research/pinterest.png`),
				hoverOverlaySrc: assetUrl(`images/adventure/2-research/pinterest-hover.png`),
				caption: `Pinterest`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/adventure/2-research/trip-advisor.jpg`),
				hoverOverlaySrc: assetUrl(`images/adventure/2-research/trip-advisor-hover.png`),
				caption: `Trip Advisor`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/adventure/2-research/google-docs.jpg`),
				hoverOverlaySrc: assetUrl(`images/adventure/2-research/google-docs-hover.png`),
				caption: `Google docs`,
				dropshadow: true,
			}),
		),
		paragraph(`
			In addition to these applications, I analyzed the following less common ones as well.
		`),	
		fullWidthImage({
			src: assetUrl(`images/adventure/2-research/competitive-review.jpg`),
			caption: `Competitive matrix`,
			dropshadow: true,
			maxWidth: 1100
		}),
		paragraph(`
			After analyzing all of the competitive applications, there wasn’t a single app that met all criteria. 
			Since there was so much that I wanted to achieve with this app, I broke down each feature into a matrix to determine what the MVP should be.
		`),	
		fullWidthImage({
			src: assetUrl(`images/adventure/2-research/feature-matrix.jpg`),
			caption: `Feature matrix`,
			dropshadow: true,
			maxWidth: 1100
		}),
		paragraph(`
			Based on the matrix, the features that I wanted to include in the MVP were: the ability to save more than one trip, see friend’s itineraries, group collaboration, map view, comments, select time of year, and have an owner or trip admin.
		`),
	]),
	section(() => [
		h1(`Hypothesis`),
		paragraph(`
			Creating a cohesive application where users can find activities and create an itinerary will cut down the trip planning time by 25% and make it a more enjoyable experience.
		`),
	]),
	section(() => [
		overline(`Define`),
		h1(`Personas`),
		paragraph(`
			Travelers come in all ages and technology levels, so it was essential to map out the types of personas I would be designing for.
		`),
		/* Update assets with just photos and add text separately
		'images/adventure/3-personas/persona-1-no-copy.jpg'
		'images/adventure/3-personas/persona-2-no-copy.jpg'
		'images/adventure/3-personas/persona-3-no-copy.jpg'
		*/
		fullWidthFigureGroup(
			image({
				src: assetUrl(`images/adventure/3-personas/persona-1.jpg`),
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/adventure/3-personas/persona-2.jpg`),
				caption: `Types of personas`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/adventure/3-personas/persona-3.jpg`),
				dropshadow: true,
			}),
		),
	]),
	section(() => [
		h1(`Project goals`),
		paragraph(`
			There were a few key goals in mind when starting this project:
		`),
		orderedListCards({
			items: [
				{
					topic: `Goal 1: `,
					details: `Allow users to save 100% of activities to an itinerary.`,
				},
				{
					topic: `Goal 2: `,
					details: `Users should be able to share or hide all itineraries from selected friends.`,
				},
				{
					topic: `Goal 3: `,
					details: `The mobile application should be image forward and easily scannable. (Each page will be at least 50% images)`,
				},
				{
					topic: `Goal 4: `,
					details: `Finish an MVP within three months.`,
				},
			],
		}),
		paragraph(`
			Since this was a side project, I knew that there was a limited amount of time that I could work on it. 
			I needed to narrow down my features so that we could prepare an MVP that is better than the competition. 
			The goal by the end of the three months was to have a design that was ready for engineering handoff.
		`),
	]),
	section(() => [
		overline(`Ideate`),
		h1(`User flows`),
		paragraph(`
			There were two primary user flows that we needed to think through, the new user and an existing user. 
			Since this app is catering to users of all backgrounds, we needed a clear onboarding flow. 
			To deter users, I assumed it was best to add the signup flow in the middle of the onboarding, so they saw the value before needed to enter an email.
		`),
		fullWidthImage({
			src: assetUrl(`images/adventure/4-user-flows/user-flow.jpg`),
			caption: `User flow`,
			dropshadow: true,
			maxWidth: 1100
		}),
		paragraph(`
			To test the flows, I created several prototypes ranging from napkin drawings to clickable prototypes in Marvel.
		`),
		fullWidthImage({
			src: assetUrl(`images/adventure/4-user-flows/sketch-iterations.jpg`),
			caption: `Prototype versions`,
			dropshadow: true,
			maxWidth: 1100
		}),
	]),
	section(() => [
		h1(`User testing`),
		paragraph(`
			The first flow that I wanted to user test was the onboarding flow. 
			I was curious whether users would prefer creating an account early on and if onboarding screens are helpful.
		`),
		fullWidthFigureGroup(
			video({
				chromeSrc: assetUrl(`images/adventure/7-mvp/small-phone-placeholder.png`),
				chromePadding: IPHONE_CHROME_PADDING,
				src: assetUrl(`images/adventure/5-user-testing/user-testing-video-1.mov`),
				caption: `Option 1`,
				playButton: true,
			}),
			video({
				chromeSrc: assetUrl(`images/adventure/7-mvp/small-phone-placeholder.png`),
				chromePadding: IPHONE_CHROME_PADDING,
				src: assetUrl(`images/adventure/5-user-testing/user-testing-video-2.mov`),
				caption: `Option 2`,
				playButton: true,
			}),
			video({
				chromeSrc: assetUrl(`images/adventure/7-mvp/small-phone-placeholder.png`),
				chromePadding: IPHONE_CHROME_PADDING,
				src: assetUrl(`images/adventure/5-user-testing/user-testing-video-3.mov`),
				caption: `Option 3`,
				playButton: true,
			}),
		),
		paragraph(`
			The user session takeaways were that the onboarding pages were potentially not useful and made the onboarding feel longer. 
			Having the sign up in the beginning or middle didn’t seem to deter the user one way or another. 
			From here, I mapped out the potential flow for the entire app.
		`),
		fullWidthImage({
			src: assetUrl(`images/adventure/5-user-testing/full-flow-chart.jpg`),
			caption: `Wireframes`,
			dropshadow: true,
			maxWidth: 1100
			/* COMP crop with this image: images/adventure/5-user-testing/croped-flow-chart.jpg and then clicking should show full image (above link) */
		}),
	]),
	section(() => [
		h1(`Design iterations`),
		paragraph(`
			Now that the flows were proven successful, it was time to decide on branding and the overall voice of the application. 
			I wanted it to feel sophisticated, trustworthy, and fun. 
			I decided to go with a limited six-color palette that was primarily cool tones with a pop of pink.
		`),
		image({
			src: assetUrl(`images/adventure/6-design-iterations/color-palette.jpg`),
			caption: `Color palette`,
			dropshadow: true,
		}),
		paragraph(`
			There were several visual design iterations to make sure I had the right feel for the application. 
			The video below shows the different versions and design process I went through to end up with the finished product.
		`),
		image({
			src: assetUrl(`images/adventure/6-design-iterations/Adventure_iterations.gif`),
			chromeSrc: assetUrl(`images/adventure/6-design-iterations/large-phone-placeholder.png`),
			chromePadding: LARGE_IPHONE_CHROME_PADDING,
			caption: `Visual design iterations`,
			dropshadow: true,
			maxWidth: 430,
		}),
	]),
	section(() => [
		h1(`The MVP`),
		paragraph(`
			Below are shots of the final MVP. I focused on the activity gallery, activity page, and the itinerary flows.
		`),
		fullWidthFigureGroup(
			image({
				src: assetUrl(`images/adventure/7-mvp/mvp-1.png`),
				caption: `Activity gallery`,
			}),
			image({
				src: assetUrl(`images/adventure/7-mvp/mvp-2.png`),
				caption: `Activity page`,
			}),
			image({
				src: assetUrl(`images/adventure/7-mvp/mvp-3.png`),
				caption: `Itinerary`,
			}),		
		),
		paragraph(`
			The most critical flows that we wanted to nail were the first time onboarding experience, the activity search, and creating an itinerary. 
			These were flows that none of our competitors successfully achieved so we went through several different iterations. 
			The prototypes below show what we ended up going with.
		`),
		fullWidthFigureGroup(
			video({
				chromeSrc: assetUrl(`images/adventure/7-mvp/small-phone-placeholder.png`),
				chromePadding: IPHONE_CHROME_PADDING,
				src: assetUrl(`images/adventure/7-mvp/ftue-c.mp4`),
				playButton: true,
				caption: `First time user experience`,
			}),
			video({
				chromeSrc: assetUrl(`images/adventure/7-mvp/small-phone-placeholder.png`),
				chromePadding: IPHONE_CHROME_PADDING,
				src: assetUrl(`images/adventure/7-mvp/add-activities-c.mp4`),
				caption: `Adding activities`,
				playButton: true,
			}),
			video({
				chromeSrc: assetUrl(`images/adventure/7-mvp/small-phone-placeholder.png`),
				chromePadding: IPHONE_CHROME_PADDING,
				src: assetUrl(`images/adventure/7-mvp/add-to-itinerary-c.mp4`),
				playButton: true,
				caption: `Itinerary`,
			}),
		),		
		paragraph(`
			A massive benefit of taking on a side project is that I can determine how much time and resources went into every step. 
			In the corporate world, you often don’t have the time to test prototypes with users. 
			For this project, we spent two weeks testing with users, and we were able to perfect the flows.
		`),
	]),
	section(() => [
		overline(`Evaluate`),
		h1(`Results`),
		paragraph(`
			At the end of three months, I successfully created an application guideline that I was ready to handoff to engineers. 
			I presented the project at a conference with over 50 people, and it was very well received. 
			Almost everyone has a story when it comes to planning a trip and would like the planning experience to be more enjoyable.
		`),
		bigQuote(`\u201C This is perfect! I’m always the one in my group to plan entire trips, and I never know where to start! \u201D`),
	]),
	section(() => [
		h1(`Next step`),
		paragraph(`
			The MVP was received very well, and the next step is building the product. 
			I can see this project launching in two different ways. 
			The first is building from scratch, and the second is sharing the idea with a larger company. 
			Pinterest, who already has the framework in place, could add this to the product and have a trip planning division.
		`),
		image({
			src: assetUrl(`images/adventure/8-next-step/10-adventure-mockup.jpg`),
			caption: `The finished product`,
			dropshadow: true,
		}),
		paragraph(`
			It’s not every day that you get to start a project from scratch and share your ideas with the general public. 
			This was a vast learning experience and extremely rewarding. 
			The entire project proved the importance of research and that you should always be testing.
		`),						
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/adventure/9-gallery/1-loading-screen.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/2-search-screen.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/3-details-screen.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/4-itinerary-screen.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/5-wireframes1.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/6-wireframes2.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/7-wireframes3.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/8-wireframes4.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/9-plane-mockup.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/adventure/9-gallery/10-adventure-mockup.jpg`),
					caption: `blah blah.`,
				},											
			],
		}),
	]),
);		
