import * as React from 'react';
import classNames from 'classnames';

// TODO(taylorm) break this out to a shared types
// this is awkward as is
import {
	ImageTypes,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';

import {
	LightboxContext,
	addImageActionPayload,
	removeImageActionPayload,
	openLightboxActionPayload,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_context_manager';

import {ExpandIcon} from 'hannah/src/app/frontend/components/layout/expand_icon/expand_icon';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/page_image/page_image.css';
import {Caption2} from 'hannah/src/app/frontend/typography';
import {ChromeUnderlay} from 'hannah/src/app/frontend/components/layout/chrome_underlay/chrome_underlay';

import {IS_MOBILE_OR_TABLET} from 'hannah/src/app/frontend/environment_utils';

export interface PageImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	src: string;
	caption?: string;
	marginTop?: boolean;
	marginBottom?: boolean;
	sideMargins?: boolean;
	dropshadow?: boolean;
	maxWidth?: number;
	hoverOverlaySrc?: string;
	grayBackground?: boolean;
	chromeSrc?: string;
	chromePadding?: string;
}

// I don't really understand the HTMLAttributes typings...
export const PageImage = (props: PageImageProps) => {

	const [isHovered, setIsHovered] = React.useState(false);

	const {
		caption,
		marginTop,
		marginBottom,
		sideMargins = false,
		dropshadow = false,
		maxWidth,
		hoverOverlaySrc,
		src,
		grayBackground = false,
		chromeSrc,
		chromePadding = '',
		...rest,
	} = props;

  	const {dispatch} = React.useContext(LightboxContext);

	React.useEffect(() => {
		if (dispatch) {
	  		dispatch(addImageActionPayload(src, ImageTypes.IMAGE, src, caption || ''));
		}
		return () => {
			if (dispatch) {
		  		dispatch(removeImageActionPayload(src));
			}
		}
	}, []);

	const handleTouchStartImage = () => {
		if (IS_MOBILE_OR_TABLET) {
			setIsHovered(true);
		}
	}

	const handleTouchEndImage = () => {
		if (IS_MOBILE_OR_TABLET) {
			setIsHovered(false);
		}
	}	

	const handleMouseEnterImage = () => {
		if (!IS_MOBILE_OR_TABLET) {
			setIsHovered(true);
		}
	};

	const handleMouseLeaveImage = (event: any) => {
		if (!IS_MOBILE_OR_TABLET) {
			setIsHovered(false);
		}
	};

	const handleClickImage = () => {
		if (dispatch) {
	  		dispatch(openLightboxActionPayload(src));
		}
	};

	const cx = classNames({
		[classes.container]: true,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
		[mediaSelectorClasses.content_side_margins]: sideMargins,
	});

	const imageContainerCx = classNames({
		[classes.image_container]: true,
		[classes.image_container_gray_background]: grayBackground,
	});

	const imageCx = classNames({
		[mediaSelectorClasses.content_width_max_wide]: true,
		[classes.image]: true,
		[classes.image_dropshadow]: dropshadow,
	});

	const imageStyles = {
		maxWidth,
	};

	const hoverOverlayStyles = {
		backgroundImage: `url(${hoverOverlaySrc})`,
	};

	const imageInWrapper = (
		<div
			onTouchStart={handleTouchStartImage}
			onMouseEnter={handleMouseEnterImage}
			onTouchEnd={handleTouchEndImage}
			onMouseLeave={handleMouseLeaveImage}
			className={imageContainerCx}>
			{
				isHovered && (
					<ExpandIcon onClick={handleClickImage} />
				)
			}
			{
				isHovered && hoverOverlaySrc && (
					<div
						className={classes.hover_overlay}
						style={hoverOverlayStyles}
					/>
				)
			}
			<img
				src={src}
				style={imageStyles}
				alt={caption}
				className={imageCx}
				{...rest}
			/>
		</div>
	);

	return (
		<figure className={cx}>
			{
				chromeSrc ? (
					<ChromeUnderlay src={chromeSrc} padding={chromePadding}>
						{imageInWrapper}
					</ChromeUnderlay>
				) : imageInWrapper
			}
			{
				caption && (
					<figcaption>
						<Caption2>{caption}</Caption2>
					</figcaption>
				)
			}
		</figure>
	);
};

