import {assetUrl} from 'hannah/src/app/frontend/static_utils';
import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';
//import {ThreePaneImageCaptionStyles} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';

import {
	fullWidthVideo,
	carousel,
	// coloredDotLegend,
	// compareImage,
	calloutsImage,
	crossfadingImages,
	h1,
	h2,
	image,
	lightboxThumbnails,
	orderedListCards,
	overline,
	link,
	page,
	paragraph,
	projectIntroCard,
	projectPageHeader,
	bigQuote,
	section,
	//threePaneImage,
	//video,
	fullWidthImage,
	//fullWidthFigureGroup,
	nonStackingFigureGroup,

} from 'hannah/src/app/frontend/page_config';

export default page(
	projectPageHeader({
		heading: `Data visualizations`,
		subhead: `Visualizing the trillion dollar commercial real estate industry with beautiful, digestible data.`,
	}),
	section(() => [
		overline(`Intro`),
		projectIntroCard({
			descriptionHeading: `The challenge`,
			descriptionParagraph: `
				Create a data visualization framework that supports bar charts, column charts, and pie charts. 
				This framework must work across all deal pages and be scalable and responsive.
			`,
			roleHeading: `My role`,
			roleParagraph: `
				Lead designer owning all user research, product strategy, UX, and visual design.
			`,
			timeframeHeading: `Timeframe`,
			timeframeParagraph: `January 2019 - June 2019`,
		}),
		paragraph(
			`
				TLDR; Dealpath is heavily used for reporting on commercial real estate investments and often users want to compare data across or within deals. 
				Most customers moved to Dealpath from Excel or Google, which does allow you to visualize your data. 
				Our competition already had data visualization, so we knew we needed this to stay competitive in the market. 
				To make this happen, we first needed to update the global filtering (see the case study `,
				link({
					text: `here`,
					to: '/global-filtering',
				}),
				`) and after we needed to define a grid for each page so that the data visualization would scale nicely (see the case study `,
				link({
					text: `here`,
					to: '/guiding-the-grid',
				}),			
				`). Since we only had our limited default color palette, we needed to create a new data visualization color palette and then define the chart styles for both deal info and reporting. 
				Everything pulled together nicely over six months, and we now have a cohesive data visualization framework.
			`,
		),
	]),
	section(() => [
		overline(`Discover`),
		h1(`Problem`),
		paragraph(`
				To start on this project, there was a lot of groundwork that needed to be done. 
				We didn’t have data visualization anywhere on the product which meant we needed to set up a grid, define a color palette, choose a charts library, and implement across several key product pages.
			`,
		),
		carousel({
			aspectRatioWidth: 717,
			aspectRatioHeight: 416,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/2-problem/carousel/problems-deal-info.png`),
					captionHeader: 'Non-visual tables',
					caption: `ATables are great for some use cases, but we needed a way to visualize this data.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/2-problem/carousel/problems-reporting.png`),
					captionHeader: 'Limited reporting',
					caption: `It was hard to compare deals and properties with our existing reporting page.`,
				},				
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/2-problem/carousel/scaling-issue-c.gif`),
					captionHeader: 'No grid in place',
					caption: `Our pages weren’t on a grid which meant scaling often would not work as expected.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/2-problem/carousel/problems-colors.png`),
					captionHeader: 'Limited color palette',
					caption: `We only had our default color palette, which wouldn’t work for data visualization.`,
				},
			]
		}),
		h2(`Problem areas`),
		orderedListCards({
			items: [
				{
					topic: `1. Grid system:`,
					details: `
						Our pages weren’t on a grid system which meant components didn’t play nicely together. 
						For charts to line up, we needed to place everything on a grid.
					`
				},
				{
					topic: `2. Color palette:`,
					details: `
						We only had our limited color palette, which doesn’t work well for data visualization. 
						We needed to define our new extensive color palette while remaining on-brand.
					`
				},
				{
					topic: `3. Deal info:`,
					details: `
						Customers wanted to compare data within deals, and this could only be done using tables.
					`
				},
				{
					topic: `4. Reporting:`,
					details: `
						Reporting should be robust and the main selling feature of Dealpath. 
						Instead, it was hard to compare data and lacked the data visualization component that many users requested.
					`
				},				
			]
		}),
	]),
	section(() => [
		h1(`Research`),
		h2(`Customer feedback`),
		paragraph(`
			Customers were used to having data visualizations, so when we told them we didn’t have this functionality yet it was often a deal-breaker. 
			The customer success team was always receiving complaints and requests, so we knew this was a top priority feature.
		`),	
		bigQuote(`\u201C Is there a way to compare data visually? \u201D`),
		h2(`Main use cases`),
		paragraph(`
			The main pages that would benefit from having data visualization were the deal info page, the reporting page, and the deal dashboard. 
			These were our top visited pages and had the most extensive data.
		`),	
		orderedListCards({
			items: [
				{
					topic: `Deal info:`,
					details: `
						This is the page within a deal where all deal information is displayed.
					`
				},
				{
					topic: `Reporting:`,
					details: `
						This is a global level page that compares all deals and properties in an Excel-like format.
					`
				},
			]
		}),			
		h2(`User research`),
		paragraph(`
			Since this was such a highly requested feature, we knew we needed to do extensive user research to make sure we built the correct framework. 
			There are two main types of users on Dealpath: analysts and executives. 
			Analysts do most of the data entry, whereas the executives go into the platform a couple of times a week. 
			Three core companies on Dealpath are L+M, Capital Pacific, and AEW. 
			I wanted to interview at least one person from each of these companies.
		`),
		nonStackingFigureGroup(
			image({
				src: assetUrl(`images/data-visualization/3-research/bubble_group_1.png`),
				caption: `Customer roles`,
			}),
			image({
				src: assetUrl(`images/data-visualization/3-research/bubble_group_2.png`),
				caption: `Companies I talked to`,
			}),		
		),				
		paragraph(`
			The below image is a screenshot of a typical deal info page. 
			There are usually tables that are comparing the proposed and actual costs. 
			Every user that I interviewed requested for there to be a way to visualize this instead of having to look across the table.
		`),
		image({
			src: assetUrl(`images/data-visualization/3-research/comparing-sections.png`),
			caption: `Typical deal info page with tables`,
			dropshadow: true,
		}),
		paragraph(`
			The deal info page is the most viewed deal page on Dealpath. 
			Represented by the dark blue line below, you can see that this page is consistently visited daily.
		`),
		image({
			src: assetUrl(`images/data-visualization/3-research/metrics-page-views.png`),
			caption: `Most viewed deal pages`,
			dropshadow: true,
		}),
		h2(`Competitive analysis`),
		paragraph(`
			Data visualization can be done in a number of ways, so it was essential to look at competitors and similar products to see what would work best for our product.
		`),
		carousel({
			aspectRatioWidth: 726,
			aspectRatioHeight: 551,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/3-research/carousel/competitive-salesforce.jpg`),
					captionHeader: 'Salesforce',
					caption: `This is a very similar product that is catered to sales teams instead of commercial real estate.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/3-research/carousel/competitive-mixpanel.jpg`),
					captionHeader: 'Mixpanel',
					caption: `This is the first thing that comes to mind when I hear “data visualization.” 
					It is used to track product metrics.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/3-research/carousel/competitive-dealcloud.jpg`),
					captionHeader: 'Deal Cloud',
					caption: `A direct competitor that already had data visualization implemented.`,
				},
			]
		}),
		h2(`Research synthesis`),
		paragraph(`
			After reviewing all data from the research, I broke everything down into a research insights and design guideline list.
		`),
		nonStackingFigureGroup(
			image({
				src: assetUrl(`images/data-visualization/3-research/research-insights-placeholder.jpg`),
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/data-visualization/3-research/design-guidelines-placeholder.jpg`),
				dropshadow: true,
			}),		
		),				
	]),
	section(() => [
		h1(`Hypothesis`),
		paragraph(`
			Creating a cohesive data visualization framework that is based on a grid system and is highly customizable will allow users to compare data and make better informed commercial real estate decisions.
		`
		),		
		paragraph(`
			We believe we’ll solve users problems by building a framework that has the following:
		`),
		nonStackingFigureGroup(
			image({
				src: assetUrl(`images/data-visualization/4-hypothesis/graphic-grid.png`),
				caption: `Grid-based format`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/data-visualization/4-hypothesis/graphic-familiarity.png`),
				caption: `Familiarity`,
				dropshadow: true,
			}),		
			image({
				src: assetUrl(`images/data-visualization/4-hypothesis/graphic-customization.png`),
				caption: `Customization`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/data-visualization/4-hypothesis/graphic-sharing.png`),
				caption: `Sharing capabilities`,
				dropshadow: true,
			}),				
		),					
	]),
	section(() => [
		overline(`Define`),
		h1(`Project goals`),
		paragraph(`
			There were a few key goals in mind when starting this project:
		`),
		orderedListCards({
			items: [
				{
					topic: `Goal 1:`,
					details: `
						Increase retention by improving ROI for customers of Dealpath. (Reduce Customer Churn Rate by 10%)
					`
				},
				{
					topic: `Goal 2:`,
					details: `
						Drive up-sell by adding additional value for users. (Increase Average Contract Value by 15%)
					`
				},
				{
					topic: `Goal 3:`,
					details: `
						Increase engagement in Dealpath. (Increase MAU by 5%)
					`
				},
				{
					topic: `Goal 4:`,
					details: `
						Create a re-usable aesthetic for all charts in Dealpath. (Implement 1 framework that works for all pages)
					`
				},				
			]
		}),
		paragraph(`
			As the only designer on this project, I knew that I needed to figure out a roadmap to integrate this across all pages without disrupting the users. 
			This meant I needed to break the project into steps: define the grid, determine engineering framework, create color palettes, implement on deal info, implement on reporting.
		`),
		paragraph(`
			Defining the grid and choosing a framework were precursors for this project. 
			This was a massive task in itself, see the case study `,
			link({
				text: `here`,
				to: `guiding-the-grid`,
			}),
			`.`,
		),
	]),
	section(() => [
		overline(`Ideate`),
		h1(`Color palette`),
		h2(`Existing colors`),
		paragraph(`
			Dealpath consisted of only six colors which all had a particular purpose. 
			This meant that the data visualization needed a separate palette that pairs nicely with our existing colors.
		`),
		image({
			src: assetUrl(`images/data-visualization/5-color-palette/existing-color-palette.png`),
			caption: `Existing Dealpath color palette`,
			dropshadow: true,
		}),
		paragraph(`
			For data visualization, I knew that we needed at least five different color palettes because every company has a specific look and they wanted to match a palette to their brand.
		`),
		h2(`Research`),
		paragraph(`
			For inspiration and guidance, I looked to a few companies that have successful data visualization.
		`),		
		carousel({
			aspectRatioWidth: 635,
			aspectRatioHeight: 368,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/5-color-palette/carousel/research-zendesk.jpg`),
					captionHeader: 'Zendesk',
					caption: `This palette consists of seven colors and has more warm tones and a playful vibe.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/5-color-palette/carousel/research-quickbooks.png`),
					captionHeader: 'Quickbooks',
					caption: `This palette has fourteen colors and has very vibrant colors and starts with cool tone colors.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/5-color-palette/carousel/research-material.png`),
					captionHeader: 'Google',
					caption: `Google typically uses only one color for their charts. The palette is cool-toned.`,
				},
								{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/5-color-palette/carousel/research-mixpanel.png`),
					captionHeader: 'Mixpanel',
					caption: `The Mixpanel palette is playful and has only six colors. The palette starts with cool tone colors.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/data-visualization/5-color-palette/carousel/research-salesforce.png`),
					captionHeader: 'Salesforce',
					caption: `Salesforce has a handful of palettes, but their default palette has six colors and starts with a cool tone.`,
				},
			]
		}),
		paragraph(`
			The color palettes should be on-brand and meet several criteria:
		`),	
		orderedListCards({
			items: [
				{
					topic: `Print in grayscale: `,
					details: `
						Many users print reports to share in meetings. 
						Colored ink isn’t as common, so the charts need to have enough contrast in grayscale.
					`
				},
				{
					topic: `Repeatable: `,
					details: `
						Charts can be indefinitely long, which means that the palette needs to repeat the colors.
					`
				},
				{
					topic: `On brand: `,
					details: `
						The new palettes must work nicely with our existing colors.
					`
				},
				{
					topic: `Accessible: `,
					details: `
						4.5% of the total population is color blind, and even more have a visual impairment. 
						We needed to have at least one palette for these users.
					`
				},				
			]
		}),
		paragraph(`
			After analyzing the competitive examples, I concluded that we should go with six colors that repeat. 
			Both Mixpanel and Salesforce follow this, and they are the most similar to what we were building.
		`),
		paragraph(`
			Like Mixpanel, Salesforce, and Google, we wanted to start our color palettes primarily with cool tones. 
			Our existing palette followed this and most of Dealpath was based around cool tones.
		`),		
		image({
			src: assetUrl(`images/data-visualization/5-color-palette/primary-colors.png`),
			caption: `Primary colors on Dealpath`,
			dropshadow: true,
			maxWidth: 540,
		}),
		paragraph(`
			Since we wanted each palette to be understandable when printed in grayscale, I needed to make sure all colors had enough contrast. 
			An excellent way to test this was to use a tool called RGBlind. 
			This allowed me to not only see the palettes in grayscale, but also the various types of colorblindness.
		`),
		nonStackingFigureGroup(
			image({
				src: assetUrl(`images/data-visualization/5-color-palette/colors.jpg`),
				caption: `Palettes in color`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/data-visualization/5-color-palette/black-and-white.jpg`),
				caption: `Palettes in grayscale`,
				dropshadow: true,									
			}),
		),
		h2(`Voting`),
		paragraph(`
			Color can be a very subjective thing, and to make sure the entire company was on board with the palettes, I printed out the top contenders and displayed them on a giant eight-foot-tall foam core board. 
			I passed out colored dots to every person so that everyone could vote on their top three favorites.
		`),
		image({
			src: assetUrl(`images/data-visualization/5-color-palette/board-cool.jpg`),
			caption: `Pin-up board for office voting`,
			dropshadow: true,
			maxWidth: 537,
		}),
		paragraph(`
			The top ranked palettes, shown below, helped decide our default color palette and the entire company felt like they had ownership in selecting this.
		`),
		crossfadingImages({
			imageSrcs: [
				assetUrl(`images/data-visualization/5-color-palette/option-1.png`),
				assetUrl(`images/data-visualization/5-color-palette/option-2.png`),
				assetUrl(`images/data-visualization/5-color-palette/option-3.png`)
			],
			intervalMS: 3000,
			caption: `Winning default color palette`,
			aspectRatioExpression: `1096 / 1450`,
			dropshadow: true,
		}),
		paragraph(`
			Encouraging the team to participate in this selection turned out to be a success because it got everyone excited for the upcoming project. 
			A previous designer had started on this color palette process, but because he didn’t have the buy-in from the company, he faced too many roadblocks with starting.
		`),		
		h2(`Admin page`),
		paragraph(`
			Below are the nine palettes that were chosen. 
			Each starts with a cool tone color but has a very different audience. 
			The administrators at each company can decide what the default palette is, but it is also customizable at a user-level too.
		`),
		fullWidthImage({
			src: assetUrl(`images/data-visualization/5-color-palette/settings-colors.png`),
			caption: `Team customization admin page`,
			dropshadow: true,
			maxWidth: 1100,
		}),
	]),
	section(() => [
		h1(`Deal info charts`),
		h2(`Research`),
		paragraph(`
			After the color palettes were set, it was time to start on the actual charts. 
			The first charts that I wanted to tackle were the deal info charts. 
			Since users were already familiar with the chart set up in Google, I wanted to follow a similar UX.
		`),
		image({
			src: assetUrl(`images/data-visualization/6-deal-info-charts/google-charts.png`),
			caption: `Google spreadsheet chart example`,
			dropshadow: true,
		}),
		paragraph(`
			The most common chart types are bar, column, and pie so we wanted to stick with these for our first version.
		`),
		nonStackingFigureGroup(
			image({
				src: assetUrl(`images/data-visualization/6-deal-info-charts/graphic-bar.png`),
				caption: `Column chart`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/data-visualization/6-deal-info-charts/graphic-column.png`),
				caption: `Bar chart`,
				dropshadow: true,									
			}),
			image({
				src: assetUrl(`images/data-visualization/6-deal-info-charts/graphic-donut.png`),
				caption: `Pie chart`,
				dropshadow: true,									
			}),			
		),
		h2(`Template editor`),
		paragraph(`
			The deal info page is based on templates determined at the admin level. 
			This means that charts would also need to be added by administrators within the template. 
			Below is the final design for the charts template editor. 
			It is designed to mimic the UI of a print preview form.
		`),
		image({
			src: assetUrl(`images/data-visualization/6-deal-info-charts/add-chart-modal.png`),
			caption: `Charts template editor`,
			dropshadow: true,
		}),
		paragraph(`
			Below is the flow showing how a user adds a chart to a template.
		`),
		fullWidthVideo({
			src: assetUrl(`images/data-visualization/6-deal-info-charts/template-editor-c.mp4`),
			caption: `Add chart flow on deal template`,
			dropshadow: true,
		}),
		h2(`Adding values`),
		paragraph(`
			Since charts rely on values to be useful, adding values needed to be simple. 
			On the deal info page, the user just clicks to edit a field, and it flips over to reveal the updated chart.
		`),
		fullWidthVideo({
			src: assetUrl(`images/data-visualization/6-deal-info-charts/edit-chart-c.mp4`),
			caption: `Adding values to charts`,
			dropshadow: true,
		}),
		h2(`Final result`),
		paragraph(`
			The finished design below shows how a deal info page looks when multiple chart types are used. 
			The charts were designed so that they are all the same height and padding. 
			This way, they look elegant in a printed report.
		`),
		fullWidthImage({
			src: assetUrl(`images/data-visualization/6-deal-info-charts/final-deal-info-page.png`),
			caption: `Final deal info page with charts`,
			dropshadow: true,
			maxWidth: 1100,
		}),
	]),
	section(() => [
		h1(`Reporting charts`),
		h2(`Research`),
		paragraph(`
			Reporting charts were the last final challenge for the data visualization project. 
			I wanted the charts to display simultaneously with the reporting data, so I went through several different iterations to determine the placement. 
			I settled on placing the charts across the top of the page.
		`),
		nonStackingFigureGroup(
			image({
				src: assetUrl(`images/data-visualization/7-reporting-charts/configuration-top.png`),
				caption: `Top configuration`,
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/data-visualization/7-reporting-charts/configuration-top-separate.png`),
				caption: `Separate top configuration`,
				dropshadow: true,									
			}),
			image({
				src: assetUrl(`images/data-visualization/7-reporting-charts/configuration-right.png`),
				caption: `Right configuration`,
				dropshadow: true,									
			}),	
			image({
				src: assetUrl(`images/data-visualization/7-reporting-charts/configuration-left.png`),
				caption: `Left chart`,
				dropshadow: true,									
			}),						
		),		
		h2(`Defining the charts`),
		paragraph(`
			The main components of the reporting charts are a pie chart (or donut), a column chart, and an interactive legend. 
			For visual appeal and symmetry, I decided to put the pie chart and legend on the outside with a responsive column chart in the middle.
		`),
		fullWidthImage({
			src: assetUrl(`images/data-visualization/7-reporting-charts/chart-dimensions.png`),
			caption: `Reporting chart layout`,
			dropshadow: true,
			maxWidth: 1100,
		}),
		h2(`Chart settings`),
		paragraph(`
			To customize the charts, I designed a dropdown which controlled all settings for the column and pie chart.
		`),
		paragraph(`
			Hover over the numbers below to learn more about the settings dropdown.
		`),		
		calloutsImage({
			src: assetUrl(`images/data-visualization/7-reporting-charts/settings-no-bubbles.png`),
			caption: `Chart settings dropdown`,
			maxWidth: 400,
			items: [
				{
					targetText: `1`,
					description: `
						The Y-Axis default is always deal count. 
						This is the number of deals you have in each column.
					`,
					position: {
						top: `22.5%`,
						left: `3%`,
					},
				},
				{
					targetText: `2`,
					description: `
						The value in the pie chart is set to have the deal count as the default. 
						Often users update this to be purchase price or a different value to compare to the column chart.
					`,
					position: {
						right: `89.5%`,
						bottom: `67%`,
					},
				},
								{
					targetText: `3`,
					description: `
						The color palette dropdown shows all of the color palettes so that the user can customize their charts.
					`,
					position: {
						top: `77.5%`,
						left: `3.5%`,
					},
				},
				{
					targetText: `4`,
					description: `
						Show values displays the numeric values above each column.
					`,
					position: {
						right: `89%`,
						bottom: `7.5%`,
					},
				},
			],
		}),		
		h2(`Interaction`),
		paragraph(`
			Now that we had the charts and settings in place, the final step was to add the flourishes to make it feel great to use.
		`),
		carousel({
			aspectRatioWidth: 734,
			aspectRatioHeight: 291,
			items: [
				{
					type: CarouselItemType.VIDEO,
					url: assetUrl(`images/data-visualization/7-reporting-charts/carousel/legend-interaction-c.mp4`),
					captionHeader: 'Interactive legend',
					caption: `The legend on the right not only shows what is selected but the user can toggle fields on or off and it updates in real-time.`,
				},				
				{
					type: CarouselItemType.VIDEO,
					url: assetUrl(`images/data-visualization/7-reporting-charts/carousel/responsive-c.mp4`),
					captionHeader: 'Responsive charts',
					caption: `Users have screens of all sizes, so I designed the charts to be fully responsive.`,
				},
				{
					type: CarouselItemType.VIDEO,
					url: assetUrl(`images/data-visualization/7-reporting-charts/carousel/scrolling-interaction-c.mp4`),
					captionHeader: 'Scrolling interaction',
					caption: `Since the chart takes up a lot of the screen, it is important to hide it under the navigation bar when scrolling.`,
				},
				{
					type: CarouselItemType.VIDEO,
					url: assetUrl(`images/data-visualization/7-reporting-charts/carousel/tooltip-interaction-c.mp4`),
					captionHeader: 'Informational tooltip ',
					caption: `This custom tooltip makes it clear which section you are hovering over and what the values are.`,
				},
				/* all should have shadow */
			]
		}),
		h2(`Final result`),
		paragraph(`
			Below is the finished design showing how users toggle and change the settings on the reporting page. 
			These views can easily be saved and shared with team members.
		`),
		fullWidthVideo({
			src: assetUrl(`images/data-visualization/7-reporting-charts/final-reporting-movie-c.mp4`),
			caption: `Final reporting page with charts`,
			dropshadow: true,
			maxWidth: 1100
		}),
	]),		
	section(() => [
		overline(`Evaluate`),
		h1(`Results`),
		paragraph(`
			This was a massive launch and we were able to ship and test features along the way. 
			Users now have a way to visualize their data and many have written in with positive feedback. 
			Although this was a challenging project we knew we needed to accomplish it to stay competitive in the market. We now have the most sophisticated chart system and have gained customers because of this.
		`),
		bigQuote(`\u201C We use Dealpath every day and couldn’t function without it! \u201D`),		
		image({
			src: assetUrl(`images/data-visualization/8-results/metric-numbers.png`),
		}),
	]),		
	section(() => [
		h1(`Next step`),
		paragraph(`
			The first step of the data visualization project was to get basic charts on the deal info and reporting pages. 
			The next step is to do different chart types. 
			Time-based charts and double charts are highly requested, and I knew they would be. 
			Luckily the architecture and designs were built with this in mind so we can easily add to the charts and continue to make them even more robust.
		`),
		fullWidthImage({
			src: assetUrl(`images/data-visualization/9-next-step/double-charts.png`),
			caption: `Reporting page double charts`,
			dropshadow: true,
			maxWidth: 1100,
		}),
		paragraph(`
			Charts were our most requested feature for several years and there were many steps needed to make it a reality. 
			Starting with the configure and filter project (see the case study `,
				link({
					text: `here`,
					to: '/global-filtering',
				}),
				`) and then the grid system (see the case study `,
				link({
					text: `here`,
					to: '/guiding-the-grid',
				}),			
				`), we were finally able to implement charts. 
				This project not only advanced me as a designer, but it added monetary value to our product. 
				We are now outcompeting our competition!
			`,
		),
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-deal-info.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-tooltips.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-template.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-modal.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-dimensions.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-legend.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-matrix.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-view-all-modal.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-column-hover.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-pie-hover.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-chart-settings.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/data-visualization/10-gallery/gallery-export.png`),
					caption: `blah blah.`,
				}							
			],
		}),
	]),
);		
