import * as React from 'react';
import {enableBodyScroll, disableBodyScroll} from 'body-scroll-lock';
import ReactModal from 'react-modal';

import {
	ImageItemMeta,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';
import {
	LightboxCarousel,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_carousel';

import * as classes from 'hannah/src/app/frontend/components/lightbox/lightbox.css';

export interface LightboxProps {
	images: ImageItemMeta[];
	activeImage: ImageItemMeta;
	onClose: () => void;
	onClickCurrentImage: () => void;
	onClickPrevButton: () => void;
	onClickNextButton: () => void;
}

export class Lightbox extends React.Component<LightboxProps> {

	componentDidMount() {
		disableBodyScroll(document.body);
	}

	componentWillUnmount() {
		enableBodyScroll(document.body);
	}

	render() {
		const {
			images,
			activeImage,
			onClose,
			onClickCurrentImage,
			onClickPrevButton,
			onClickNextButton,
		} = this.props;

		return (
	    <ReactModal
	    	appElement={document.body}
	    	style={{
	    		// TODO move these into CSS
	    		content: {
	    			position: 'relative',
	    			top: 0,
	    			right: 0,
	    			bottom: 0,
	    			left: 0,
				    border: 'none',
				    background: 'none',
				    overflow: 'visible',
				    borderRadius: 0,
				    outline: 'none',
				    display: 'flex',
				    padding: 0,
				    flex: 1,
				    pointerEvents: 'none',
				    //alignItems: 'center', // weird bug with videos causes them to ignore parent height and padding
				    justifyContent: 'center',
	    		},
	    		overlay: {
				    position: 'fixed',
				    display: 'flex',
				    top: 0,
				    left: 0,
				    right: 0,
				    bottom: 0,
				    backgroundColor: '#fff',
				    zIndex: 102,
				    padding: 20,
	    		},
	    	}}
	      isOpen={true}
	      onRequestClose={onClose}
	      shouldCloseOnOverlayClick={true}
	      contentLabel='Lightbox'
	    >
    		<React.Fragment>
			   	<button
		        onClick={onClose}
		        className={classes.close_button}>
		        <span className={classes.close_button_symbol}>
		        	&#xd7;
		        </span>
		      </button>
		    	<LightboxCarousel
		    		images={images}
		    		activeImage={activeImage}
		    		onClickCurrentImage={onClickCurrentImage}
		    		onClickPrevButton={onClickPrevButton}
		    		onClickNextButton={onClickNextButton}
		    	/>
	    	</React.Fragment>
	    </ReactModal>
		);	
	}
};
