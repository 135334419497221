import * as React from 'react';

import {
	PageHeading,
	PageSubhead,
} from 'hannah/src/app/frontend/typography';

import * as classes from 'hannah/src/app/frontend/components/layout/page_header/page_header.css';

export interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
	heading: string;
	subhead: string;
}

const _PageHeader = (props: PageHeaderProps) => {
	const {heading, subhead} = props;
	return (
		<header className={classes.container}>
			<PageHeading>{heading}</PageHeading>
			<PageSubhead>{subhead}</PageSubhead>
		</header>
	);
};

export const PageHeader = React.memo(_PageHeader);
