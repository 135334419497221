import * as React from 'react';

import {
	ImageTypes,
	ImageItemMeta,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';
import {Lightbox} from 'hannah/src/app/frontend/components/lightbox/lightbox';

export const LightboxContext = React.createContext({} as any);

export enum LightboxActionTypes {
	ADD_IMAGE,
	REMOVE_IMAGE,
	OPEN_LIGHTBOX,
	CLOSE_LIGHTBOX,
	ADVANCE_IMAGE,
}

interface LightboxAction {
	data?: any;
	type: LightboxActionTypes;
}

interface LightboxReducerState {
	lightboxIsOpen: boolean;
	images: ImageItemMeta[];
	activeImageId?: string;
}

const initialState: LightboxReducerState = {
	lightboxIsOpen: false,
	images: [],
	activeImageId: undefined,
};

export function addImageActionPayload(id: string, type: ImageTypes, src: string, caption: string) {
	return {
		type: LightboxActionTypes.ADD_IMAGE,
		data: {
			image: {
				id: id,
				type: type,
				url: src,
				caption: caption,
			},
		},
	};
}

export function removeImageActionPayload(id: string) {
	return {
		type: LightboxActionTypes.REMOVE_IMAGE,
		data: {
			image: {
				id: id,
			},
		},
	};
}

export function openLightboxActionPayload(id: string) {
	return {
		type: LightboxActionTypes.OPEN_LIGHTBOX,
		data: {
			image: {
				id: id,
			},
		},
	};
}

export function closeLightboxActionPayload() {
	return {
		type: LightboxActionTypes.CLOSE_LIGHTBOX,
	};
}

export function advanceImageActionPayload(offset: number) {
	return {
		type: LightboxActionTypes.ADVANCE_IMAGE,
		data: {
			offset: offset,
		},
	};
}


function reducer(state: LightboxReducerState, action: LightboxAction) {
  	switch (action.type) {
	    case LightboxActionTypes.ADD_IMAGE: {
	    	const {images} = state;
	    	const {image} = action.data;
	    	// don't add dupes
	    	const foundImage = images.find(({id}: ImageItemMeta) => id === image.id);
	    	if (foundImage) {
	    		return state;
	    	} else {
				return {
					...state,
					images: [...images, image],
				};
	    	}
	    }
	    case LightboxActionTypes.REMOVE_IMAGE: {
	    	const {images} = state;
	    	const {image} = action.data;
	    	const foundImage = images.find(({id}: ImageItemMeta) => id === image.id);
	    	const idx = images.indexOf(foundImage!);
			return {
				...state,
				images: [...images].splice(idx, 1),
			};
	    }
	    case LightboxActionTypes.OPEN_LIGHTBOX: {
	    	const {
	    		image,
	    	} = action.data;
			return {
				...state,
				lightboxIsOpen: true,
				activeImageId: image.id,
			};
	    }
	    case LightboxActionTypes.CLOSE_LIGHTBOX: {
			return {
				...state,
				lightboxIsOpen: false,
				activeImageId: undefined,
			};
	    }
	    case LightboxActionTypes.ADVANCE_IMAGE: {
	    	const {
	    		activeImageId,
	    		images,
	    	} = state;
	    	const {
	    		offset,
	    	} = action.data;

		    const foundImage = images.find(({id}: ImageItemMeta) => id === activeImageId);
		    const activeImageIdx = images.indexOf(foundImage!);
		    const nextImage = images[activeImageIdx + offset];
		    if (nextImage) {
		    	return {
		    		...state,
		    		activeImageId: nextImage.id,
		    	};
		    } else {
		    	return state;
		    }
	    }
	    default: {
	     	return initialState;
	    }
  	}
}

interface LightboxContextManagerProps {
	children: React.ReactChild;
}

const _LightboxContextManager = (props: LightboxContextManagerProps) => {
	const {children} = props;
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const {
		images,
		lightboxIsOpen,
		activeImageId,
	} = state;

	let activeImage: ImageItemMeta | undefined;
	if (activeImageId) {
		activeImage = (images as any).find(({id}: ImageItemMeta) => id === activeImageId);
	}

	const handleCloseLightbox = () => {
		dispatch(closeLightboxActionPayload());
	};


	const handleClickCurrentImage = () => {
		dispatch(advanceImageActionPayload(1));
	};

	const handleClickPrevButton = () => {
		dispatch(advanceImageActionPayload(-1));
	};

	const handleClickNextButton = () => {
		dispatch(advanceImageActionPayload(1));
	};

	return (
		<React.Fragment>
			<LightboxContext.Provider value={{state, dispatch}}>
				{children}
			</LightboxContext.Provider>
			{
				lightboxIsOpen && activeImage && (
					<Lightbox
			    		images={images}
			    		activeImage={activeImage}
			    		onClose={handleCloseLightbox}
			    		onClickCurrentImage={handleClickCurrentImage}
			    		onClickPrevButton={handleClickPrevButton}
			    		onClickNextButton={handleClickNextButton}
					/>
				)
			}
		</React.Fragment>
	);
};


export const LightboxContextManager = React.memo(_LightboxContextManager);
