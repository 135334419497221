import * as React from 'react';
import classNames from 'classnames';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/figure_group/figure_group.css';

export interface FigureGroupProps {
	marginTop?: boolean;
	marginBottom?: boolean;
	maxWidth?: number;
	sideMargins?: boolean;
	children: JSX.Element[];
	stackOnMobile?: boolean;
}

const _FigureGroup = (props: FigureGroupProps) => {
	const {
		marginTop,
		marginBottom,
		maxWidth,
		sideMargins = false,
		stackOnMobile = false,
		children,
	} = props;

	const cx = classNames({
		[classes.outer_container]: true,
		[mediaSelectorClasses.content_side_margins]: sideMargins,
	});

	const innerContainerCx = classNames({
		[mediaSelectorClasses.figure_group_inner_container]: true,
		[mediaSelectorClasses.figure_group_inner_container_stack_on_tablet_mobile]: stackOnMobile,
		[mediaSelectorClasses.margin_top_spacing]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
		[mediaSelectorClasses.content_width_max_wide]: true,
	});

	const containerStyles = {
		maxWidth,
	};

	return (
		<div className={cx}>
			<div className={innerContainerCx} style={containerStyles}>
				{
					children.map((child, index) => {

						const cx = classNames({
							[mediaSelectorClasses.figure_group_image_container]: true,
							//[mediaSelectorClasses.figure_group_image_container_margin_right]: index !== children.length - 1,
						});

						return (
							<div key={index} className={cx}>
								{child}
							</div>
						);
					})
				}
			</div>
		</div>
	);
};

export const FigureGroup = React.memo(_FigureGroup);
