import classNames from 'classnames';
import * as React from 'react';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/figure_showcase/figure_showcase.css';

export interface FigureShowcaseProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactChild[];
}

const _FigureShowcase = (props: FigureShowcaseProps) => {
	const {children} = props;
	const cx = classNames(
		mediaSelectorClasses.padding_top_spacing,
		mediaSelectorClasses.padding_bottom_spacing,
		mediaSelectorClasses.padding_left_spacing,
		mediaSelectorClasses.padding_right_spacing,
		classes.container,
	);
	return (
		<div className={cx}>
			<div className={mediaSelectorClasses.content_width_max_wide}>
				{children.map((child, index) => {
					return (
						<div key={index} className={mediaSelectorClasses.figure_showcase_child_wrapper}>
							{child}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export const FigureShowcase = React.memo(_FigureShowcase);
