import {assetUrl} from 'hannah/src/app/frontend/static_utils';
import {CarouselItemType} from 'hannah/src/app/frontend/components/carousel/carousel';
//import {ThreePaneImageCaptionStyles} from 'hannah/src/app/frontend/components/layout/three_pane_image/three_pane_image';

import {
	carousel,
	// coloredDotLegend,
	// compareImage,
	h1,
	h2,
	image,
	lightboxThumbnails,
	link,
	orderedListCards,
	overline,
	page,
	paragraph,
	projectIntroCard,
	projectPageHeader,
	bigQuote,
	section,
	//threePaneImage,
	video,
	fullWidthImage,
	figureGroup,

} from 'hannah/src/app/frontend/page_config';

export default page(
	projectPageHeader({
		heading: `Guiding the grid`,
		subhead: `Solving all organization problems by implementing a much needed grid system.`,
	}),
	section(() => [
		overline(`Intro`),
		projectIntroCard({
			descriptionHeading: `The challenge`,
			descriptionParagraph: `
				Define and implement a grid for the deal info page that aligns all sections and panels.
			`,
			roleHeading: `My role`,
			roleParagraph: `
				Lead designer owning all user research, product strategy, UX, and visual design.
			`,
			timeframeHeading: `Timeframe`,
			timeframeParagraph: `December 2018 - January 2019`,
		}),
		paragraph(`
			TLDR; Dealpath had never used a grid system for our application, and instead, everything was based on padding and flex code. 
			This made it easy to design, but it meant elements often didn’t line up perfectly. 
			With the following data visualization project (see the case study `,
			link({
				text: `here`,
				to: `/data-visualizations`,
			}),
			`), we needed a way for the charts to line up correctly with the tables and sections. 
			The best way to do this was to define a grid and move everything over to this system. 
			This took a lot of math and code rewriting, but we found the perfect layout and now everything is aligned and responsive.
		`),
	]),
	section(() => [
		overline(`Discover`),
		h1(`Problem`),
		paragraph(`
			This project was started because of several occurring issues. 
			The alignment and padding were off on the deal info page, and the page was not responsive.
		`),
		carousel({
			aspectRatioWidth: 635,
			aspectRatioHeight: 368,
			items: [
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/grid/2-problem/carousel/problems-alignment.png`),
					captionHeader: 'Alignment issues',
					caption: `Most deal info pages looked like this where the sections didn’t perfectly line up.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/grid/2-problem/carousel/problems-section-types.png`),
					captionHeader: 'Different section types',
					caption: `In the template editor, we allowed users to select which panel and section type to place. 
					This meant there were many different variations, and nothing perfectly lined up.`,
				},
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/grid/2-problem/carousel/scaling-issue.gif`),
					captionHeader: 'Non-responsive',
					caption: `Since everything was using flex to fill the page, this meant text would overlap or break when the screen size changed.`,
				},					
				{
					type: CarouselItemType.IMAGE_WITH_DROPSHADOW,
					url: assetUrl(`images/grid/2-problem/carousel/problems-padding.png`),
					captionHeader: 'Incorrect padding',
					caption: `Padding between sections was too much, and padding on the outside was too little.`,
				},
			]
		}),
		h2(`Problem areas`),
		orderedListCards({
			items: [
				{
					topic: `1. No alignment: `,
					details: `
						Since the page was not based on a grid layout, the sections rarely aligned.
					`
				},
				{
					topic: `2. Scalability: `,
					details: `
						The deal info page was designed for larger screens, but many users use tablets or phones. The page was not usable at a smaller scale.
					`
				},
				{
					topic: `3. UI: `,
					details: `
						Padding and alignment made the page look unorganized and sections weren’t clearly separated.
					`
				},
			]
		}),
	]),
	section(() => [
		h1(`Research`),
		h2(`Competitive research`),		
		paragraph(`
			Many popular websites are grid-based, so there was no need to reinvent the wheel. 
			Google’s grid was the first that came to mind when I started my research. 
			I also wanted to look into how Salesforce lays out their site because it is a similar product.
		`),
		image({
			src: assetUrl(`images/grid/3-research/logo-google.png`),
			dropshadow: true,
			maxWidth: 350		
			}),	
		image({
			src: assetUrl(`images/grid/3-research/google-grid.png`),
			caption: 'Google material design',
			dropshadow: true,		
			}),					
		paragraph(`
			Google is based on a 12-column grid, which is relatively standard. 
			One page that resembles our template editor is the Google website builder. 
			This follows the 12-column grid and has each section snap to the columns.
		`),
		image({
			src: assetUrl(`images/grid/3-research/google-editor.png`),
			caption: `Google website builder`,
			dropshadow: true,
		}),
		paragraph(`
			Salesforce was the next grid to explore. 
			It also follows a 12-column grid and has extensive documentation in their design system.
		`),
		image({
			src: assetUrl(`images/grid/3-research/logo-salesforce.png`),
			dropshadow: true,
			maxWidth: 350		
			}),	
		image({
			src: assetUrl(`images/grid/3-research/salesforce-grid.png`),
			caption: 'Salesforce 12-column grid',
			dropshadow: true,		
			}),			
		paragraph(`
			The Salesforce grid works well for the template editor and they even show the users where the columns and padding are. 
			This makes it very clear how you can arrange your elements.
		`),
		image({
			src: assetUrl(`images/grid/3-research/salesforce-editor.png`),
			caption: `Salesforce template editor`,
			dropshadow: true,
		}),
		paragraph(`
			Although Salesforce and Google were sites I researched, there were several other resources explored to make sure we were following the best practices. 
			Typically grids come before designs, and in our case, we were going backward. 
			This meant we need to find a grid that would be the least disruptive to the user’s pages.
		`),
		h2(`User research`),		
		paragraph(`
			In order to make sure all pages line up correctly, I needed to do extensive user research. 
			Every team has a slightly different setup for their deal info pages and our grid needed to work for all variations. 
			I interviewed users who had complicated deal info pages to understand best what we should build.
		`),
		bigQuote(`\u201C Why are my sections not lining up? \u201D`),
		image({
			src: assetUrl(`images/grid/3-research/typical-layout.png`),
			caption: `Old layout without grid`,
			dropshadow: true,
		}),
		paragraph(`
			The screenshot above shows what the existing deal info page looked like for a typical user. 
			They had a mix of image sections as well as tables and text sections. 
			Below is what they were used to having in Excel and other platforms.
		`),
		image({
			src: assetUrl(`images/grid/3-research/user-data.png`),
			caption: `Example of a typical user spreadsheet before Dealpath`,
			dropshadow: true,
		}),
	]),
	section(() => [
		h1(`Hypothesis`),	
		paragraph(`
			Moving all components to a grid-based system on the deal info page will align all sections and prepare us better for the upcoming data visualization project.
		`),
	]),
	section(() => [
		overline(`Define`),
		h1(`Project goals`),
		paragraph(`
			There were a few key goals in mind when starting this project:
		`),
		orderedListCards({
			items: [
				{
					topic: `Goal 1:`,
					details: `
						Improve alignment on the deal info page by aligning all sections to a grid.
					`
				},
				{
					topic: `Goal 2:`,
					details: `
						All pages should be responsive and fit all screen sizes.
					`
				},
				{
					topic: `Goal 3:`,
					details: `
						Improve UI by adding borders around sections so that each component is better defined.
					`
				},
				{
					topic: `Goal 4:`,
					details: `
						Improve the template editor experience by updating the grid on the template editor to match the grid on the deal info page.
					`
				},
			]
		}),
		paragraph(`
			This project was the precursor to the huge upcoming data visualization project, so time was limited. 
			We knew this project had to be completed in only a month. 
			The goal was to not disrupt any customer data, while at the same time making everything aligned.
		`),
	]),
	section(() => [
		overline(`Ideate`),
		h1(`The grid`),
		h2('Iterations'),
		paragraph(`
			Luckily math is something I enjoy or else this project would have been a nightmare. 
			Our existing layout had precise ratios for all sections, and we didn’t want to mess with this. 
			There were several different iterations of the grid to figure out the exact padding and number of columns we needed to use.
		`),
		image({
			src: assetUrl(`images/grid/4-grid/iterations.png`),
			caption: `Grid iterations`,
			dropshadow: true,
		}),
		paragraph(`
			Below are a few process shots of me trying to figure out how to make a grid work with our different customization features. 
			Not only did our grid need to work for each section type, but within each section as well.
		`),
		image({
			src: assetUrl(`images/grid/4-grid/grid-animation.gif`),
			caption: `Grid process`,
			dropshadow: true,
		}),
		h2('The final grid'),
		paragraph(`
			After several weeks of testing, we came to a solution. 
			A 12-column grid wouldn’t work for our platform because of our complexity, so we went with a 24-column grid instead.
		`),
		image({
			src: assetUrl(`images/grid/4-grid/final-grid.png`),
			caption: `Final grid`,
			dropshadow: true,
		}),
		paragraph(`
			Figuring out the grid was the hardest part of the project so everything following was adjusting sections to line up with it. 
			Now that the deal info page is based on a grid, everything lines up and is fully responsive.
		`),
		video({
			src: assetUrl(`images/grid/4-grid/grid-scaling-on-product-c.mp4`),
			caption: `Updated deal info page`,
			dropshadow: true,
		}),			
	]),
	section(() => [
		h1(`Improved UI`),
		paragraph(`
			The last step of this project was to improve the UI of the page. 
			Based on customer feedback, the updates to make were: make each section better defined, improve padding for legibility, adjust font sizes and weight, and make exports beautiful.
		`),
		figureGroup(
			image({
				src: assetUrl(`images/grid/5-improved-ui/graphic-component.png`),
				caption: 'Containerized',
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/grid/5-improved-ui/graphic-padding.png`),
				caption: 'Padding',
				dropshadow: true,
			}),	
			image({
				src: assetUrl(`images/grid/5-improved-ui/graphic-font.png`),
				caption: 'Font',
				dropshadow: true,
			}),
			image({
				src: assetUrl(`images/grid/5-improved-ui/graphic-exports.png`),
				caption: 'Exports',
				dropshadow: true,
			}),					
		),		
		paragraph(`
			The video below shows the before and after look of the page. 
			We adjusted padding, added borders around the sections, and improved text legibility. 
			Since many customers print this report, we also made sure the exports match the on-screen experience.
		`),	
		image({
			src: assetUrl(`images/grid/5-improved-ui/grid-ui-animation.gif`),
			caption: `UI before and after`,
			dropshadow: true,
		}),
		paragraph(`
			Now that the deal info page is based on a grid, all sections line up and we were able to follow through with the data visualization project. 
			The current deal info page now looks polished and is fully responsive.
		`),
		fullWidthImage({
			src: assetUrl(`images/grid/5-improved-ui/final-ui-design.png`),
			caption: `Final design with grid`,
			dropshadow: true,
			maxWidth: 1100
		}),		
	]),
	section(() => [
		overline(`Evaluate`),
		h1(`Results`),
		paragraph(`
			Although this project was intended to happen silently without customers realizing, many customers did notice the improvements. 
			We received several complimentary emails with remarks about the page being more comfortable to read. 
			The ultimate goal of this project was to set up the deal info page for data visualization. 
			We successfully finished everything in a month and were able to kick off the charts project on time.
		`),
		bigQuote(`\u201C The refreshed deal info page looks great! \u201D`),
		image({
			src: assetUrl(`images/grid/6-results/metric-numbers.png`),
		}),		
	]),
	section(() => [
		h1(`Next step`),
		paragraph(`
			Besides the data visualization project, the next step was to bring in different types of sections. 
			Now that everything aligns we are able to introduce new types of charts and more robust tables.
		`),
		fullWidthImage({
			src: assetUrl(`images/grid/7-next-step/vision-mock.png`),
			caption: `Description of image`,
			dropshadow: true,
			maxWidth: 1100
		}),
		paragraph(`
			This project initially received some management pushback because there was a fear that shifting everything to a grid system could not be done in a month. 
			I worked with the engineering team to scope out the project and prove that it was doable. 
			After completion, everyone agreed that it was a huge success and that the page feels more polished. 
			This was a mathematical feat, but now all pages are responsive and we have beautiful charts and perfect alignment.
		`),						
		lightboxThumbnails({
			images: [
				{
					url: assetUrl(`images/grid/8-gallery/1-columns.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/grid/8-gallery/2-snapping.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/grid/8-gallery/3-sections.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/grid/8-gallery/4-deal-info.jpg`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/grid/8-gallery/5-template-editor.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/grid/8-gallery/6-deal-info.png`),
					caption: `blah blah.`,
				},
				{
					url: assetUrl(`images/grid/8-gallery/7-edit-mode.png`),
					caption: `blah blah.`,
				},	
								{
					url: assetUrl(`images/grid/8-gallery/8-property-info.png`),
					caption: `blah blah.`,
				},							
			],
		}),
	]),
);


