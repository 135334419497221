import * as React from 'react';
import classNames from 'classnames';

import {
	ImageTypes,
} from 'hannah/src/app/frontend/components/lightbox/lightbox_types';
import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/page_crossfading_images/page_crossfading_images.css';
import {Caption2} from 'hannah/src/app/frontend/typography';
import {Lightbox} from 'hannah/src/app/frontend/components/lightbox/lightbox';
import {CrossfadingElements} from 'hannah/src/app/frontend/components/crossfading_elements/crossfading_elements';

export interface PageCrossfadingImagesProps {
	imageSrcs: string[];
	caption?: string;
	marginTop?: boolean;
	marginBottom?: boolean;
	intervalMS: number;
	aspectRatioExpression: string;
	dropshadow?: boolean;
}

interface PageCrossfadingImagesState {
	lightboxIsOpen: boolean;
	currentImgSrc: string;
	activeLightboxImageUrl: string;
}

export class PageCrossfadingImages extends React.Component<PageCrossfadingImagesProps, PageCrossfadingImagesState> {

	private intervalId: any;

	state = {
		lightboxIsOpen: false,
		currentImgSrc: '',
		activeLightboxImageUrl: '',
	};

	componentDidMount() {
		const {intervalMS} = this.props;

		this.intervalId = setInterval(() => {
			const {imageSrcs} = this.props;
			const {currentImgSrc} = this.state;
			const index = imageSrcs.indexOf(currentImgSrc);
			const nextIndex = (index + 1) > imageSrcs.length - 1 ? 0 : index + 1;
			this.setState({
				currentImgSrc: imageSrcs[nextIndex],
			});
		}, intervalMS);
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	handleClickImage = () => {
		const {currentImgSrc} = this.state;
		this.setState({
			lightboxIsOpen: true,
			activeLightboxImageUrl: currentImgSrc,
		});
	};

	handleCloseLightbox = () => {
		this.setState({
			lightboxIsOpen: false,
		});
	};

	advanceImageIndex(indexDelta: number) {
		const {imageSrcs} = this.props;
		const {activeLightboxImageUrl} = this.state;

		const idx = imageSrcs.indexOf(activeLightboxImageUrl);
		const nextImageSrc = imageSrcs[idx + indexDelta];

		if (nextImageSrc) {
			this.setState({
				activeLightboxImageUrl: nextImageSrc,
			});	
		}
	}

	handleClickCurrentImage = () => {
		this.advanceImageIndex(1);
	};

	handleClickPrevButton = () => {
		this.advanceImageIndex(-1);
	};

	handleClickNextButton = () => {
		this.advanceImageIndex(1);
	};

	render() {
		const {
			imageSrcs,
			caption,
			marginTop,
			marginBottom,
			aspectRatioExpression,
			dropshadow = false,
		} = this.props;

		const {
			currentImgSrc,
			lightboxIsOpen,
			activeLightboxImageUrl,
		} = this.state;

		const cx = classNames({
			[classes.container]: true,
			[mediaSelectorClasses.margin_top_spacing]: marginTop,
			[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
		});

		const imageContainerCx = classNames({
			[classes.image_container]: true,
			[classes.image_dropshadow]: dropshadow,
		});

		const imageCx = classNames({
			[mediaSelectorClasses.content_width_max_wide]: true,
			[classes.image]: true,
		});

		const lightboxImages = imageSrcs.map((src: string) => {
			return {
				id: src,
				url: src,
				caption: caption || '',
				type: ImageTypes.IMAGE,
			};
		});

		const activeLightboxImage = lightboxImages.find(({url}) => {
			return url === activeLightboxImageUrl;
		});

		const aspectRatioStyles = {
			paddingBottom: `calc(100% * ${aspectRatioExpression})`,
		};

		return (
			<React.Fragment>
				<figure className={cx}>
					<div className={imageContainerCx}>
						<div style={aspectRatioStyles}>
							<CrossfadingElements
							  itemKey={currentImgSrc}
							  data={{
							  	src: currentImgSrc,
							  }}
							>{(config: any) => {
								return (
									<img
										onClick={this.handleClickImage}
										className={imageCx}
										style={config.style}
										src={config.data.src}
									/>
								);
							}}</CrossfadingElements>
						</div>
					</div>
					{
						caption && (
							<figcaption>
								<Caption2>{caption}</Caption2>
							</figcaption>
						)
					}
				</figure>
				{
					lightboxIsOpen && (
						<Lightbox
				    		images={lightboxImages}
				    		activeImage={activeLightboxImage!}
				    		onClose={this.handleCloseLightbox}
				    		onClickCurrentImage={this.handleClickCurrentImage}
				    		onClickPrevButton={this.handleClickPrevButton}
				    		onClickNextButton={this.handleClickNextButton}
						/>
					)
				}
			</React.Fragment>
		);
	}
};

