import * as React from 'react';
import {Switch, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import * as H from 'history';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import * as exenv from 'exenv';

import {indexReducer} from 'hannah/src/app/frontend/index_reducer';
import {setStoreStateAction, setActiveTopLinkHrefAction} from 'hannah/src/app/frontend/index_actions';
import {initDevOnlyFeatures} from  'hannah/src/app/frontend/dev_only_features';

import {AuthorNoteFooter} from 'hannah/src/app/frontend/components/layout/author_note_footer/author_note_footer';
import {HomePage} from 'hannah/src/app/frontend/pages/home_page/home_page';
import {HomepageCoverFlow} from 'hannah/src/app/frontend/components/homepage_cover_flow/homepage_cover_flow';
import {
	PageRenderConfigItem,
	PageConfigItem,
} from 'hannah/src/app/frontend/site_config_types';
import {CrossfadeMotion} from 'hannah/src/app/frontend/components/crossfade_motion/crossfade_motion';
import {PageTopNavigation} from 'hannah/src/app/frontend/components/layout/page_top_navigation/page_top_navigation';
import {ProjectPage} from 'hannah/src/app/frontend/pages/project_page';
import {PageNavFooter} from 'hannah/src/app/frontend/components/layout/page_nav_footer/page_nav_footer';

import {
	AUTHOR_INFO,
	NO_NAV_PAGE_CONFIGS,
	NO_NAV_PAGE_CONFIGS_WITH_CUSTOM_RENDER,
	HOME_PAGE_CONTENT,
	HOMEPAGE_COVER_IMAGE_CROSS_FADE_SPRING_CONFIG,
	HOMEPAGE_COVER_IMAGE_SCALE_UP_RATIO,
	HOMEPAGE_COVER_IMAGE_SLIDE_SPRING_CONFIG,
	HOMEPAGE_COVER_IMAGE_ZOOM_SPRING_CONFIG,
	INDEX_PAGE,
	INFO_PAGES,
	PAGE_TOP_NAVIGATION_SPRING_CONFIG,
	PROJECT_PAGES,
	TOP_NAV_SPRING_CONFIG,
} from 'hannah/src/app/frontend/site_config';

import 'hannah/src/app/frontend/global.css';

interface HannahAppProps {
	initialPageLoadData: any;
}

export class HannahApp extends React.Component<HannahAppProps> {

	private store: any;

	constructor(props: any) {
		super(props);
		this.initStore(this.props);
	}

	initStore(props: any) {
		const {initialPageLoadData} = this.props;
		this.store = createStore(indexReducer);
		this.store.dispatch(setStoreStateAction(initialPageLoadData));
	}

	componentDidMount() {

		if (localStorage.devFeatures === 'true') {
			initDevOnlyFeatures();
		}
	}

	render() {
		const topLevelPageConfigs = [
			...PROJECT_PAGES,
			...INFO_PAGES,
		];

		const pageConfigs = [
			...topLevelPageConfigs,
			...NO_NAV_PAGE_CONFIGS,
			...NO_NAV_PAGE_CONFIGS_WITH_CUSTOM_RENDER,
		];

		const pathname = window.location.pathname

		return (
			<Provider store={this.store}>
				<React.Fragment>
  					<Route render={({location}: {location: H.Location}) => {
						// NOTE take from https://github.com/ReactTraining/react-router/issues/2019
					  	if (exenv.canUseViewport) {
					  		window.scrollTo(0, 0);
					 	}
						// HACK ensure the active top link is always up to date
						// better solution - https://medium.com/@gatilin222/how-to-synchronize-a-redux-store-with-url-queries-a37551cf60d3
					  	// TODO(taylorm) unify with other place this conditional is used
					  	const url = location.pathname;
					  	if (url !== '/') {
					  		// don't clear state so left nav preserves last item
					  		requestAnimationFrame(() => {
					  			this.store.dispatch(setActiveTopLinkHrefAction(url));
					  		});
					  	}
						return null;
					}}/>
					<Switch>
					    {
					    	pathname !== '/' && (/\/$/).test(pathname) &&
					    	<Redirect to={pathname.slice(0, -1)} />
					   	}
						<Route render={({location}: {location: H.Location}) => {
							const isHomePage = location.pathname === '/';
							return (
								<HomepageCoverFlow
									navIsOpen={isHomePage}
									projectPageConfigs={PROJECT_PAGES}
									infoPageConfigs={INFO_PAGES}
									noNavPageConfigs={[
										...NO_NAV_PAGE_CONFIGS,
										...NO_NAV_PAGE_CONFIGS_WITH_CUSTOM_RENDER,
									]}
									authorInfo={AUTHOR_INFO}
									homePageContent={HOME_PAGE_CONTENT}
									coverImageScaleUpRatio={HOMEPAGE_COVER_IMAGE_SCALE_UP_RATIO}
									coverImageSlideSpringConfig={HOMEPAGE_COVER_IMAGE_SLIDE_SPRING_CONFIG}
									coverImageCrossFadeSpringConfig={HOMEPAGE_COVER_IMAGE_CROSS_FADE_SPRING_CONFIG}
									coverImageZoomSpringConfig={HOMEPAGE_COVER_IMAGE_ZOOM_SPRING_CONFIG}
									navSpringConfig={TOP_NAV_SPRING_CONFIG}
								/>
							);
						}}/>
					</Switch>
					<Switch>
						<Route render={({location}: {location: H.Location}) => {
							const activePageConfig = pageConfigs.find(({href}: Partial<PageConfigItem>) => {
								return href === location.pathname;
							});
							if (activePageConfig) {
								const {name: authorName} = AUTHOR_INFO;
								return (
									<CrossfadeMotion springConfig={PAGE_TOP_NAVIGATION_SPRING_CONFIG}>
										<PageTopNavigation
											authorName={authorName}
											links={[INDEX_PAGE, ...INFO_PAGES]}
										/>
									</CrossfadeMotion>
								);
							} else {
								// return so it can keep the children around to complete the transition
								return <CrossfadeMotion />;
							}
						}}/>
					</Switch>
					<Switch>
						<Route exact path={INDEX_PAGE.href} component={HomePage} />
						{
							NO_NAV_PAGE_CONFIGS.map(({href, config}: PageConfigItem) => {
								return (
									<Route key={href} exact path={href} render={() => {
										return (
											<React.Fragment>
												<ProjectPage config={config} />
												<AuthorNoteFooter credit={AUTHOR_INFO.credit} email={AUTHOR_INFO.email} />
											</React.Fragment>
										);
									}}/>
								);
							})
						}
						{
							NO_NAV_PAGE_CONFIGS_WITH_CUSTOM_RENDER.map(({href, render}: PageRenderConfigItem) => {
								return <Route key={href} exact path={href} render={render} />;
							})
						}
						{
							[
								topLevelPageConfigs.map((pageConfig: PageConfigItem) => {
									const {href, config} = pageConfig;
									const pageConfigIndex = topLevelPageConfigs.indexOf(pageConfig);
									const prevPageConfig = topLevelPageConfigs[pageConfigIndex - 1];
									const nextPageConfig = topLevelPageConfigs[pageConfigIndex + 1];

									return (
										<Route key={href} exact path={href} render={() => {
											return (
												<React.Fragment>
													<ProjectPage config={config} />
													<PageNavFooter
														prevPage={prevPageConfig}
														nextPage={nextPageConfig}
													/>
													<AuthorNoteFooter credit={AUTHOR_INFO.credit} email={AUTHOR_INFO.email} />
												</React.Fragment>
											);
										}}/>
									);
								}),
							]
						}
					</Switch>
				</React.Fragment>
			</Provider>
		);
	}
}
