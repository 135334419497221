import * as React from 'react';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/download_button/download_button.css';

export interface DownloadButtonProps {
	href: string;
	text: string;
}

export const DownloadButton = (props: DownloadButtonProps) => {
	const {
		href,
		text,
	} = props;

	return (
		<div className={classes.container}>
			<a className={mediaSelectorClasses.download_button}
				href={href}
				target="_blank">
				{text}
			</a>
		</div>
	);
};
