import * as React from 'react';
import {SpringHelperConfig} from 'react-motion';

import {
	PageConfigItem,
	AuthorInfo,
	HomePageContent,
} from 'hannah/src/app/frontend/site_config_types';

import {
	TranslateXMotion,
} from 'hannah/src/app/frontend/components/translate_x_motion/translate_x_motion';

import {
	Logo,
	LogoSubhead,
	TopNavLink,
	ContactBlurb,
} from 'hannah/src/app/frontend/typography';

import * as classes from 'hannah/src/app/frontend/components/top_left_nav/top_left_nav.css';

const TopAuthorHeading = ({authorInfo}: {authorInfo: AuthorInfo}) => {
	return (
		<div className={classes.author_heading}>
			<Logo>{authorInfo.name}</Logo>
			<LogoSubhead>{authorInfo.title}</LogoSubhead>
		</div>
	);
};

interface TopNavLiProps extends Partial<PageConfigItem> {
	onMouseEnter: () => void;
	hovered?: boolean;
}

const TopNavLi = (props: TopNavLiProps) => {
	const {href, hovered = false, text, onMouseEnter} = props;
	return (
		<li>
			<TopNavLink
				to={href!}
				exact
				hovered={hovered}
				onMouseEnter={onMouseEnter}>
				{text}
			</TopNavLink>
		</li>
	);
};

interface TopLeftNavProps {
	isOpen: boolean;
	projectPageConfigs: PageConfigItem[];
	infoPageConfigs: PageConfigItem[];
	authorInfo: AuthorInfo,
	homePageContent: HomePageContent,
	springConfig?: SpringHelperConfig;
	onMouseEnterNavItem: (pageConfig: PageConfigItem) => void;
	activeTopLinkHref: string;
}

export const TopLeftNav = (props: TopLeftNavProps) => {
	const {
		isOpen,
		projectPageConfigs,
		infoPageConfigs,
		authorInfo,
		homePageContent,
		springConfig,
		activeTopLinkHref,
		onMouseEnterNavItem,
	} = props;

	const {
		contactBlurb,
	} = homePageContent;

	function renderTopNavLi(pageConfig: PageConfigItem) {
		const {href, text} = pageConfig;
		return (
			<TopNavLi
				key={href}
				href={href}
				text={text}
				hovered={href === activeTopLinkHref}
				onMouseEnter={() => {
					onMouseEnterNavItem(pageConfig);
			}}/>
		);
	}

	const projectNavElems = projectPageConfigs.map(renderTopNavLi);
	const infoNavElems = infoPageConfigs.map(renderTopNavLi);

	return (
		<TranslateXMotion
			isOn={isOpen}
			className={classes.nav_container}
			onAndRestedClassName={classes.nav_container_open}
			onTranslateX={0}
			offTranslateX={-100}
			springConfig={springConfig}>
			<TopAuthorHeading authorInfo={authorInfo} />
			<nav className={classes.left_nav}>
				<ul className={classes.project_items_ul}>
					{projectNavElems}
				</ul>
				<ul className={classes.info_items_ul}>
					{infoNavElems}
				</ul>
			</nav>
			<ContactBlurb>{contactBlurb}</ContactBlurb>
		</TranslateXMotion>
	);
};
