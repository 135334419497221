import classNames from 'classnames';
import * as React from 'react';

import * as mediaSelectorClasses from 'hannah/src/app/frontend/media_selectors.css';
import * as classes from 'hannah/src/app/frontend/components/layout/ordered_list_cards/ordered_list_cards.css';

export interface OrderedListCardProps {
	topic: string;
	details: string;
}

export interface OrderedListCardsProps {
	items: OrderedListCardProps[];
	marginTop?: boolean;
	marginBottom?: boolean;
}

const OrderedListCard = (props: OrderedListCardProps) => {
	const {topic, details} = props;

	return (
		<li className={classes.card}>
			<strong className={mediaSelectorClasses.ordered_list_card_topic}>
				{topic}
			</strong>
			<p className={mediaSelectorClasses.ordered_list_card_details}>
				{details}
			</p>
		</li>
	);
}

const _OrderedListCards = (props: OrderedListCardsProps) => {
	const {
		items,
		marginTop = false,
		marginBottom = false,
	} = props;

	const cx = classNames({
		[classes.card_container]: true,
		[mediaSelectorClasses.margin_top_spacing_small]: marginTop,
		[mediaSelectorClasses.margin_bottom_spacing]: marginBottom,
	});

	return (
		<ol className={cx}>
			{items.map((itemProps, index: number) => {
				return <OrderedListCard key={index} {...itemProps} />
			})}
		</ol>
	);
};

export const OrderedListCards = React.memo(_OrderedListCards);
